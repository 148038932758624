.main-underline-tabs {
  background-color: $white;
  border-bottom: none;
  position: relative;

  .nav-item {
    border: 0;
    margin-bottom: -1px;
    padding-top: 10px;
    font-size: .75rem;

    .nav-link {
      text-align: center;
      font-weight: 400;
      color: black;
      border: 0;
      border-bottom: 1px solid transparent;
      padding: .5rem 0;
      margin-right: 40px;

      &:hover {
        border: 0;
        color: $gray-600;
        color: black;
        border-bottom: 3px solid $gray-400;
      }

      &.active {
        border: 0;
        color: $black;
        font-weight: 500;
        text-shadow: -0.03ex 0 $gray-700, 0.03ex 0 $gray-700;
        border-bottom: 3px solid $primary;
      }
    }

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      padding-top: 0;
      font-size: 1rem;

      .nav-link {
        margin-top: 15px;
        padding: 5px 0 10px!important;
        margin-right: 1rem;
      }

      .smart-nav-link {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    .nav-item {
      padding-top: 0;
      font-size: 1.1em;
    }
  }
}

.switch.link {
  font-size: 75%;
  align-self: flex-end;
}
