//
// Brandbar
// ----------------------------------------

.brand-logo {
  display: block;
}

.brand-claim {
  display: block;
}

.brandbar {
  min-height: building-units(7) - 4px;
  padding-right: 9px;
  padding-left: 9px;
  background-color: theme-color("primary");
  @include clearfix();

  .brand-logo {
    float: left;
    margin-top: 24px;
  }

  .brand-claim {
    float: right;
    margin-top: 35px;
  }
}
