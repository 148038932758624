.task-form {
  input[readonly]:focus{
    background-color: #ededed;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }

  button.btn-info:not(:disabled):not(.disabled):hover {
    background-color: darken($primary, 5%);
  }

  button.btn-info:not(:disabled):not(.disabled):active {
    background-color: darken($primary, 10%);
  }

  button.btn-info:not(:disabled):not(.disabled):focus {
    box-shadow: 0 0 0 0.2rem transparentize($primary, .75)
  }

  legend {
    font-size: 16px
  }

  #root__description {
    font-size: 15px;
  }

  .radio-inline {
    margin-right: 10px;
    font-size: 16px;
    color: $gray-600;
    input {
      margin-right: 5px;
    }
  }

  input[type="radio"] {
    margin: 0 10px;
  }

  .form-group {
    p { font-size: 15px; }
    .control-label {
      font-size: 16px;
      color: $gray-600;
    }
  }

  .array-item{
    margin-top: 20px;
    border-bottom: 1px solid $gray-100;
  }

  .array-item-add{
    max-width: 100%!important;
    width: 100%!important;
    margin: 20px 0 0!important;
    text-align: left!important;
  }

  .array-item-remove{
    margin-top: 30px;
  }
}

.form-actions-container {
  margin-top: 30px;
  float: right;
}

@include media-breakpoint-down(sm) {
  .form-actions-container {
    button {
      font-size: 18px;
    }
  }
}
