@charset "UTF-8";
:export {
  sliderColor: #a4a4a4; }

/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #246ee3;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #951c1c;
  --orange: #fd7e14;
  --yellow: #ddae14;
  --green: #1c761c;
  --teal: #20c997;
  --cyan: #2376b2;
  --white: #fff;
  --gray: #6c6c6c;
  --gray-dark: #383838;
  --magenta: #e20074;
  --primary: #0945a5;
  --secondary: #B9BCC0;
  --success: #1c761c;
  --info: #2376b2;
  --warning: #ddae14;
  --danger: #951c1c;
  --light: #E7E8EA;
  --dark: #4b4b4b;
  --breakpoint-xs: 0;
  --breakpoint-sm: 320px;
  --breakpoint-md: 636px;
  --breakpoint-lg: 1200px;
  --breakpoint-xl: 1500px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: OpenSans, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3;
  color: #262626;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #00739f;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #003c53;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c6c6c;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 4.21875rem; }

h2, .h2 {
  font-size: 3.375rem; }

h3, .h3 {
  font-size: 2.53125rem; }

h4, .h4 {
  font-size: 1.31288rem; }

h5, .h5 {
  font-size: 1.125rem; }

h6, .h6 {
  font-size: 0.93375rem; }

.lead {
  font-size: 1.31288rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 83%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.31288rem; }

.blockquote-footer {
  display: block;
  font-size: 83%;
  color: #6c6c6c; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #d0d0d0;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c6c6c; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #262626;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #262626; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 320px) {
  .container, .container-sm {
    max-width: 635px; } }

@media (min-width: 636px) {
  .container, .container-sm, .container-md {
    max-width: 1199px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1200px; } }

@media (min-width: 1500px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1499px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 320px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 636px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1500px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #262626;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #d0d0d0; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #d0d0d0; }
  .table tbody + tbody {
    border-top: 2px solid #d0d0d0; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #d0d0d0; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #d0d0d0; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #262626;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bacbe6; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7f9ed0; }

.table-hover .table-primary:hover {
  background-color: #a7bddf; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a7bddf; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #ebeced; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #dbdcde; }

.table-hover .table-secondary:hover {
  background-color: #dedfe1; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #dedfe1; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #bfd9bf; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #89b889; }

.table-hover .table-success:hover {
  background-color: #afd0af; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #afd0af; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c1d9e9; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #8db8d7; }

.table-hover .table-info:hover {
  background-color: #aecde2; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #aecde2; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f5e8bd; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #edd585; }

.table-hover .table-warning:hover {
  background-color: #f2e0a7; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #f2e0a7; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #e1bfbf; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #c88989; }

.table-hover .table-danger:hover {
  background-color: #d9aeae; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #d9aeae; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f8f9f9; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f3f3f4; }

.table-hover .table-light:hover {
  background-color: #eaeded; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #eaeded; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cdcdcd; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #a1a1a1; }

.table-hover .table-dark:hover {
  background-color: silver; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: silver; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #262626;
  border-color: #393939; }

.table .thead-light th {
  color: #4b4b4b;
  background-color: #B9BCC0;
  border-color: #d0d0d0; }

.table-dark {
  color: #fff;
  background-color: #262626; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #393939; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 319.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 635.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1499.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control, .search-form .close-search-form {
  display: block;
  width: 100%;
  height: calc(2.2125rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3;
  color: #4b4b4b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .search-form .close-search-form {
      transition: none; } }
  .form-control::-ms-expand, .search-form .close-search-form::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus, .search-form .close-search-form:focus {
    color: #4b4b4b;
    background-color: #fff;
    border-color: #3981f4;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(9, 69, 165, 0.25); }
  .form-control::placeholder, .search-form .close-search-form::placeholder {
    color: #6c6c6c;
    opacity: 1; }
  .form-control:disabled, .search-form .close-search-form:disabled, .form-control[readonly], .search-form .close-search-form[readonly] {
    background-color: #B9BCC0;
    opacity: 1; }

input[type="date"].form-control, .search-form input.close-search-form[type="date"],
input[type="time"].form-control,
.search-form input.close-search-form[type="time"],
input[type="datetime-local"].form-control,
.search-form input.close-search-form[type="datetime-local"],
input[type="month"].form-control,
.search-form input.close-search-form[type="month"] {
  appearance: none; }

select.form-control:-moz-focusring, .search-form select.close-search-form:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #4b4b4b; }

select.form-control:focus::-ms-value, .search-form select.close-search-form:focus::-ms-value {
  color: #4b4b4b;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.3; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.40625rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.98438rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1.125rem;
  line-height: 1.3;
  color: #262626;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.97656rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.98438rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(3.10938rem + 2px);
  padding: 0.5rem 1.5rem;
  font-size: 1.40625rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], .search-form select.close-search-form[size], select.form-control[multiple], .search-form select.close-search-form[multiple] {
  height: auto; }

textarea.form-control, .search-form textarea.close-search-form {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c6c6c; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 83%;
  color: #1c761c; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.98438rem;
  line-height: 1.3;
  color: #fff;
  background-color: rgba(28, 118, 28, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .was-validated .search-form .close-search-form:valid, .search-form .was-validated .close-search-form:valid, .form-control.is-valid, .search-form .is-valid.close-search-form {
  border-color: #1c761c;
  padding-right: 2.2125rem !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231c761c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.325em + 0.1875rem) center;
  background-size: calc(0.65em + 0.375rem) calc(0.65em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated .search-form .close-search-form:valid:focus, .search-form .was-validated .close-search-form:valid:focus, .form-control.is-valid:focus, .search-form .is-valid.close-search-form:focus {
    border-color: #1c761c;
    box-shadow: 0 0 0 0.2rem rgba(28, 118, 28, 0.25); }

.was-validated select.form-control:valid, .was-validated .search-form select.close-search-form:valid, .search-form .was-validated select.close-search-form:valid, select.form-control.is-valid, .search-form select.is-valid.close-search-form {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, .was-validated .search-form textarea.close-search-form:valid, .search-form .was-validated textarea.close-search-form:valid, textarea.form-control.is-valid, .search-form textarea.is-valid.close-search-form {
  padding-right: 2.2125rem;
  background-position: top calc(0.325em + 0.1875rem) right calc(0.325em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #1c761c;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23383838' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231c761c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.65em + 0.375rem) calc(0.65em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #1c761c;
    box-shadow: 0 0 0 0.2rem rgba(28, 118, 28, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #1c761c; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #1c761c; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #1c761c; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #269f26;
  background-color: #269f26; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(28, 118, 28, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #1c761c; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #1c761c; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #1c761c;
  box-shadow: 0 0 0 0.2rem rgba(28, 118, 28, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 83%;
  color: #951c1c; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.98438rem;
  line-height: 1.3;
  color: #fff;
  background-color: rgba(149, 28, 28, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .was-validated .search-form .close-search-form:invalid, .search-form .was-validated .close-search-form:invalid, .form-control.is-invalid, .search-form .is-invalid.close-search-form {
  border-color: #951c1c;
  padding-right: 2.2125rem !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23951c1c' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23951c1c' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.325em + 0.1875rem) center;
  background-size: calc(0.65em + 0.375rem) calc(0.65em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated .search-form .close-search-form:invalid:focus, .search-form .was-validated .close-search-form:invalid:focus, .form-control.is-invalid:focus, .search-form .is-invalid.close-search-form:focus {
    border-color: #951c1c;
    box-shadow: 0 0 0 0.2rem rgba(149, 28, 28, 0.25); }

.was-validated select.form-control:invalid, .was-validated .search-form select.close-search-form:invalid, .search-form .was-validated select.close-search-form:invalid, select.form-control.is-invalid, .search-form select.is-invalid.close-search-form {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, .was-validated .search-form textarea.close-search-form:invalid, .search-form .was-validated textarea.close-search-form:invalid, textarea.form-control.is-invalid, .search-form textarea.is-invalid.close-search-form {
  padding-right: 2.2125rem;
  background-position: top calc(0.325em + 0.1875rem) right calc(0.325em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #951c1c;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23383838' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23951c1c' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23951c1c' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.65em + 0.375rem) calc(0.65em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #951c1c;
    box-shadow: 0 0 0 0.2rem rgba(149, 28, 28, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #951c1c; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #951c1c; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #951c1c; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #c02424;
  background-color: #c02424; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(149, 28, 28, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #951c1c; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #951c1c; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #951c1c;
  box-shadow: 0 0 0 0.2rem rgba(149, 28, 28, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 320px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline .search-form .close-search-form, .search-form .form-inline .close-search-form {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #262626;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1.3;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #262626;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(9, 69, 165, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #0945a5;
  border-color: #0945a5; }
  .btn-primary:hover {
    color: #fff;
    background-color: #073681;
    border-color: #063175; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #073681;
    border-color: #063175;
    box-shadow: 0 0 0 0.2rem rgba(46, 97, 179, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #0945a5;
    border-color: #0945a5; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #063175;
    border-color: #062c69; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(46, 97, 179, 0.5); }

.btn-secondary {
  color: #262626;
  background-color: #B9BCC0;
  border-color: #B9BCC0; }
  .btn-secondary:hover {
    color: #262626;
    background-color: #a5a9ae;
    border-color: #9ea2a8; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #262626;
    background-color: #a5a9ae;
    border-color: #9ea2a8;
    box-shadow: 0 0 0 0.2rem rgba(163, 166, 169, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #262626;
    background-color: #B9BCC0;
    border-color: #B9BCC0; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #262626;
    background-color: #9ea2a8;
    border-color: #979ca2; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(163, 166, 169, 0.5); }

.btn-success {
  color: #fff;
  background-color: #1c761c;
  border-color: #1c761c; }
  .btn-success:hover {
    color: #fff;
    background-color: #155715;
    border-color: #124d12; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #155715;
    border-color: #124d12;
    box-shadow: 0 0 0 0.2rem rgba(62, 139, 62, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #1c761c;
    border-color: #1c761c; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #124d12;
    border-color: #104210; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 139, 62, 0.5); }

.btn-info {
  color: #fff;
  background-color: #2376b2;
  border-color: #2376b2; }
  .btn-info:hover {
    color: #fff;
    background-color: #1d6192;
    border-color: #1b5a87; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #1d6192;
    border-color: #1b5a87;
    box-shadow: 0 0 0 0.2rem rgba(68, 139, 190, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #2376b2;
    border-color: #2376b2; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #1b5a87;
    border-color: #19537d; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(68, 139, 190, 0.5); }

.btn-warning {
  color: #262626;
  background-color: #ddae14;
  border-color: #ddae14; }
  .btn-warning:hover {
    color: #fff;
    background-color: #ba9211;
    border-color: #ae8910; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #ba9211;
    border-color: #ae8910;
    box-shadow: 0 0 0 0.2rem rgba(194, 154, 23, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #262626;
    background-color: #ddae14;
    border-color: #ddae14; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ae8910;
    border-color: #a3800f; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(194, 154, 23, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #951c1c;
  border-color: #951c1c; }
  .btn-danger:hover {
    color: #fff;
    background-color: #751616;
    border-color: #6a1414; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #751616;
    border-color: #6a1414;
    box-shadow: 0 0 0 0.2rem rgba(165, 62, 62, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #951c1c;
    border-color: #951c1c; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #6a1414;
    border-color: #5f1212; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(165, 62, 62, 0.5); }

.btn-light {
  color: #262626;
  background-color: #E7E8EA;
  border-color: #E7E8EA; }
  .btn-light:hover {
    color: #262626;
    background-color: #d3d4d8;
    border-color: #ccced2; }
  .btn-light:focus, .btn-light.focus {
    color: #262626;
    background-color: #d3d4d8;
    border-color: #ccced2;
    box-shadow: 0 0 0 0.2rem rgba(202, 203, 205, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #262626;
    background-color: #E7E8EA;
    border-color: #E7E8EA; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #262626;
    background-color: #ccced2;
    border-color: #c5c7cc; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(202, 203, 205, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #4b4b4b;
  border-color: #4b4b4b; }
  .btn-dark:hover {
    color: #fff;
    background-color: #383838;
    border-color: #323232; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #383838;
    border-color: #323232;
    box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #4b4b4b;
    border-color: #4b4b4b; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #323232;
    border-color: #2b2b2b; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5); }

.btn-outline-primary {
  color: #0945a5;
  border-color: #0945a5; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0945a5;
    border-color: #0945a5; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(9, 69, 165, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0945a5;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0945a5;
    border-color: #0945a5; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(9, 69, 165, 0.5); }

.btn-outline-secondary {
  color: #B9BCC0;
  border-color: #B9BCC0; }
  .btn-outline-secondary:hover {
    color: #262626;
    background-color: #B9BCC0;
    border-color: #B9BCC0; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(185, 188, 192, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #B9BCC0;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #262626;
    background-color: #B9BCC0;
    border-color: #B9BCC0; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(185, 188, 192, 0.5); }

.btn-outline-success {
  color: #1c761c;
  border-color: #1c761c; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #1c761c;
    border-color: #1c761c; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(28, 118, 28, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #1c761c;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #1c761c;
    border-color: #1c761c; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(28, 118, 28, 0.5); }

.btn-outline-info {
  color: #2376b2;
  border-color: #2376b2; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #2376b2;
    border-color: #2376b2; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 118, 178, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #2376b2;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #2376b2;
    border-color: #2376b2; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(35, 118, 178, 0.5); }

.btn-outline-warning {
  color: #ddae14;
  border-color: #ddae14; }
  .btn-outline-warning:hover {
    color: #262626;
    background-color: #ddae14;
    border-color: #ddae14; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 174, 20, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ddae14;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #262626;
    background-color: #ddae14;
    border-color: #ddae14; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 174, 20, 0.5); }

.btn-outline-danger {
  color: #951c1c;
  border-color: #951c1c; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #951c1c;
    border-color: #951c1c; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(149, 28, 28, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #951c1c;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #951c1c;
    border-color: #951c1c; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(149, 28, 28, 0.5); }

.btn-outline-light {
  color: #E7E8EA;
  border-color: #E7E8EA; }
  .btn-outline-light:hover {
    color: #262626;
    background-color: #E7E8EA;
    border-color: #E7E8EA; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(231, 232, 234, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #E7E8EA;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #262626;
    background-color: #E7E8EA;
    border-color: #E7E8EA; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(231, 232, 234, 0.5); }

.btn-outline-dark {
  color: #4b4b4b;
  border-color: #4b4b4b; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #4b4b4b;
    border-color: #4b4b4b; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(75, 75, 75, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #4b4b4b;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #4b4b4b;
    border-color: #4b4b4b; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(75, 75, 75, 0.5); }

.btn-link {
  font-weight: 400;
  color: #00739f;
  text-decoration: none; }
  .btn-link:hover {
    color: #003c53;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c6c6c;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1.5rem;
  font-size: 1.40625rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.98438rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1.125rem;
  color: #262626;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 320px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 636px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1500px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #B9BCC0; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #262626;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #191919;
    text-decoration: none;
    background-color: #E7E8EA; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0945a5; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c6c6c;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.98438rem;
  color: #6c6c6c;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #262626; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .search-form .input-group > .close-search-form,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .search-form .input-group > .close-search-form + .form-control, .search-form .input-group > .form-control + .close-search-form, .search-form .input-group > .close-search-form + .close-search-form,
    .input-group > .form-control + .custom-select,
    .search-form .input-group > .close-search-form + .custom-select,
    .input-group > .form-control + .custom-file,
    .search-form .input-group > .close-search-form + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .search-form .input-group > .form-control-plaintext + .close-search-form,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .search-form .input-group > .custom-select + .close-search-form,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .search-form .input-group > .custom-file + .close-search-form,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus, .search-form .input-group > .close-search-form:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child), .search-form .input-group > .close-search-form:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child), .search-form .input-group:not(.has-validation) > .close-search-form:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3), .search-form .input-group.has-validation > .close-search-form:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3;
  color: #4b4b4b;
  text-align: center;
  white-space: nowrap;
  background-color: #B9BCC0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .search-form .input-group-lg > .close-search-form:not(textarea),
.input-group-lg > .custom-select {
  height: calc(3.10938rem + 2px); }

.input-group-lg > .form-control, .search-form .input-group-lg > .close-search-form,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1.5rem;
  font-size: 1.40625rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea), .search-form .input-group-sm > .close-search-form:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.97656rem + 2px); }

.input-group-sm > .form-control, .search-form .input-group-sm > .close-search-form,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.98438rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.4625rem;
  padding-left: 2rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.23125rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #0945a5;
    background-color: #0945a5; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(9, 69, 165, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #3981f4; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #6aa0f7;
    border-color: #6aa0f7; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c6c6c; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #B9BCC0; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.23125rem;
    left: -2rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #d0d0d0;
    border: 1px solid #72757e; }
  .custom-control-label::after {
    position: absolute;
    top: 0.23125rem;
    left: -2rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0945a5;
  background-color: #0945a5; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(9, 69, 165, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(9, 69, 165, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(9, 69, 165, 0.5); }

.custom-switch {
  padding-left: 2.75rem; }
  .custom-switch .custom-control-label::before {
    left: -2.75rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.23125rem + 2px);
    left: calc(-2.75rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #72757e;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #d0d0d0;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(9, 69, 165, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.2125rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3;
  color: #4b4b4b;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23383838' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #3981f4;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(57, 129, 244, 0.5); }
    .custom-select:focus::-ms-value {
      color: #4b4b4b;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c6c6c;
    background-color: #B9BCC0; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #4b4b4b; }

.custom-select-sm {
  height: calc(1.97656rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(3.10938rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.2125rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.2125rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #3981f4;
    box-shadow: 0 0 0 0.2rem rgba(9, 69, 165, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #B9BCC0; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.2125rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.3;
  color: #4b4b4b;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.2125rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.3;
    color: #4b4b4b;
    content: "Browse";
    background-color: #B9BCC0;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(9, 69, 165, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(9, 69, 165, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(9, 69, 165, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0945a5;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #6aa0f7; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #d0d0d0;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0945a5;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #6aa0f7; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #d0d0d0;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #0945a5;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #6aa0f7; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #d0d0d0;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #d0d0d0;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #72757e; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #72757e; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #72757e; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c6c6c;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #d0d0d0; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #B9BCC0 #B9BCC0 #d0d0d0; }
    .nav-tabs .nav-link.disabled {
      color: #6c6c6c;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #4b4b4b;
    background-color: #fff;
    border-color: #d0d0d0 #d0d0d0 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0945a5; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.31719rem;
  padding-bottom: 0.31719rem;
  margin-right: 1rem;
  font-size: 1.40625rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.40625rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 319.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 320px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 635.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 636px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1499.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1500px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 320px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 320px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 320px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #383838;
    content: " "; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #383838; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #262626;
  background-color: #fff;
  border: 1px solid #b2b2b2; }
  .page-link:hover {
    z-index: 2;
    color: #fff;
    text-decoration: none;
    background-color: #757575;
    border-color: #757575; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(9, 69, 165, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0945a5;
  border-color: #0945a5; }

.page-item.disabled .page-link {
  color: #6c6c6c;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #d0d0d0; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.40625rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.98438rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #0945a5; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #063175; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(9, 69, 165, 0.5); }

.badge-secondary {
  color: #262626;
  background-color: #B9BCC0; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #262626;
    background-color: #9ea2a8; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(185, 188, 192, 0.5); }

.badge-success {
  color: #fff;
  background-color: #1c761c; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #124d12; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(28, 118, 28, 0.5); }

.badge-info {
  color: #fff;
  background-color: #2376b2; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #1b5a87; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(35, 118, 178, 0.5); }

.badge-warning {
  color: #262626;
  background-color: #ddae14; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #262626;
    background-color: #ae8910; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(221, 174, 20, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #951c1c; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #6a1414; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(149, 28, 28, 0.5); }

.badge-light {
  color: #262626;
  background-color: #E7E8EA; }
  a.badge-light:hover, a.badge-light:focus {
    color: #262626;
    background-color: #ccced2; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(231, 232, 234, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #4b4b4b; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #323232; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(75, 75, 75, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #B9BCC0;
  border-radius: 0.3rem; }
  @media (min-width: 320px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4.1875rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #052456;
  background-color: #cedaed;
  border-color: #bacbe6; }
  .alert-primary hr {
    border-top-color: #a7bddf; }
  .alert-primary .alert-link {
    color: #021026; }

.alert-secondary {
  color: #606264;
  background-color: #f1f2f2;
  border-color: #ebeced; }
  .alert-secondary hr {
    border-top-color: #dedfe1; }
  .alert-secondary .alert-link {
    color: #47494a; }

.alert-success {
  color: #0f3d0f;
  background-color: #d2e4d2;
  border-color: #bfd9bf; }
  .alert-success hr {
    border-top-color: #afd0af; }
  .alert-success .alert-link {
    color: #051405; }

.alert-info {
  color: #123d5d;
  background-color: #d3e4f0;
  border-color: #c1d9e9; }
  .alert-info hr {
    border-top-color: #aecde2; }
  .alert-info .alert-link {
    color: #0a2132; }

.alert-warning {
  color: #735a0a;
  background-color: #f8efd0;
  border-color: #f5e8bd; }
  .alert-warning hr {
    border-top-color: #f2e0a7; }
  .alert-warning .alert-link {
    color: #443506; }

.alert-danger {
  color: #4d0f0f;
  background-color: #ead2d2;
  border-color: #e1bfbf; }
  .alert-danger hr {
    border-top-color: #d9aeae; }
  .alert-danger .alert-link {
    color: #220707; }

.alert-light {
  color: #78797a;
  background-color: #fafafb;
  border-color: #f8f9f9; }
  .alert-light hr {
    border-top-color: #eaeded; }
  .alert-light .alert-link {
    color: #5f6060; }

.alert-dark {
  color: #272727;
  background-color: #dbdbdb;
  border-color: #cdcdcd; }
  .alert-dark hr {
    border-top-color: silver; }
  .alert-dark .alert-link {
    color: #0e0e0e; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.84375rem;
  background-color: #B9BCC0;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0945a5;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #4b4b4b;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #4b4b4b;
    text-decoration: none;
    background-color: #E7E8EA; }
  .list-group-item-action:active {
    color: #262626;
    background-color: #B9BCC0; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c6c6c;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0945a5;
    border-color: #0945a5; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 320px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 636px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1500px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #052456;
  background-color: #bacbe6; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #052456;
    background-color: #a7bddf; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #052456;
    border-color: #052456; }

.list-group-item-secondary {
  color: #606264;
  background-color: #ebeced; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #606264;
    background-color: #dedfe1; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #606264;
    border-color: #606264; }

.list-group-item-success {
  color: #0f3d0f;
  background-color: #bfd9bf; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0f3d0f;
    background-color: #afd0af; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0f3d0f;
    border-color: #0f3d0f; }

.list-group-item-info {
  color: #123d5d;
  background-color: #c1d9e9; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #123d5d;
    background-color: #aecde2; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #123d5d;
    border-color: #123d5d; }

.list-group-item-warning {
  color: #735a0a;
  background-color: #f5e8bd; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #735a0a;
    background-color: #f2e0a7; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #735a0a;
    border-color: #735a0a; }

.list-group-item-danger {
  color: #4d0f0f;
  background-color: #e1bfbf; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #4d0f0f;
    background-color: #d9aeae; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #4d0f0f;
    border-color: #4d0f0f; }

.list-group-item-light {
  color: #78797a;
  background-color: #f8f9f9; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #78797a;
    background-color: #eaeded; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #78797a;
    border-color: #78797a; }

.list-group-item-dark {
  color: #272727;
  background-color: #cdcdcd; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #272727;
    background-color: silver; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #272727;
    border-color: #272727; }

.close {
  float: right;
  font-size: 1.6875rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c6c6c;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #b2b2b2;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.3; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dcdcdc;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 320px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1200px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1500px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: OpenSans, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.98438rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: OpenSans, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.98438rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.125rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #262626; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0945a5 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #063175 !important; }

.bg-secondary {
  background-color: #B9BCC0 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #9ea2a8 !important; }

.bg-success {
  background-color: #1c761c !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #124d12 !important; }

.bg-info {
  background-color: #2376b2 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1b5a87 !important; }

.bg-warning {
  background-color: #ddae14 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ae8910 !important; }

.bg-danger {
  background-color: #951c1c !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #6a1414 !important; }

.bg-light {
  background-color: #E7E8EA !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #ccced2 !important; }

.bg-dark {
  background-color: #4b4b4b !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #323232 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #d0d0d0 !important; }

.border-top {
  border-top: 1px solid #d0d0d0 !important; }

.border-right {
  border-right: 1px solid #d0d0d0 !important; }

.border-bottom {
  border-bottom: 1px solid #d0d0d0 !important; }

.border-left {
  border-left: 1px solid #d0d0d0 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0945a5 !important; }

.border-secondary {
  border-color: #B9BCC0 !important; }

.border-success {
  border-color: #1c761c !important; }

.border-info {
  border-color: #2376b2 !important; }

.border-warning {
  border-color: #ddae14 !important; }

.border-danger {
  border-color: #951c1c !important; }

.border-light {
  border-color: #E7E8EA !important; }

.border-dark {
  border-color: #4b4b4b !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 320px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 636px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1500px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 320px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 636px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1500px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 320px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 636px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1500px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.w-65 {
  width: 65% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.h-65 {
  height: 65% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 320px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 636px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1500px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 320px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 636px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1500px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #0945a5 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #05275c !important; }

.text-secondary {
  color: #B9BCC0 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #91959c !important; }

.text-success {
  color: #1c761c !important; }

a.text-success:hover, a.text-success:focus {
  color: #0d380d !important; }

.text-info {
  color: #2376b2 !important; }

a.text-info:hover, a.text-info:focus {
  color: #164c72 !important; }

.text-warning {
  color: #ddae14 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #97770e !important; }

.text-danger {
  color: #951c1c !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #551010 !important; }

.text-light {
  color: #E7E8EA !important; }

a.text-light:hover, a.text-light:focus {
  color: #bec1c6 !important; }

.text-dark {
  color: #4b4b4b !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #252525 !important; }

.text-body {
  color: #262626 !important; }

.text-muted {
  color: #6c6c6c !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #72757e;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1200px !important; }
  .container {
    min-width: 1200px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #d0d0d0 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #d0d0d0; }
  .table .thead-dark th {
    color: inherit;
    border-color: #d0d0d0; } }

.fade {
  transition: opacity 300ms ease-out; }
  .fade.in {
    opacity: 1; }

.slide {
  width: 0;
  transition: width 150ms cubic-bezier(0.6, 0, 0.7, 0.2); }
  .slide .icon {
    opacity: 0;
    transition: opacity 150ms ease-out; }
  .slide.in .icon {
    opacity: 1;
    transition: 150ms opacity 150ms ease-out; }

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/OpenSans_Regular.ttf");
  src: url("/fonts/OpenSans_Regular.ttf") format("truetype"); }

h1, .h1 {
  font-weight: 600;
  font-size: 1.31rem; }

.form-control:not(:disabled):hover, .search-form .close-search-form:not(:disabled):hover,
.custom-select:not(:disabled):hover {
  background-color: #fff; }

.form-control:not(:disabled):focus, .search-form .close-search-form:not(:disabled):focus,
.custom-select:not(:disabled):focus {
  background-color: #fff; }

.form-control:not(:disabled):active, .search-form .close-search-form:not(:disabled):active,
.custom-select:not(:disabled):active {
  background-color: #fff; }

.was-validated .form-control:valid:not(:disabled), .was-validated .search-form .close-search-form:valid:not(:disabled), .search-form .was-validated .close-search-form:valid:not(:disabled), .form-control.is-valid:not(:disabled), .search-form .is-valid.close-search-form:not(:disabled), .was-validated
.custom-select:valid:not(:disabled),
.custom-select.is-valid:not(:disabled) {
  background-color: #f1f6f1; }
  .was-validated .form-control:valid:not(:disabled):hover, .was-validated .search-form .close-search-form:valid:not(:disabled):hover, .search-form .was-validated .close-search-form:valid:not(:disabled):hover, .form-control.is-valid:not(:disabled):hover, .search-form .is-valid.close-search-form:not(:disabled):hover, .was-validated
  .custom-select:valid:not(:disabled):hover,
  .custom-select.is-valid:not(:disabled):hover {
    background-color: #e3eee3; }
  .was-validated .form-control:valid:not(:disabled):focus, .was-validated .search-form .close-search-form:valid:not(:disabled):focus, .search-form .was-validated .close-search-form:valid:not(:disabled):focus, .form-control.is-valid:not(:disabled):focus, .search-form .is-valid.close-search-form:not(:disabled):focus, .was-validated
  .custom-select:valid:not(:disabled):focus,
  .custom-select.is-valid:not(:disabled):focus {
    background-color: #fff; }
  .was-validated .form-control:valid:not(:disabled):active, .was-validated .search-form .close-search-form:valid:not(:disabled):active, .search-form .was-validated .close-search-form:valid:not(:disabled):active, .form-control.is-valid:not(:disabled):active, .search-form .is-valid.close-search-form:not(:disabled):active, .was-validated
  .custom-select:valid:not(:disabled):active,
  .custom-select.is-valid:not(:disabled):active {
    background-color: #d4e5d4; }

.was-validated .form-control:invalid:not(:disabled), .was-validated .search-form .close-search-form:invalid:not(:disabled), .search-form .was-validated .close-search-form:invalid:not(:disabled), .form-control.is-invalid:not(:disabled), .search-form .is-invalid.close-search-form:not(:disabled), .was-validated
.custom-select:invalid:not(:disabled),
.custom-select.is-invalid:not(:disabled) {
  background-color: #f8f1f1; }
  .was-validated .form-control:invalid:not(:disabled):hover, .was-validated .search-form .close-search-form:invalid:not(:disabled):hover, .search-form .was-validated .close-search-form:invalid:not(:disabled):hover, .form-control.is-invalid:not(:disabled):hover, .search-form .is-invalid.close-search-form:not(:disabled):hover, .was-validated
  .custom-select:invalid:not(:disabled):hover,
  .custom-select.is-invalid:not(:disabled):hover {
    background-color: #f2e3e3; }
  .was-validated .form-control:invalid:not(:disabled):focus, .was-validated .search-form .close-search-form:invalid:not(:disabled):focus, .search-form .was-validated .close-search-form:invalid:not(:disabled):focus, .form-control.is-invalid:not(:disabled):focus, .search-form .is-invalid.close-search-form:not(:disabled):focus, .was-validated
  .custom-select:invalid:not(:disabled):focus,
  .custom-select.is-invalid:not(:disabled):focus {
    background-color: #fff; }
  .was-validated .form-control:invalid:not(:disabled):active, .was-validated .search-form .close-search-form:invalid:not(:disabled):active, .search-form .was-validated .close-search-form:invalid:not(:disabled):active, .form-control.is-invalid:not(:disabled):active, .search-form .is-invalid.close-search-form:not(:disabled):active, .was-validated
  .custom-select:invalid:not(:disabled):active,
  .custom-select.is-invalid:not(:disabled):active {
    background-color: #ebd4d4; }

.has-icon {
  position: relative; }
  .has-icon .form-control, .has-icon .search-form .close-search-form, .search-form .has-icon .close-search-form {
    padding-left: 36px; }
    .input-group-large .has-icon .form-control, .input-group-large .has-icon .search-form .close-search-form, .search-form .input-group-large .has-icon .close-search-form {
      padding-left: 52px; }

.form-icon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  pointer-events: none; }

.MuiInputBase-input, .MuiMenuItem-root, .MuiFormControlLabel-label, .MuiFormLabel-root {
  font-size: 1rem !important;
  font-family: OpenSans !important; }

.MuiInputBase-input {
  background: transparent !important; }

.MuiInputBase-input, .MuiMenuItem-root, .MuiFormControlLabel-label {
  color: #24344e !important; }

.MuiFormLabel-root.Mui-focused {
  color: #0945a5 !important; }

.MuiListItem-root {
  min-height: 36px !important; }

.MuiFormHelperText-root {
  font-size: 0.87rem !important;
  padding: 0 !important;
  margin: 5px 0 0 !important;
  color: #8b8f97 !important;
  font-family: OpenSans !important; }

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #0945a5 !important; }

.MuiInputLabel-outlined:not(.MuiFormLabel-filled):not(.Mui-focused) {
  transform: translate(14px, 16px) scale(1) !important; }

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #0945a5 !important; }

.MuiInputBase-root legend {
  font-size: 13px !important; }

.MuiOutlinedInput-input.Mui-disabled {
  background-color: #f8f8f8 !important; }

.btn-primary {
  background: #246ee3;
  color: #fff;
  border: none;
  padding: 10px 30px;
  border-radius: 24px;
  width: fit-content; }
  .btn-primary:hover {
    border-color: none;
    background-color: #0945a5;
    color: #fff; }
  .btn-primary:disabled {
    background-color: #8b8f97; }

.btn-secondary {
  background: #24344e;
  color: #fff;
  border: none;
  padding: 10px 30px;
  border-radius: 24px; }
  .btn-secondary:hover {
    border-color: none;
    background-color: #192130;
    color: #fff; }

.btn-secondary-icon {
  background: #24344e;
  color: #fff;
  border: none;
  padding: 10px 30px;
  border-radius: 24px;
  font-size: 1rem !important;
  padding: 0px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  height: 40px;
  display: flex !important;
  align-items: center; }
  .btn-secondary-icon:hover {
    border-color: none;
    background-color: #192130;
    color: #fff; }
  .btn-secondary-icon .icon {
    border-right: 1px solid #a4a4a4;
    display: flex;
    height: 40px;
    align-items: center; }
  .btn-secondary-icon .text {
    padding-left: 15px !important; }
  .btn-secondary-icon svg {
    width: 16px !important;
    padding-right: 15px !important;
    box-sizing: content-box;
    fill: white; }

.btn-primary-icon {
  font-size: 1rem !important;
  padding: 0px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  height: 40px;
  display: flex !important;
  align-items: center;
  background: #246ee3;
  color: #fff;
  border: none;
  padding: 10px 30px;
  border-radius: 24px;
  width: fit-content; }
  .btn-primary-icon .icon {
    border-right: 1px solid #a4a4a4;
    display: flex;
    height: 40px;
    align-items: center; }
  .btn-primary-icon .text {
    padding-left: 15px !important; }
  .btn-primary-icon svg {
    width: 16px !important;
    padding-right: 15px !important;
    box-sizing: content-box;
    fill: white; }
  .btn-primary-icon:hover {
    border-color: none;
    background-color: #0945a5;
    color: #fff; }
  .btn-primary-icon:disabled {
    background-color: #8b8f97; }
  .btn-primary-icon .icon {
    border-right: none; }
    .btn-primary-icon .icon svg {
      padding-right: 0 !important; }

.btn.array-item-remove, .btn-add {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 5px 6px;
  flex: none !important;
  border: none; }

.btn.array-item-remove {
  background-color: #dcdcdc;
  color: #8b8f97; }
  .btn.array-item-remove:hover {
    background-color: #951c1c;
    color: #fff; }

.btn.btn-add {
  background-color: #246ee3; }

.dropdown-toggle::after {
  position: relative;
  top: 2px; }

.MuiPopover-root {
  z-index: 99999 !important; }

.navbar {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }

.navbar-nav .nav-item {
  position: static; }

.navbar-nav.utilities-nav .navbar-icon {
  height: 1em; }

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1.5rem;
  padding-left: 1.5rem; }

.brand-logo {
  display: block; }

.brand-claim {
  display: block; }

.brandbar, .footer {
  min-height: 80px;
  padding-right: 9px;
  padding-left: 9px;
  background-color: #0945a5; }
  .brandbar::after, .footer::after {
    display: block;
    clear: both;
    content: ""; }
  .brandbar .brand-logo, .footer .brand-logo {
    float: left;
    margin-top: 24px; }
  .brandbar .brand-claim, .footer .brand-claim {
    float: right;
    margin-top: 35px; }

@media (max-width: 1199.98px) {
  .brandnav {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    display: none;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    overflow: visible;
    background-color: #fff;
    transition: margin-left .3s ease-in-out; }
    .brandnav .brandnav {
      top: 0; }
    .active > .brandnav {
      left: 100%;
      display: block; }
    .brandnav.brandnav-lvl-container-1 {
      left: 0;
      margin-top: 1px; }
    .brandnav.has-active {
      margin-left: -100%; }
    @supports (transform-style: preserve-3d) {
      .brandnav {
        transition: transform .3s ease-in-out;
        perspective: 1000px; }
        .brandnav.has-active {
          margin-left: 0;
          transform: translate3d(-100%, 0, 0); } }
    .brandnav .brandnav-lvl-1 > .nav-item {
      position: static;
      width: 100%;
      padding-right: 0;
      padding-left: 0; }
    .brandnav .nav-item > .nav-link {
      font-size: 21px; }
      .brandnav .nav-item > .nav-link:not(:only-child)::after {
        float: right;
        width: .5rem;
        height: 1rem;
        margin-top: .2rem;
        margin-right: .1rem;
        content: "";
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg class='navbar-nav-svg' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 20' focusable='false'%3E%3Cpath d='M1 1l9 9l-9 9' stroke='currentColor' fill='none'%3E%3C/path%3E%3C/svg%3E"); }
  .brandnav-lvl-container-2 > .container {
    max-width: 100%; } }

@media (min-width: 1200px) {
  .brandnav {
    transition: color .3s ease-in-out; }
    .brandnav.has-active {
      color: #000; }
  .brandnav-lvl-container-1 {
    float: left; }
  .brandnav-lvl-1 > .nav-item {
    position: static;
    float: left; }
    .brandnav-lvl-1 > .nav-item:not(:last-child) {
      margin-right: 1.45rem; }
    .brandnav-lvl-1 > .nav-item > .nav-link {
      padding: .325rem .75rem;
      font-size: 21px;
      text-align: center;
      border-radius: 3px; }
    .brandnav-lvl-1 > .nav-item.active > .nav-link {
      color: #0945a5; }
  .brandnav-lvl-container-2 {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 1px;
    overflow: hidden;
    background-color: #fff; }
    .brandnav-lvl-container-2 .brandnav-lvl-2 {
      margin-right: auto;
      margin-left: auto;
      opacity: 0;
      transition: opacity 0.25s cubic-bezier(0.2, 0.7, 0.3, 1); }
      .brandnav-lvl-container-2 .brandnav-lvl-2::after {
        display: block;
        clear: both;
        content: ""; }
      .brandnav-lvl-container-2 .brandnav-lvl-2 > .nav-item > .nav-link {
        padding-top: .8125rem;
        padding-bottom: .75rem;
        font-size: 1.125rem;
        font-weight: 600;
        color: #262626;
        text-transform: uppercase;
        cursor: default; }
        .brandnav-lvl-container-2 .brandnav-lvl-2 > .nav-item > .nav-link:hover {
          text-decoration: none; }
    .active > .brandnav-lvl-container-2 {
      z-index: 1;
      height: auto;
      padding-top: 10px;
      padding-bottom: 18px; }
      .active > .brandnav-lvl-container-2 .brandnav-lvl-2 {
        opacity: 1; }
    @supports (transform-style: preserve-3d) {
      .brandnav-lvl-container-2 {
        height: auto;
        padding-top: 10px;
        padding-bottom: 18px;
        transition: transform 0.25s cubic-bezier(0.6, 0, 0.7, 0.2);
        perspective: 1000px;
        transition-delay: .25s;
        transform: scaleY(0);
        transform-origin: top; }
        .brandnav-lvl-container-2 .cancel {
          margin: .25rem .625rem;
          opacity: 0;
          transition: opacity 0.25s cubic-bezier(0.2, 0.7, 0.3, 1);
          transition-delay: 0s; }
        .brandnav-lvl-container-2 .brandnav-lvl-2 {
          transition-delay: 0s; }
        .active > .brandnav-lvl-container-2 {
          transition-delay: 0s;
          transform: scaleY(1); }
          .active > .brandnav-lvl-container-2 .brandnav-lvl-2 {
            transition-delay: .25s; }
          .active > .brandnav-lvl-container-2 .cancel {
            opacity: 1;
            transition-delay: .25s; } }
  .nav-item-lvl-2 > .nav-link {
    font-weight: 600;
    pointer-events: none; }
  .brandnav-lvl-3 > .nav-item > .nav-link {
    padding-top: .8125rem;
    padding-bottom: .75rem;
    font-size: 1.125rem; } }

.brandnav-lvl-2-head {
  display: none;
  width: 100%;
  min-height: 30px;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }
  .brandnav-lvl-2-head::after {
    display: block;
    clear: both;
    content: ""; }
  @media (min-width: 636px) {
    .brandnav-lvl-2-head {
      display: block; } }

.brandnav-control-up:not(.in) {
  pointer-events: none;
  visibility: hidden; }

.brandnavhead {
  display: none;
  min-height: 77px;
  padding-right: 12px;
  padding-bottom: .3125rem;
  padding-left: 12px;
  background-color: #fff; }
  .active .brandnavhead {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block; }
    @media (min-width: 636px) {
      .active .brandnavhead {
        display: none; } }
  .brandnavhead label {
    margin-top: .8rem; }
  .brandnavhead .navbar-nav {
    align-items: center;
    justify-content: space-between;
    height: 100%; }
    .brandnavhead .navbar-nav :nth-child(2) {
      overflow: hidden; }

.brand-navbar-container:not(.active) .brandnavhead {
  display: none; }

.navbar-header-label {
  font-size: 21px;
  color: #0945a5;
  text-align: center; }

.brand-navbar {
  height: 70px;
  padding: 1.2rem .6rem;
  background: url("../images/navbar-bg.png") #fff 30% 0% no-repeat;
  border-top: 4px solid #0945a5;
  z-index: 9999; }
  @media (min-width: 636px) {
    .brand-navbar .browser-container {
      width: 500px !important; } }
  .brand-navbar .browser-container .dashboard-link {
    padding-bottom: 3px; }
  .brand-navbar .browser-container .dashboard-item, .brand-navbar .browser-container .magazine-item {
    cursor: pointer;
    fill: #a4a4a4;
    stroke: none !important; }
    .brand-navbar .browser-container .dashboard-item:hover, .brand-navbar .browser-container .dashboard-item.active, .brand-navbar .browser-container .magazine-item:hover, .brand-navbar .browser-container .magazine-item.active {
      fill: #0945a5;
      stroke: none; }
      .brand-navbar .browser-container .dashboard-item:hover g, .brand-navbar .browser-container .dashboard-item.active g, .brand-navbar .browser-container .magazine-item:hover g, .brand-navbar .browser-container .magazine-item.active g {
        stroke: none; }
  .brand-navbar .browser-container .magazine-link {
    padding-bottom: 3px; }
  @media (max-width: 636px) {
    .brand-navbar {
      min-height: 70px !important; } }
  @media (min-width: 1200px) {
    .brand-navbar > .container {
      position: relative; } }
  .brand-navbar > .brand-navbar-container {
    flex-wrap: wrap;
    justify-content: left; }
    @media (min-width: 636px) {
      .brand-navbar > .brand-navbar-container {
        padding-right: 15px;
        padding-left: 15px; } }
  @media (min-width: 1200px) {
    @supports (position: sticky) or (position: -webkit-sticky) {
      .brand-navbar {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 1020; } } }
  .brand-navbar .navbar-brand {
    font-size: 1.3rem;
    padding: 0.6rem 0 0.7rem !important; }
    @media (max-width: 636px) {
      .brand-navbar .navbar-brand {
        padding-top: 3px !important; } }
    @media (max-width: 320px) {
      .brand-navbar .navbar-brand .app-title-first {
        display: none; } }
    .brand-navbar .navbar-brand h1 {
      font-size: 1.3rem;
      margin-bottom: 0; }
    .brand-navbar .navbar-brand, .brand-navbar .navbar-brand:hover {
      color: #0945a5; }
    .brand-navbar .navbar-brand:focus {
      color: #0945a5; }
    .brand-navbar .navbar-brand .app-title-first {
      margin-right: 0rem;
      font-weight: 600;
      font-size: 1.31rem;
      padding-left: 40px;
      background: url("../images/dashboard-logo.png") no-repeat; }
    .brand-navbar .navbar-brand .app-title-second {
      font-weight: bold; }
    @media (max-width: 636px) {
      .brand-navbar .navbar-brand h1 {
        font-size: 1.1rem; } }
  .brand-navbar .navbar-nav-svg {
    display: inline-block;
    width: 1.8rem;
    height: 1.6rem;
    vertical-align: middle; }
  .brand-navbar .navbar-nav-svg-small {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: middle; }
  .brand-navbar .nav-item {
    padding-right: 0;
    padding-left: 0; }
  .brand-navbar .nav-link {
    position: relative;
    color: #383838;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    background: transparent;
    padding: 1.4375rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.3;
    border-radius: 0; }
    @media (min-width: 636px) {
      .brand-navbar .nav-link {
        padding: 0.75rem 15px;
        font-size: 1.125rem;
        line-height: 1.3;
        border-radius: 3px; } }
    @media (min-width: 636px) {
      .brand-navbar .nav-link {
        padding-right: 0 !important;
        padding-left: 13px !important; } }
    .brand-navbar .nav-link:hover, .brand-navbar .nav-link:focus {
      text-decoration: none; }
    .brand-navbar .nav-link.disabled, .brand-navbar .nav-link:disabled {
      box-shadow: none;
      opacity: 0.65; }
    .brand-navbar .nav-link:hover {
      color: #262626;
      background-color: #B9BCC0; }
    .brand-navbar .nav-link:active {
      background: #dcdcdc; }
  .brand-navbar .btn-brandbar,
  .brand-navbar .btn-brandbar-small {
    position: relative;
    color: #383838;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    background: transparent;
    padding: 0.3125rem 0.6875rem;
    font-size: 1.25rem;
    line-height: 1.3;
    border-radius: 4px; }
    @media (min-width: 636px) {
      .brand-navbar .btn-brandbar,
      .brand-navbar .btn-brandbar-small {
        padding: 0.75rem 15px;
        font-size: 1.125rem;
        line-height: 1.3;
        border-radius: 3px; } }
    .brand-navbar .btn-brandbar:hover, .brand-navbar .btn-brandbar:focus,
    .brand-navbar .btn-brandbar-small:hover,
    .brand-navbar .btn-brandbar-small:focus {
      text-decoration: none;
      box-shadow: none; }
    .brand-navbar .btn-brandbar.disabled, .brand-navbar .btn-brandbar:disabled,
    .brand-navbar .btn-brandbar-small.disabled,
    .brand-navbar .btn-brandbar-small:disabled {
      box-shadow: none;
      opacity: 0.65; }
    .brand-navbar .btn-brandbar:hover,
    .brand-navbar .btn-brandbar-small:hover {
      color: #262626;
      background-color: #B9BCC0; }
    .brand-navbar .btn-brandbar:active,
    .brand-navbar .btn-brandbar-small:active {
      background: #dcdcdc; }
    .brand-navbar .btn-brandbar .badge,
    .brand-navbar .btn-brandbar-small .badge {
      position: absolute;
      top: -.55rem;
      left: 1.2rem;
      font-size: .8rem; }
  .brand-navbar .btn-brandbar-small {
    padding: .125rem .3125rem; }
  .brand-navbar .nav-item-lvl-2 .nav-link {
    border-radius: 0; }

.navbar-persistent {
  margin-left: auto; }
  @media (min-width: 636px) {
    .navbar-persistent {
      margin-right: -.5rem; } }
  .navbar-persistent .nav-item:not(:first-child) {
    margin-left: .8rem; }

.nav-link-dropdown {
  position: absolute;
  left: 0;
  width: 100vw;
  max-height: 100vh;
  transition: all .4s ease-out; }
  .nav-link-dropdown > * {
    transition: all .4s ease-out; }
  .nav-link-dropdown:not(.show) {
    display: block;
    max-height: 0;
    visibility: hidden; }
    .nav-link-dropdown:not(.show) > * {
      opacity: 0; }

.breadcrumb {
  font-size: .95rem; }

.breadcrumb-item,
.breadcrumb-item a {
  color: #757575; }

.breadcrumb-item + .breadcrumb-item::before {
  padding-right: .4rem;
  padding-left: .4rem;
  line-height: 1;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M5.72,12.69c-0.18,0-0.35-0.16-0.35-0.35c0-0.09,0.05-0.18,0.09-0.26l4.58-4.56L5.46,2.97 C5.33,2.84,5.33,2.63,5.44,2.49c0.14-0.14,0.35-0.14,0.49-0.02c0,0,0.02,0,0.02,0.02l4.81,4.81c0.14,0.14,0.14,0.35,0,0.48 L5.95,12.6C5.9,12.65,5.81,12.69,5.72,12.69z' fill='%23262626'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%; }

.breadcrumb-item.active {
  color: #0945a5; }

.pagination .page-link:active {
  z-index: 2;
  color: #fff;
  background-color: #636363;
  border-color: #636363; }

.alert {
  color: #262626; }

.alert-primary {
  background-color: rgba(15, 116, 255, 0.05);
  border-color: #0f74ff; }
  .alert-primary hr {
    border-top-color: #0067f5; }
  .alert-primary .alert-link {
    color: #262626; }

.alert-secondary {
  background-color: rgba(164, 164, 164, 0.05);
  border-color: #a4a4a4; }
  .alert-secondary hr {
    border-top-color: #979797; }
  .alert-secondary .alert-link {
    color: #262626; }

.alert-success {
  background-color: rgba(107, 179, 36, 0.05);
  border-color: #6bb324; }
  .alert-success hr {
    border-top-color: #5e9e20; }
  .alert-success .alert-link {
    color: #262626; }

.alert-info {
  background-color: rgba(0, 160, 222, 0.05);
  border-color: #00a0de; }
  .alert-info hr {
    border-top-color: #008ec5; }
  .alert-info .alert-link {
    color: #262626; }

.alert-warning {
  background-color: rgba(221, 174, 20, 0.05);
  border-color: #ddae14; }
  .alert-warning hr {
    border-top-color: #c69c12; }
  .alert-warning .alert-link {
    color: #262626; }

.alert-danger {
  background-color: rgba(149, 28, 28, 0.05);
  border-color: #951c1c; }
  .alert-danger hr {
    border-top-color: #801818; }
  .alert-danger .alert-link {
    color: #262626; }

.alert-light {
  background-color: rgba(235, 235, 235, 0.05);
  border-color: #ebebeb; }
  .alert-light hr {
    border-top-color: #dedede; }
  .alert-light .alert-link {
    color: #262626; }

.alert-dark {
  background-color: rgba(75, 75, 75, 0.05);
  border-color: #4b4b4b; }
  .alert-dark hr {
    border-top-color: #3e3e3e; }
  .alert-dark .alert-link {
    color: #262626; }

.list-group-item {
  border-right: 0;
  border-left: 0; }
  .list-group-item:first-child, .list-group-item:last-child {
    border-radius: 0; }

.close {
  opacity: 1; }
  .close::before {
    display: block;
    width: 16px;
    height: 16px;
    content: " ";
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M15.81,14.99l-6.99-7l6.99-7c0.24-0.24,0.2-0.63-0.04-0.83c-0.24-0.2-0.59-0.2-0.79,0l-6.99,7l-6.99-7 C0.75-0.08,0.36-0.04,0.16,0.2c-0.2,0.24-0.2,0.59,0,0.79l6.99,7l-6.99,7c-0.24,0.24-0.2,0.63,0.04,0.83c0.24,0.2,0.59,0.2,0.79,0 l6.99-7l6.99,7c0.24,0.24,0.59,0.24,0.83,0.04C16.04,15.66,16.08,15.26,15.81,14.99C15.85,15.03,15.81,15.03,15.81,14.99z' fill='%23262626'/%3E%3C/svg%3E"); }
  .close > span {
    display: none; }

.modal-title {
  flex: 1;
  text-align: center; }

.modal-header .close {
  position: absolute;
  right: 1rem;
  float: none; }

.search {
  display: none; }
  .search.in {
    display: block; }

.search-form-set {
  position: relative; }
  .search-form-set .form-control, .search-form-set .search-form .close-search-form, .search-form .search-form-set .close-search-form {
    padding-right: 29px; }
    .search-form-set .form-control::-ms-clear, .search-form-set .search-form .close-search-form::-ms-clear, .search-form .search-form-set .close-search-form::-ms-clear {
      display: none; }
  .search-form-set .close {
    position: absolute;
    top: 7px;
    right: 8px;
    z-index: 5;
    width: 20px;
    height: 20px;
    padding: 0;
    overflow: hidden;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    background: transparent;
    border: 0; }
    .search-form-set .close:hover, .search-form-set .close:focus {
      text-decoration: none;
      cursor: pointer; }
  .search-form-set.in .close {
    display: block; }

.navbar-search-item .form-icon {
  font-size: 24px; }

.navbar-search-item > .navbar-search {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  padding-top: 19px;
  padding-right: 12px;
  padding-left: 12px; }

@media (max-width: 635.98px) {
  .navbar-search-item-regular > .navbar-search {
    background-color: #fff; }
    .navbar-search-item-regular > .navbar-search.slide {
      width: auto; }
    .navbar-search-item-regular > .navbar-search .close {
      font-size: 24px; }
  .navbar-search-item-regular .input-group > .form-control, .navbar-search-item-regular .search-form .input-group > .close-search-form, .search-form .navbar-search-item-regular .input-group > .close-search-form {
    border: 0;
    border-radius: 6px; } }

@media (min-width: 636px) {
  .navbar-search-item-regular {
    position: relative !important; }
    .navbar-search-item-regular > .navbar-search {
      left: auto;
      width: 360px;
      padding-top: 0;
      padding-right: 0;
      padding-left: 0; }
      .navbar-search-item-regular > .navbar-search.slide {
        width: 0; }
        .navbar-search-item-regular > .navbar-search.slide.in {
          width: 360px; }
    .navbar-search-item-regular .input-group > .form-control, .navbar-search-item-regular .search-form .input-group > .close-search-form, .search-form .navbar-search-item-regular .input-group > .close-search-form {
      border-radius: .25rem; }
    .navbar-search-item-regular .slide .form-control, .navbar-search-item-regular .slide .search-form .close-search-form, .search-form .navbar-search-item-regular .slide .close-search-form {
      transition: padding 150ms cubic-bezier(0.6, 0, 0.7, 0.2); }
    .navbar-search-item-regular .slide:not(.in) .form-control, .navbar-search-item-regular .slide:not(.in) .search-form .close-search-form, .search-form .navbar-search-item-regular .slide:not(.in) .close-search-form {
      padding-right: 0;
      padding-left: 0; } }

.navbar-search-item-block .navbar-form {
  margin-right: auto;
  margin-left: auto; }

.navbar-search-item-block > .navbar-search {
  width: 100%;
  background-color: #fff; }
  .navbar-search-item-block > .navbar-search .close {
    font-size: 24px; }

.navbar-search-item-block .input-group > .form-control, .navbar-search-item-block .search-form .input-group > .close-search-form, .search-form .navbar-search-item-block .input-group > .close-search-form {
  border: 0; }

.navbar-left {
  float: left; }

.navbar-right {
  float: right; }

.footer {
  background-color: #f8f8f8;
  color: #8b8f97;
  font-size: 14px;
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 19px 0;
  z-index: 0;
  min-height: auto;
  border-top: solid 2px #fff; }
  .footer .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .footer .brand-images {
    border-bottom: 1px solid #dcdcdc;
    min-height: 40px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .footer .brand-images .brand-logo, .footer .brand-images .brand-claim {
      margin-top: 0;
      float: none; }
    .footer .brand-images .brand-logo img {
      width: 85px; }
    .footer .brand-images .brand-claim img {
      width: 193px; }
  .footer .footer-links {
    padding: 8px 0; }
    .footer .footer-links .imprint a,
    .footer .footer-links .pages-links a {
      color: #8b8f97; }
      .footer .footer-links .imprint a:hover,
      .footer .footer-links .pages-links a:hover {
        color: #72757e; }
    .footer .footer-links .copyright {
      text-align: right; }
      .footer .footer-links .copyright p {
        margin-bottom: 0; }
        .footer .footer-links .copyright p svg {
          margin-top: -5px; }
  @media (max-width: 1200px) {
    .footer {
      padding: 9px 0; }
      .footer .container {
        height: auto !important; } }
  @media (max-width: 636px) {
    .footer {
      min-height: auto !important;
      margin-top: 15px; }
      .footer .footer-links > * {
        text-align: center;
        margin: 0; }
      .footer .footer-links .copyright {
        margin: 0;
        text-align: center; } }

.card {
  background: #B9BCC0; }
  .card .card-img-overlay {
    cursor: not-allowed;
    background-color: #fff;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    z-index: 3; }
  .card .card-content {
    background: none;
    padding: 0.5rem; }
    .card .card-content:last-child {
      overflow: hidden; }

.json-schema-react-editor .wrapper {
  padding-left: 0; }

.json-schema-react-editor .down-style-col {
  width: 16px;
  max-width: 16px; }

.json-schema-react-editor .option-formStyle {
  padding-top: 0 !important; }

.json-schema-react-editor .object-style {
  margin: 0 !important;
  padding: 0 !important; }
  .json-schema-react-editor .object-style > div:not(.ant-row-flex):not(.schema-content) {
    padding-left: 8px; }

.json-schema-react-editor .col-item {
  margin-bottom: 15px;
  padding-left: 0 !important; }

.json-schema-react-editor .col-item-name {
  flex: 0 0 23.33333333%;
  max-width: 23.33333333%; }
  .json-schema-react-editor .col-item-name .down-style-col {
    flex: 0 0 12%;
    max-width: 12%; }
    .json-schema-react-editor .col-item-name .down-style-col svg {
      width: 16px;
      height: 12px; }
  .json-schema-react-editor .col-item-name .ant-col-22 {
    flex: 0 0 88%;
    max-width: 88%; }

.json-schema-react-editor .col-item-type {
  flex: 0 0 16.5%;
  max-width: 16.5%; }
  .json-schema-react-editor .col-item-type .ant-select-selection__rendered {
    text-align: left; }

.json-schema-react-editor .col-item-mock {
  flex: 0 0 23.83333333%;
  max-width: 23.83333333%; }

.json-schema-react-editor .col-item-desc {
  flex: 0 0 24.83333333%;
  max-width: 24.83333333%; }

.json-schema-react-editor .col-item-setting {
  padding-top: 10px;
  padding-left: 13px !important;
  display: flex; }
  .json-schema-react-editor .col-item-setting > span:not(:last-child) {
    margin-right: 12px;
    padding: 0; }

.json-schema-react-editor input, .json-schema-react-editor .ant-select-selection {
  border: solid 1px #a4a4a4;
  border-radius: 4px !important;
  padding: 10px;
  color: #8b8f97;
  font-size: 1rem; }

.json-schema-react-editor .ant-input-wrapper .ant-input-group-addon {
  z-index: 2;
  position: absolute;
  right: 18px;
  top: 12px; }

.json-schema-react-editor .col-item:not(.col-item-name) .ant-input-group-addon {
  display: none; }

.json-schema-react-editor svg {
  fill: #8b8f97;
  width: 16px;
  height: 16px; }

.json-schema-react-editor .delete-item {
  padding-right: 0;
  display: inline-block;
  margin-top: 4px;
  width: 16px;
  height: 16px; }

.json-schema-react-editor .ant-checkbox-inner {
  border: solid 2px #a4a4a4;
  background: none; }

.ant-select-dropdown-menu {
  padding: 5px 10px;
  margin: 0;
  list-style: none;
  color: #8b8f97; }
  .ant-select-dropdown-menu li:not(:last-child) {
    margin-bottom: 5px;
    cursor: pointer; }
    .ant-select-dropdown-menu li:not(:last-child):hover {
      color: #72757e; }

.anticon:hover {
  opacity: 1 !important; }
  .anticon:hover svg {
    fill: #24344e; }

.anticon svg path {
  transform: translate(64px, 64px); }

.anticon.anticon-setting svg path {
  d: path("M792.574,493.9A339.785,339.785,0,0,0,795.8,450a339.786,339.786,0,0,0-3.224-43.9l97.18-73.92a22.188,22.188,0,0,0,5.527-28.672L803.167,148.5c-5.527-9.856-17.962-13.44-28.094-9.856l-114.681,44.8a338.885,338.885,0,0,0-77.836-43.9l-17.5-118.72C563.672,10.064,554,2,542.486,2H358.259c-11.514,0-21.186,8.064-22.568,18.816l-17.5,118.72c-28.095,11.2-53.886,26.432-77.836,43.9l-114.681-44.8A22.761,22.761,0,0,0,97.577,148.5L5.464,303.5a21.689,21.689,0,0,0,5.527,28.672l97.18,73.92a345.766,345.766,0,0,0-3.224,43.9,345.763,345.763,0,0,0,3.224,43.9l-97.18,73.92A22.188,22.188,0,0,0,5.464,596.5L97.577,751.5c5.527,9.856,17.962,13.44,28.095,9.856l114.681-44.8a338.891,338.891,0,0,0,77.836,43.9l17.5,118.72C337.073,889.936,346.744,898,358.259,898H542.486c11.514,0,21.186-8.064,22.568-18.816l17.5-118.72c28.095-11.2,53.887-26.432,77.836-43.9l114.681,44.8a22.761,22.761,0,0,0,28.094-9.856L895.281,596.5a22.188,22.188,0,0,0-5.527-28.672Zm-342.2,112.9c-88.89,0-161.2-70.336-161.2-156.8s72.309-156.8,161.2-156.8,161.2,70.336,161.2,156.8S539.262,606.8,450.372,606.8Z"); }

.anticon.anticon-plus svg path {
  d: path("M901,517H517V901H389V517H5V389H389V5H517V389H901Z"); }

.anticon.anticon-close {
  line-height: 0 !important; }
  .anticon.anticon-close::before {
    display: none !important; }
  .anticon.anticon-close svg path {
    d: path("M745.191,79.973l-92.8-92.8L376.573,263,96.878-13.794,5,78.084,283.244,356.328,5.214,634.358l92.8,92.8,278.03-278.03,277.54,277.54,93.329-93.329L469.368,355.8Z");
    transform: translate(64px, 164px); }

.anticon.anticon-caret-down svg path {
  d: path("M909.68,8.59,518,399.417,126.32,8.59,6,128.91l512,512,512-512Z"); }

.anticon.anticon-caret-right svg path {
  d: path("M10,1031.045,522.022,519.023,10,7Z"); }

.anticon.ant-select-arrow-icon svg {
  width: 12px;
  height: 10px; }
  .anticon.ant-select-arrow-icon svg path {
    d: path("M111-20,623,492,1135-20Z");
    transform: none; }

label.is-invalid {
  color: #951c1c; }

label.form-check-label a, label.custom-control-label a {
  color: inherit;
  text-decoration: underline; }

.background-layout {
  background: url("../images/background_image_login.png") center center;
  padding: 100px 0;
  padding: 100px 0 150px;
  margin-bottom: -150px;
  height: 100vh; }
  @media (min-width: 636px) and (max-width: 1199.98px) {
    .background-layout {
      padding: 110px 0;
      margin-bottom: -90px; } }
  @media (max-width: 635.98px) {
    .background-layout {
      padding: 0;
      margin-bottom: -60px; } }
  .background-layout h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase; }
    @media (max-width: 1199.98px) {
      .background-layout h1 {
        color: #fff; } }
    @media (max-width: 635.98px) {
      .background-layout h1 {
        font-size: 21px; } }

.form-container {
  width: 770px;
  margin: auto; }
  .form-container .form {
    margin: auto; }
    .form-container .form .form-group {
      margin-top: 30px; }
      .form-container .form .form-group a {
        font-size: 24px; }
        @media (max-width: 635.98px) {
          .form-container .form .form-group a {
            font-size: 18px; } }
  .form-container .external-providers-actions {
    height: 52px;
    display: flex;
    justify-content: space-around; }
    .form-container .external-providers-actions svg path {
      fill: #fff; }
  .form-container h2 {
    font-size: 24px;
    font-weight: 500; }
    @media (max-width: 635.98px) {
      .form-container h2 {
        font-size: 18px; } }
  @media (max-width: 635.98px) {
    .form-container p {
      font-size: 15px; } }
  .form-container hr {
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-color: #a4a4a4; }
    @media (min-width: 636px) {
      .form-container hr {
        margin: 48px 0; } }
    @media (max-width: 635.98px) {
      .form-container hr {
        margin: 30px 0; } }
  @media (min-width: 1200px) {
    .form-container {
      padding: 100px; } }
  @media (min-width: 636px) and (max-width: 1199.98px) {
    .form-container {
      padding: 48px 0 0;
      width: 463px; } }
  @media (max-width: 635.98px) {
    .form-container {
      padding-top: 30px;
      width: 100%; } }

.intro-text {
  color: #000;
  font-size: 18px; }

#tabs-container .dashboard-row {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px; }
  @media (max-width: 1200px) {
    #tabs-container .dashboard-row {
      justify-content: center; } }
  #tabs-container .dashboard-row.layout-3 {
    margin-left: -10px;
    margin-right: -15px;
    margin-bottom: -20px; }
    @media (max-width: 1200px) {
      #tabs-container .dashboard-row.layout-3 {
        margin-bottom: -12px; } }
  #tabs-container .dashboard-row.layout-2 {
    margin-left: -20px;
    margin-right: -25px;
    margin-bottom: -30px; }
    @media (max-width: 636px) {
      #tabs-container .dashboard-row.layout-2 {
        margin-bottom: -6px; } }
  #tabs-container .dashboard-row.layout-4 {
    margin-left: -5px;
    margin-right: -10px;
    margin-bottom: -10px; }
  #tabs-container .dashboard-row .tile_grid .layout-3 {
    margin-left: 10px;
    margin-right: 10px; }
  #tabs-container .dashboard-row .tile_grid .layout-2 {
    margin-left: 20px;
    margin-right: 20px; }
  #tabs-container .dashboard-row .tile_grid .layout-4 {
    margin-left: 5px;
    margin-right: 5px; }
  .layout-3-cols #tabs-container .dashboard-row .workflow-elements-container {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 24px; }
  .layout-4-cols #tabs-container .dashboard-row .workflow-elements-container {
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 8px; }

.panel-btn {
  width: calc(100% + 30px);
  background: white;
  border: none;
  text-align: left;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f5f1f3;
  height: 50px;
  vertical-align: middle;
  margin-left: -15px;
  margin-right: -15px; }
  .panel-btn h5 {
    margin: 0;
    color: #4b4b4b;
    font-size: 15px; }
  .panel-btn .icon {
    display: inline-block;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 1.49625rem;
    height: 1.49625rem;
    float: right; }
    .panel-btn .icon.expanded {
      background-image: url("/images/arrow_up.svg"); }
    .panel-btn .icon.collapsed {
      background-image: url("/images/arrow_down.svg"); }

.layout-controls {
  cursor: pointer; }
  .layout-controls.plus {
    padding-left: 7px; }
  .layout-controls.minus {
    padding-right: 7px;
    vertical-align: text-bottom; }

.workflows-container.is-hidden {
  display: none; }

@media (max-width: 635.98px) {
  .navbar {
    border: none;
    padding-bottom: 0.375rem;
    padding: 0rem 15px; } }

.navbar .navbar-toggler .navbar-toggler-icon {
  height: .5em;
  width: .5em; }

.navbar.loggedout {
  padding-top: 1.4125rem;
  padding-bottom: 1.4125rem; }
  @media (max-width: 635.98px) {
    .navbar.loggedout {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem; } }

.navbar.navbar-expand-md .navbar-collapse {
  padding-top: 8px; }
  @media (min-width: 636px) {
    .navbar.navbar-expand-md .navbar-collapse {
      display: none !important; } }

.navbar-nav {
  flex-grow: 1;
  flex-direction: row; }
  .navbar-nav .navbar-nav-flex-right {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1; }
    @media (max-width: 635.98px) {
      .navbar-nav .navbar-nav-flex-right {
        display: flex;
        flex-grow: 1; }
        .show .navbar-nav .navbar-nav-flex-right {
          display: none; } }
  .navbar-nav .navbar-icon {
    color: #000;
    cursor: pointer;
    padding: 0;
    margin: 9px 0 0 10px;
    background: none !important;
    z-index: 999;
    background: none;
    border: none; }
    @media (max-width: 1200px) {
      .navbar-nav .navbar-icon {
        margin: 5px 0 0 20px; } }
    .navbar-nav .navbar-icon.search-icon {
      margin-left: 0px; }
    .navbar-nav .navbar-icon.linkgroups svg g path {
      fill: #a4a4a4;
      stroke: none; }
    .navbar-nav .navbar-icon.linkgroups:hover g path, .navbar-nav .navbar-icon.linkgroups.active g path {
      fill: #0945a5; }
    .navbar-nav .navbar-icon.linkbell g g path {
      fill: #a4a4a4;
      stroke: none; }
    .navbar-nav .navbar-icon.linkbell:hover g g path, .navbar-nav .navbar-icon.linkbell.active g g path {
      fill: #0945a5; }
    .navbar-nav .navbar-icon.profile-menu svg, .navbar-nav .navbar-icon.search-icon svg {
      width: 26px;
      height: 26px; }
    .navbar-nav .navbar-icon.profile-menu path, .navbar-nav .navbar-icon.profile-menu circle, .navbar-nav .navbar-icon.search-icon path, .navbar-nav .navbar-icon.search-icon circle {
      fill: #a4a4a4;
      stroke: none; }
    .navbar-nav .navbar-icon.profile-menu:hover path, .navbar-nav .navbar-icon.profile-menu:hover circle, .navbar-nav .navbar-icon.profile-menu .active path, .navbar-nav .navbar-icon.profile-menu .active circle, .navbar-nav .navbar-icon.search-icon:hover path, .navbar-nav .navbar-icon.search-icon:hover circle, .navbar-nav .navbar-icon.search-icon .active path, .navbar-nav .navbar-icon.search-icon .active circle {
      fill: #0945a5; }
    .navbar-nav .navbar-icon.close-icon {
      margin: 10px 0 0 0; }
      .navbar-nav .navbar-icon.close-icon path, .navbar-nav .navbar-icon.close-icon circle {
        stroke: none; }
      .navbar-nav .navbar-icon.close-icon svg {
        width: 28px;
        height: 28px;
        fill: #72757e; }
      @media screen and (-ms-high-contrast: active) {
        .navbar-nav .navbar-icon.close-icon svg path:nth-child(1) {
          fill: none; } }
      .navbar-nav .navbar-icon.close-icon:hover svg {
        fill: #000; }
      @media (max-width: 1200px) {
        .navbar-nav .navbar-icon.close-icon {
          margin: 5px 0 0 28px; }
          .navbar-nav .navbar-icon.close-icon svg {
            width: 30px;
            height: 30px; } }
      @media (max-width: 636px) {
        .navbar-nav .navbar-icon.close-icon {
          margin: 5px 10px 0 28px; } }
    @media (max-width: 636px) {
      .navbar-nav .navbar-icon {
        margin: 4px 0 0 28px; }
        .navbar-nav .navbar-icon svg {
          width: 22px;
          height: 22px; }
        .navbar-nav .navbar-icon.close-icon svg {
          width: 26px;
          height: 26px; }
        .navbar-nav .navbar-icon.linkburger-menu svg {
          width: 18px;
          height: 18px; } }
    .navbar-nav .navbar-icon svg {
      stroke: #000; }
    .navbar-nav .navbar-icon:hover svg, .navbar-nav .navbar-icon.active svg {
      stroke: #0945a5; }
      @media screen and (-ms-high-contrast: active) {
        .navbar-nav .navbar-icon:hover svg path, .navbar-nav .navbar-icon.active svg path {
          fill: #0945a5; }
        .navbar-nav .navbar-icon:hover svg g, .navbar-nav .navbar-icon.active svg g {
          stroke: #0945a5; } }
    @media screen and (-ms-high-contrast: active) {
      .navbar-nav .navbar-icon svg path {
        fill: #fff; }
      .navbar-nav .navbar-icon svg g {
        stroke: #fff; }
      .navbar-nav .navbar-icon svg .active {
        fill: none;
        stroke: #0945a5; } }
  .navbar-nav .badge {
    position: absolute;
    top: -7px;
    right: 0; }
    .navbar-nav .badge.small {
      font-size: 0.75rem;
      padding: 0.4em; }
    @media (max-width: 635.98px) {
      .navbar-nav .badge {
        width: 24px;
        height: 24px;
        position: relative;
        display: inline-block;
        margin-left: 18px; }
        .navbar-nav .badge.small {
          font-size: 0.93rem;
          font-weight: bold;
          padding-top: 5px;
          top: 0; }
        .navbar-nav .badge.menu {
          position: absolute;
          top: 7px;
          right: 4px;
          width: 12px;
          height: 12px;
          z-index: 9999; } }
  .navbar-nav .nav-item .profile-menu {
    padding-right: 0 !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important; }
  .navbar-nav .nav-item .username {
    padding-left: 0.75rem; }

.tile {
  border: 1px solid white;
  border-radius: 0;
  width: 100%; }
  .tile ~ .triangle {
    content: ' ';
    z-index: -1;
    position: relative;
    top: -17px;
    left: calc(50% - 20px);
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #B9BCC0; }
  .tile:not(.restricted) {
    cursor: pointer; }
    .tile:not(.restricted):not(.tool).open, .tile:not(.restricted):not(.tool):hover {
      text-decoration: none; }
      .tile:not(.restricted):not(.tool).open .card-title, .tile:not(.restricted):not(.tool):hover .card-title {
        color: #0945a5; }
      .tile:not(.restricted):not(.tool).open .badge, .tile:not(.restricted):not(.tool):hover .badge {
        background-color: #0945a5; }
    .tile:not(.restricted) .tool-link:hover {
      text-decoration: none; }
      .tile:not(.restricted) .tool-link:hover .card-title {
        color: #0945a5; }
      .tile:not(.restricted) .tool-link:hover .badge {
        background-color: #0945a5; }
  .tile.open {
    margin-bottom: 0; }
    .tile.open .card-title {
      color: #0945a5; }
    .tile.open .badge {
      background-color: #0945a5; }
  .tile .card-status-icon {
    float: right;
    position: relative;
    width: 7px;
    height: 7px;
    right: .4rem;
    clear: both;
    background-position: center;
    background-size: contain; }
  .tile .card-content .badge {
    float: right;
    position: absolute;
    font-size: .75em;
    right: 10px;
    top: 151px;
    height: 25px;
    min-width: 25px;
    line-height: 12.5px;
    text-align: center;
    font-weight: 500;
    background-color: #6c6c6c;
    border: 2px solid #B9BCC0;
    color: #fff;
    border-radius: 50px; }
  .tile .card-content .card-title,
  .tile .card-content .card-subtitle {
    color: #262626;
    font-size: 11px;
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .tile .card-content .card-title {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1.7;
    letter-spacing: 0.2px;
    color: #707070; }
  .tile .card-footer {
    padding: 0;
    background-color: #B9BCC0;
    border-top: 2px solid white; }
    .tile .card-footer .icon {
      vertical-align: text-bottom; }
  @media (min-width: 320px) {
    .tile ~ .triangle {
      left: calc(6rem - 17px); } }
  .tile .favorite {
    width: 27px;
    height: 27px;
    top: 115px;
    right: 10px;
    background: #B9BCC0;
    line-height: 27px;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    .tile .favorite svg {
      margin: 0 auto;
      stroke: #72757e;
      height: 12px;
      width: 12px;
      stroke-width: 0.6px; }
    .tile .favorite:hover svg path {
      fill: #0945a5;
      stroke: #0945a5; }
    .tile .favorite.active svg path {
      stroke: #0945a5;
      fill: #0945a5; }
    .tile .favorite.active:hover {
      background: #0945a5; }
      .tile .favorite.active:hover svg path {
        stroke: #fff;
        fill: #fff; }

.workflow-elements-container .icon {
  max-height: 1em;
  max-width: 1em; }

img.lock-icon {
  width: 25px; }

.workflow-box-container {
  position: relative;
  z-index: 0;
  width: calc(200% + 8px); }
  .workflow-elements-container:nth-of-type(odd) .workflow-box-container {
    left: 0; }
  .workflow-elements-container:nth-of-type(even) .workflow-box-container {
    left: calc(-100% - 8px); }
  .workflow-box-container .workflow-box-inner {
    position: relative;
    background: #B9BCC0;
    padding: 0;
    left: 0; }
    .layout-3-cols .workflow-box-container .workflow-box-inner {
      margin: -18px 0 14px; }
    .layout-4-cols .workflow-box-container .workflow-box-inner {
      margin: -18px 0 30px; }
  @media (min-width: 636px) {
    .layout-3-cols .workflow-box-container {
      width: calc(300% + 48px); }
    .layout-4-cols .workflow-box-container {
      width: calc(300% + 16px); }
    .workflow-elements-container:nth-of-type(3n + 1) .workflow-box-container {
      left: 0; }
    .layout-3-cols .workflow-elements-container:nth-of-type(3n + 2) .workflow-box-container {
      left: calc(-100% - 24px); }
    .layout-4-cols .workflow-elements-container:nth-of-type(3n + 2) .workflow-box-container {
      left: calc(-100% - 8px); }
    .layout-3-cols .workflow-elements-container:nth-of-type(3n + 3) .workflow-box-container {
      left: calc(-200% - 48px); }
    .layout-4-cols .workflow-elements-container:nth-of-type(3n + 3) .workflow-box-container {
      left: calc(-200% - 16px); } }
  @media (min-width: 1200px) {
    .layout-3-cols .workflow-box-container {
      width: calc(300% + 48px); }
    .layout-3-cols .workflow-elements-container:nth-of-type(3n + 1) .workflow-box-container {
      left: 0; }
    .layout-3-cols .workflow-elements-container:nth-of-type(3n + 2) .workflow-box-container {
      left: calc(-100% - 24px); }
    .layout-3-cols .workflow-elements-container:nth-of-type(3n + 3) .workflow-box-container {
      left: calc(-200% - 48px); } }
  .layout-4-cols .workflow-box-container {
    width: calc(400% + 24px); }
  .layout-4-cols .workflow-elements-container:nth-of-type(4n + 1) .workflow-box-container {
    left: 0; }
  .layout-4-cols .workflow-elements-container:nth-of-type(4n + 2) .workflow-box-container {
    left: calc(-100% - 8px) !important; }
  .layout-4-cols .workflow-elements-container:nth-of-type(4n + 3) .workflow-box-container {
    left: calc(-200% - 16px) !important; }
  .layout-4-cols .workflow-elements-container:nth-of-type(4n + 4) .workflow-box-container {
    left: calc(-300% - 24px) !important; }

.box-header {
  border-bottom: 2px solid #fff;
  padding-top: 5px; }
  .box-header .btn-toggle-description {
    float: right; }
    .box-header .btn-toggle-description:hover {
      cursor: pointer; }
  .box-header .magenta {
    color: #e20074; }

.box-sub-header .col:nth-child(1) {
  border-right: 2px solid #fff; }

.box-sub-header h6 {
  padding-top: 5px;
  font-size: .9em; }

.box-content {
  background-color: #E7E8EA; }
  .box-content .col:nth-child(1) {
    border-right: 2px solid #fff; }
  .box-content.description .col:nth-child(1) {
    border-right: none; }
  .box-content.description .collapse {
    width: 100%; }

.box-actions {
  margin: 0;
  margin-top: 10px; }
  .box-actions .box-link .icon {
    margin-right: 4px; }
  .description .box-actions {
    padding: 5px 0 15px; }

ul.box-notification-list {
  margin: 0;
  margin-top: 10px;
  padding-left: 19px;
  font-size: .9em; }
  ul.box-notification-list li {
    padding-left: 0;
    margin-bottom: 5px; }

.box-aufgaben-list {
  min-height: 200px;
  max-height: 200px;
  padding-bottom: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 5px; }

.back-expanded {
  background: none;
  border: none;
  padding: 0; }

.row {
  position: relative; }
  .row .sidebar {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease; }
    .row .sidebar .sidebar-header {
      border-bottom: 1px solid #d0d0d0;
      padding-top: 0.5rem; }
      .row .sidebar .sidebar-header .container {
        display: flex;
        justify-content: space-between;
        align-items: center; }
    .row .sidebar:not(.expanded) .sidebar-header .container, .row .sidebar:not(.expanded) .tasks .container {
      padding: 0;
      display: flex;
      justify-content: space-between; }
    @media (min-width: 636px) {
      .row .sidebar .filters-container {
        justify-content: flex-start; }
        .row .sidebar .filters-container .filter {
          margin-left: unset; } }
    .row .sidebar .overview-content {
      padding: 40px 0; }
    .row .sidebar .sidebar-icon {
      cursor: pointer;
      background: none;
      border: none; }
    .row .sidebar .sidebar-title {
      text-transform: uppercase;
      line-height: 1.3;
      font-size: 1.1em;
      font-weight: 500;
      color: #4b4b4b;
      padding: 0;
      margin: 0;
      flex-grow: 1;
      padding-bottom: 0.1rem; }
      .row .sidebar .sidebar-title .count {
        color: #72757e; }
    .row .sidebar .sidebar-buttons {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end; }
    .row .sidebar .sidebar-links {
      flex-grow: 2;
      font-size: 1rem; }
      @media (max-width: 636px) {
        .row .sidebar .sidebar-links {
          display: none !important; } }
    .row .sidebar .tasks {
      overflow: visible; }
      @media (min-width: 320px) {
        .row .sidebar .tasks {
          overflow-y: auto; } }
      .row .sidebar .tasks .tasks-header {
        border-bottom: 1px solid #d0d0d0; }
        .row .sidebar .tasks .tasks-header .container {
          padding-bottom: 0.5rem;
          color: #6c6c6c;
          font-size: 1rem;
          align-items: flex-end;
          display: flex;
          justify-content: space-between; }
          .row .sidebar .tasks .tasks-header .container.mobile > div {
            display: none !important; }
            .row .sidebar .tasks .tasks-header .container.mobile > div.task-inner {
              display: block !important; }
          .row .sidebar .tasks .tasks-header .container > div {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
            padding: 0.5rem; }
            .row .sidebar .tasks .tasks-header .container > div.small-column {
              flex: 0 0 8.33333%;
              max-width: 8.33333%; }
            .row .sidebar .tasks .tasks-header .container > div.task-inner {
              flex: 0 0 33.33333%;
              max-width: 33.33333%; }
            .row .sidebar .tasks .tasks-header .container > div:last-child {
              padding-right: 0; }
      .row .sidebar .tasks.notifications .tasks-header .container {
        margin-top: 0; }
        .row .sidebar .tasks.notifications .tasks-header .container > div:last-child {
          text-align: right;
          padding-right: 0.5rem; }
      .row .sidebar .tasks.notifications .tasks-header ul li > :not(:first-child) {
        text-align: left; }
    .row .sidebar.expanded {
      background-color: white;
      max-width: 100%;
      width: 100%;
      padding: 0 15px 0 15px; }
      .row .sidebar.expanded .sidebar-header {
        margin-top: 40px; }
      .row .sidebar.expanded .sidebar-buttons {
        justify-content: flex-start; }
      .row .sidebar.expanded .tasks .task {
        padding: 20.4px 0; }
        .row .sidebar.expanded .tasks .task .container {
          display: flex;
          justify-content: space-between; }
          .row .sidebar.expanded .tasks .task .container > *:not(.mobile) {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
            padding: 0.5rem; }
            .row .sidebar.expanded .tasks .task .container > *:not(.mobile).small-column {
              flex: 0 0 8.33333%;
              max-width: 8.33333%; }
            .row .sidebar.expanded .tasks .task .container > *:not(.mobile).task-inner {
              flex: 0 0 33.33333%;
              max-width: 33.33333%; }
        .row .sidebar.expanded .tasks .task > div {
          font-size: 1rem; }
        .row .sidebar.expanded .tasks .task .more-options {
          padding-right: 17px; }
        .row .sidebar.expanded .tasks .task .actions-container {
          margin-right: 1.5rem; }

@media (min-width: 1200px) {
  .tasks ul .task:first-child {
    padding-top: 0; } }

.tasks ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.sticky .tasks {
  max-height: 100vh; }

.task-inner-div {
  width: 100%; }

.task {
  border-bottom: 1px solid #d0d0d0;
  padding: 15px 0; }
  .task > .container {
    padding: 0;
    display: flex;
    justify-content: space-between; }
  @media (max-width: 1200px) {
    .task {
      padding: 1.5rem 0 !important; } }
  .task.task-highlighted {
    border-bottom-color: #dcdcdc; }
    .task.task-highlighted .task-inner {
      margin-top: 5px;
      text-align: left;
      border: 0;
      padding: 0;
      background: transparent;
      background-color: #B9BCC0; }
      .task.task-highlighted .task-inner:first-child {
        margin-top: 0; }
  .task .task-inner {
    display: flex;
    transition: margin-left .1s ease-in-out; }
    .task .task-inner.just-in {
      margin-left: -100%; }
  .task a {
    text-decoration: none; }
  .task .task-name {
    color: #000;
    font-size: 18px;
    margin-bottom: 2px; }
  .task .task-process-name {
    color: #6c6c6c;
    margin-bottom: .4rem;
    font-size: 16px;
    margin-bottom: 0; }
  .task .task-info {
    color: #6c6c6c;
    font-size: 16px; }
  .task.group {
    float: none !important; }
    .task.group .task-info, .task.group .task-owner {
      color: #0945a5; }
  .task.notification .task-status {
    text-align: right; }
  .task .task-menu {
    margin-left: auto; }
  .task:hover .task-name span {
    color: #0945a5; }
  .task:hover .task-process-name, .task:hover .task-info {
    color: #000; }
  .task:hover .kebab-dropdown svg, .task:hover .group-icon svg {
    fill: #000 !important; }
    @media screen and (-ms-high-contrast: active) {
      .task:hover .kebab-dropdown svg, .task:hover .group-icon svg {
        fill: #fff; } }
    .task:hover .kebab-dropdown svg:hover, .task:hover .group-icon svg:hover {
      fill: #0945a5 !important; }
  .task:hover .favorite svg g g path {
    stroke: #000 !important; }
    @media screen and (-ms-high-contrast: active) {
      .task:hover .favorite svg g g path {
        stroke: #fff; } }
  .task:hover .favorite.active svg g path {
    fill: #000 !important; }
    @media screen and (-ms-high-contrast: active) {
      .task:hover .favorite.active svg g path {
        fill: #fff; } }
  .task .task-details {
    padding: 10px;
    display: block;
    width: 100%; }
    .task .task-details.disabled-link {
      pointer-events: none; }
    .task .task-details:hover {
      text-decoration: none; }
  .task .task-details {
    padding: 0;
    display: block;
    width: 100%;
    position: relative; }
    .task .task-details.disabled-link {
      pointer-events: none; }
    .task .task-details:hover {
      text-decoration: none; }
  .task .task-menu {
    margin-left: auto; }
  .task .actions-container .kebab-dropdown {
    margin-bottom: 4px; }
  .task .actions-container .group-icon {
    width: 40px;
    height: 24px;
    cursor: default; }
    .task .actions-container .group-icon svg {
      fill: #72757e; }
  .task .actions-container .favorite {
    cursor: pointer; }
    .task .actions-container .favorite svg {
      width: 32px;
      height: 24px; }
      .task .actions-container .favorite svg g g path {
        stroke: #72757e; }
      .task .actions-container .favorite svg .active path g {
        fill: orange; }
    .task .actions-container .favorite.active svg g path {
      fill: #72757e; }
    .task .actions-container .favorite:hover svg g g path {
      stroke: #0945a5 !important; }
    .task .actions-container .favorite:hover.active svg g path {
      fill: #0945a5 !important; }
  @media (max-width: 1200px) {
    .task .task-icon {
      margin-right: 1.5rem; }
      .task .task-icon .active svg g path {
        fill: #0945a5; } }
  .task .task-icon svg {
    width: 1.5rem; }
  .task .bell-icon svg g g path {
    fill: #0945a5; }
    @media screen and (-ms-high-contrast: active) {
      .task .bell-icon svg g g path {
        fill: #fff; } }

.external-link-bar .navbar-brand {
  color: #fff; }

.external-link-bar .navbar-brand-title {
  margin-left: 30px; }

.external-link-bar .nav-item {
  justify-self: flex-end; }

.external-link-frame {
  border: 0;
  position: absolute;
  width: 100%;
  height: 100vh;
  height: 100%;
  height: -webkit-fill-available; }

.alerts {
  display: flex;
  flex-direction: column;
  flex-grow: 1; }

.alert.flash {
  display: flex;
  color: #6c6c6c;
  padding: 0;
  border-radius: 0;
  border-color: rgba(178, 178, 178, 0.7);
  margin-bottom: 30px; }
  .alert.flash .alert-icon {
    background-color: rgba(178, 178, 178, 0.7);
    background-image: url("/images/alert-info.svg");
    background-position: 50% 50%;
    background-size: 25px;
    background-repeat: no-repeat;
    width: 82px;
    height: 82px; }
  .alert.flash.warning {
    border-color: rgba(253, 126, 20, 0.7); }
    .alert.flash.warning .alert-icon {
      background-image: url("/images/alert-warning.svg");
      background-color: rgba(253, 126, 20, 0.7); }
  .alert.flash.danger {
    border-color: rgba(149, 28, 28, 0.7); }
    .alert.flash.danger .alert-icon {
      background-color: rgba(149, 28, 28, 0.7);
      background-image: url("/images/alert-danger.svg"); }
  .alert.flash.success {
    border-color: rgba(28, 118, 28, 0.7); }
    .alert.flash.success .alert-icon {
      background-color: rgba(28, 118, 28, 0.7);
      background-image: url("/images/alert-success.svg"); }
  .alert.flash .alert-text {
    padding: 21px 32px;
    flex-grow: 1;
    background: #fff; }

.description-alert {
  border: none;
  border-radius: 0;
  background-color: #4b4b4b;
  color: #fff;
  font-size: 1.3rem;
  margin-bottom: 0;
  transition: all 300ms ease-out;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .description-alert > .container {
    position: relative; }
  .description-alert.show {
    height: inherit;
    padding: 48px 0; }
    .description-alert.show .close {
      display: block; }
  .description-alert .label {
    color: #d0d0d0;
    margin-bottom: 10px; }
  .description-alert .description {
    margin-bottom: 0; }
  .description-alert .close {
    padding-top: 0;
    display: none; }
    .description-alert .close::before {
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M15.81,14.99l-6.99-7l6.99-7c0.24-0.24,0.2-0.63-0.04-0.83c-0.24-0.2-0.59-0.2-0.79,0l-6.99,7l-6.99-7 C0.75-0.08,0.36-0.04,0.16,0.2c-0.2,0.24-0.2,0.59,0,0.79l6.99,7l-6.99,7c-0.24,0.24-0.2,0.63,0.04,0.83c0.24,0.2,0.59,0.2,0.79,0 l6.99-7l6.99,7c0.24,19.24,0.59,0.24,0.83,0.04C16.04,15.66,16.08,15.26,15.81,14.99C15.85,15.03,15.81,15.03,15.81,14.99z' fill='white'/%3E%3C/svg%3E"); }
      @media (max-width: 636px) {
        .description-alert .close::before {
          width: 16px;
          height: 16px; } }
  @media (max-width: 1200px) {
    .description-alert {
      font-size: 1.1rem; }
      .description-alert.show {
        padding: 30px 0px; } }
  @media (max-width: 636px) {
    .description-alert {
      font-size: 0.9rem; }
      .description-alert.show {
        padding: 20px 0px; }
      .description-alert .label {
        font-size: 0.75rem; } }

.smart_tile {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 3px 6px 0 rgba(124, 124, 124, 0.16);
  background-color: #fff; }
  .smart_tile button {
    border: none; }
  .smart_tile .clickable-metric {
    padding: 0; }
  @media (min-width: 1200px) {
    .smart_tile {
      -webkit-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out; }
      .smart_tile:hover {
        box-shadow: 0 6px 12px 0 rgba(124, 124, 124, 0.16);
        transform: translate(0px, -4px); }
        .smart_tile:hover .smart_tile_actions_wrapper {
          background: #fff; } }
  .smart_tile .card-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1; }
  .smart_tile .tile-gradient {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    opacity: 0.75;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 9%, rgba(32, 32, 32, 0.5) 62%, #383838); }
  .smart_tile:hover .smart_tile_actions_wrapper .action, .smart_tile:hover .smart_tile_actions_wrapper .icon-action {
    visibility: visible; }
  .smart_tile:hover .smart_tile_info .smart_tile_name {
    color: #0945a5; }
  .smart_tile:hover:not(.restricted) .tile-gradient {
    display: block; }
  .smart_tile.focus-within .smart_tile_actions_wrapper .action, .smart_tile.focus-within .smart_tile_actions_wrapper .icon-action {
    visibility: visible; }
  .smart_tile.focus-within .smart_tile_info .smart_tile_name {
    color: #0945a5; }
  .smart_tile.focus-within:not(.restricted) .tile-gradient {
    display: block; }
  .smart_tile:focus-within .smart_tile_actions_wrapper .action, .smart_tile:focus-within .smart_tile_actions_wrapper .icon-action {
    visibility: visible; }
  .smart_tile:focus-within .smart_tile_info .smart_tile_name {
    color: #0945a5; }
  .smart_tile:focus-within:not(.restricted) .tile-gradient {
    display: block; }
  .smart_tile.layout-2 {
    margin-bottom: 30px;
    width: 400px;
    height: 377px; }
    .smart_tile.layout-2 .smart_tile_info .smart_tile_name {
      font-size: 1.40625rem; }
    .smart_tile.layout-2 .smart_tile_img {
      height: 268px; }
    .smart_tile.layout-2 .icon_wrapper {
      width: 60px;
      height: 60px;
      border-radius: 50px; }
      .smart_tile.layout-2 .icon_wrapper.smart_tile_actions_counter div {
        font-size: 1.40625rem;
        font-weight: 700;
        text-overflow: ellipsis; }
      .smart_tile.layout-2 .icon_wrapper svg {
        height: 32px;
        width: 32px; }
    @media (max-width: 636px) {
      .smart_tile.layout-2 {
        width: 138px;
        height: 130px;
        margin: 6px !important; }
        .smart_tile.layout-2 .smart_tile_info {
          min-height: 40px; }
          .smart_tile.layout-2 .smart_tile_info .smart_tile_name, .smart_tile.layout-2 .smart_tile_info .smart_tile_category {
            font-size: 0.75rem !important; }
        .smart_tile.layout-2 .smart_tile_img {
          height: 90px; }
        .smart_tile.layout-2 .smart_tile_actions .smart_tile_actions_counter div, .smart_tile.layout-2 .smart_tile_actions .icon-counter div {
          font-size: 0.75rem; }
        .smart_tile.layout-2 .smart_tile_actions .icon_wrapper, .smart_tile.layout-2 .smart_tile_actions .icon-action {
          width: 25px;
          height: 25px; }
          .smart_tile.layout-2 .smart_tile_actions .icon_wrapper svg, .smart_tile.layout-2 .smart_tile_actions .icon-action svg {
            width: 11px;
            height: 11px; } }
  .smart_tile.layout-3 {
    margin-bottom: 20px;
    width: 270px;
    height: 255px; }
    .smart_tile.layout-3 .smart_tile_img {
      height: 195px; }
    @media (max-width: 1200px) {
      .smart_tile.layout-3 {
        width: 200px;
        height: 200px;
        margin: 12px !important; }
        .smart_tile.layout-3 .smart_tile_img {
          height: 137px; }
        .smart_tile.layout-3 .icon_wrapper, .smart_tile.layout-3 .icon-action {
          width: 40px;
          height: 40px; }
          .smart_tile.layout-3 .icon_wrapper svg, .smart_tile.layout-3 .icon-action svg {
            width: 20px;
            height: 20px; } }
  .smart_tile.layout-4 {
    margin-bottom: 10px;
    width: 200px;
    height: 189px; }
    .smart_tile.layout-4 .smart_tile_info .smart_tile_name {
      font-size: 0.98438rem !important; }
    .smart_tile.layout-4 .icon_wrapper, .smart_tile.layout-4 .icon-action {
      width: 32px;
      height: 32px;
      border-radius: 50px; }
      .smart_tile.layout-4 .icon_wrapper.smart_tile_actions_counter div, .smart_tile.layout-4 .icon_wrapper.icon-counter div, .smart_tile.layout-4 .icon-action.smart_tile_actions_counter div, .smart_tile.layout-4 .icon-action.icon-counter div {
        font-size: 0.98438rem;
        font-weight: 700;
        text-overflow: ellipsis; }
      .smart_tile.layout-4 .icon_wrapper svg, .smart_tile.layout-4 .icon-action svg {
        height: 16px;
        width: 16px; }
    .smart_tile.layout-4 .smart_tile_img {
      height: 134px; }
  .smart_tile a {
    text-decoration: none; }
  .smart_tile .smart_tile_img {
    position: relative; }
  .smart_tile .smart_tile_actions_wrapper {
    position: absolute;
    top: -62px;
    left: 0;
    padding: 15px;
    width: 100%;
    z-index: 10;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    @media (max-width: 1200px) {
      .smart_tile .smart_tile_actions_wrapper {
        top: -72px;
        padding: 15px 5px; } }
    @media (max-width: 636px) {
      .smart_tile .smart_tile_actions_wrapper {
        top: -55px; } }
    .smart_tile .smart_tile_actions_wrapper .action, .smart_tile .smart_tile_actions_wrapper .icon-action {
      visibility: hidden; }
      .smart_tile .smart_tile_actions_wrapper .action.favorite, .smart_tile .smart_tile_actions_wrapper .icon-action.favorite {
        visibility: visible; }
  .smart_tile .smart_tile_info {
    padding: 9px 15px;
    min-height: 60px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    z-index: 99;
    bottom: 0;
    position: absolute;
    width: 100%;
    background: white; }
    .smart_tile .smart_tile_info .smart_tile_name {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
      color: #0945a5; }
    .smart_tile .smart_tile_info .smart_tile_category {
      font-size: 1rem;
      font-weight: 400;
      color: #6c6c6c; }

.table-task-data {
  padding-top: 40px;
  margin-bottom: 0; }
  @media (min-width: 320px) and (max-width: 635.98px) {
    .table-task-data {
      padding-top: 30px; } }
  .table-task-data .table-title {
    font-size: 18px;
    font-weight: 500;
    color: #000; }
    @media (min-width: 636px) and (max-width: 1199.98px) {
      .table-task-data .table-title {
        font-size: 16px; } }
    @media (min-width: 320px) and (max-width: 635.98px) {
      .table-task-data .table-title {
        font-size: 15px; } }
  .table-task-data .tr {
    min-height: 60px; }
    .table-task-data .tr .nested-row {
      width: 100%; }
      .table-task-data .tr .nested-row.key {
        padding-bottom: 5px; }
    @media (min-width: 320px) and (max-width: 635.98px) {
      .table-task-data .tr {
        min-height: 48px; } }
  .table-task-data .tr:after {
    content: '';
    min-height: inherit;
    font-size: 0; }
  .table-task-data .td {
    vertical-align: middle;
    border-top: none;
    font-size: 18px; }
    @media (min-width: 636px) and (max-width: 1199.98px) {
      .table-task-data .td {
        font-size: 16px; } }
    @media (min-width: 320px) and (max-width: 635.98px) {
      .table-task-data .td {
        font-size: 15px;
        width: 111px; } }
    .table-task-data .td.key {
      font-size: 16px;
      font-weight: 500;
      color: #6c6c6c;
      margin-right: 27px;
      width: 370px;
      align-self: center; }
      @media (min-width: 636px) and (max-width: 1199.98px) {
        .table-task-data .td.key {
          width: 300px;
          font-size: 15px; } }
      @media (min-width: 320px) and (max-width: 635.98px) {
        .table-task-data .td.key {
          font-size: 12px;
          width: 111px;
          margin-right: 10px; } }
  @media (min-width: 320px) and (max-width: 635.98px) {
    .table-task-data .td:first-child {
      margin-right: 10px; } }
  .table-task-data .download-icon #download_outline path {
    fill: #72757e; }
  .table-task-data .download-icon:hover #download_outline path {
    fill: black; }

.table-striped .table-body .tr:nth-of-type(odd):not(.nested-row) {
  background-color: #E7E8EA; }

.nested-table-container .td {
  padding: 10px 0; }

.nested-table-container .tr {
  min-height: 48px; }
  .nested-table-container .tr.key {
    font-size: 16px;
    color: #6c6c6c;
    font-weight: 500;
    min-height: 37px;
    display: flex;
    align-items: flex-end;
    border-bottom: solid 0.5px #a4a4a4; }
    @media (min-width: 636px) and (max-width: 1199.98px) {
      .nested-table-container .tr.key {
        width: 300px;
        font-size: 15px; } }
    @media (min-width: 320px) and (max-width: 635.98px) {
      .nested-table-container .tr.key {
        font-size: 12px;
        height: 35px; } }
  .nested-table-container .tr .nested-table .tr:not(:first-child) {
    border-top: solid 0.5px #d0d0d0; }

.nested-table-container .tr:after {
  content: '';
  min-height: inherit;
  font-size: 0; }

.smart_tile_actions_wrapper .smart_tile_actions, .smart_tile_actions_wrapper .icons {
  display: flex;
  flex: 0 0 1;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  .smart_tile_actions_wrapper .smart_tile_actions .external-link, .smart_tile_actions_wrapper .icons .external-link {
    stroke-width: 1; }
    .smart_tile_actions_wrapper .smart_tile_actions .external-link svg path, .smart_tile_actions_wrapper .icons .external-link svg path {
      fill: #8b8f97;
      stroke: none; }
      .smart_tile_actions_wrapper .smart_tile_actions .external-link svg path.empty, .smart_tile_actions_wrapper .icons .external-link svg path.empty {
        stroke: none !important;
        fill: none !important; }
  .smart_tile_actions_wrapper .smart_tile_actions .clickable-metric, .smart_tile_actions_wrapper .icons .clickable-metric {
    padding: 0; }
  .smart_tile_actions_wrapper .smart_tile_actions .icon_wrapper, .smart_tile_actions_wrapper .smart_tile_actions .icon-action, .smart_tile_actions_wrapper .icons .icon_wrapper, .smart_tile_actions_wrapper .icons .icon-action {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0; }
    .smart_tile_actions_wrapper .smart_tile_actions .icon_wrapper svg, .smart_tile_actions_wrapper .smart_tile_actions .icon-action svg, .smart_tile_actions_wrapper .icons .icon_wrapper svg, .smart_tile_actions_wrapper .icons .icon-action svg {
      height: 21px; }
  .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_info,
  .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_link,
  .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_favorite,
  .smart_tile_actions_wrapper .smart_tile_actions .icon-action, .smart_tile_actions_wrapper .icons .smart_tile_actions_info,
  .smart_tile_actions_wrapper .icons .smart_tile_actions_link,
  .smart_tile_actions_wrapper .icons .smart_tile_actions_favorite,
  .smart_tile_actions_wrapper .icons .icon-action {
    fill: black;
    stroke: black; }
    @media screen and (-ms-high-contrast: active) {
      .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_info svg path,
      .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_link svg path,
      .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_favorite svg path,
      .smart_tile_actions_wrapper .smart_tile_actions .icon-action svg path, .smart_tile_actions_wrapper .icons .smart_tile_actions_info svg path,
      .smart_tile_actions_wrapper .icons .smart_tile_actions_link svg path,
      .smart_tile_actions_wrapper .icons .smart_tile_actions_favorite svg path,
      .smart_tile_actions_wrapper .icons .icon-action svg path {
        fill: #fff;
        stroke: #fff; } }
    .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_info.icon-play svg,
    .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_link.icon-play svg,
    .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_favorite.icon-play svg,
    .smart_tile_actions_wrapper .smart_tile_actions .icon-action.icon-play svg, .smart_tile_actions_wrapper .icons .smart_tile_actions_info.icon-play svg,
    .smart_tile_actions_wrapper .icons .smart_tile_actions_link.icon-play svg,
    .smart_tile_actions_wrapper .icons .smart_tile_actions_favorite.icon-play svg,
    .smart_tile_actions_wrapper .icons .icon-action.icon-play svg {
      margin-left: 2px; }
    .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_info:hover svg path,
    .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_link:hover svg path,
    .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_favorite:hover svg path,
    .smart_tile_actions_wrapper .smart_tile_actions .icon-action:hover svg path, .smart_tile_actions_wrapper .icons .smart_tile_actions_info:hover svg path,
    .smart_tile_actions_wrapper .icons .smart_tile_actions_link:hover svg path,
    .smart_tile_actions_wrapper .icons .smart_tile_actions_favorite:hover svg path,
    .smart_tile_actions_wrapper .icons .icon-action:hover svg path {
      fill: #0945a5;
      stroke: #0945a5; }
    .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_info.info-action,
    .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_link.info-action,
    .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_favorite.info-action,
    .smart_tile_actions_wrapper .smart_tile_actions .icon-action.info-action, .smart_tile_actions_wrapper .icons .smart_tile_actions_info.info-action,
    .smart_tile_actions_wrapper .icons .smart_tile_actions_link.info-action,
    .smart_tile_actions_wrapper .icons .smart_tile_actions_favorite.info-action,
    .smart_tile_actions_wrapper .icons .icon-action.info-action {
      stroke: none; }
    .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_info:hover.info-action g,
    .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_link:hover.info-action g,
    .smart_tile_actions_wrapper .smart_tile_actions .smart_tile_actions_favorite:hover.info-action g,
    .smart_tile_actions_wrapper .smart_tile_actions .icon-action:hover.info-action g, .smart_tile_actions_wrapper .icons .smart_tile_actions_info:hover.info-action g,
    .smart_tile_actions_wrapper .icons .smart_tile_actions_link:hover.info-action g,
    .smart_tile_actions_wrapper .icons .smart_tile_actions_favorite:hover.info-action g,
    .smart_tile_actions_wrapper .icons .icon-action:hover.info-action g {
      fill: none; }

#tabs-container .header {
  display: none; }

#tabs-container .dashboard-row.list {
  padding: 10px 0;
  margin: 0px;
  border-bottom: solid 1px #72757e; }
  #tabs-container .dashboard-row.list .col {
    padding: 0px; }
  #tabs-container .dashboard-row.list .card {
    background: white;
    border: none; }
    #tabs-container .dashboard-row.list .card.disabled {
      pointer-events: none; }
    #tabs-container .dashboard-row.list .card .card-link {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
  #tabs-container .dashboard-row.list:hover {
    box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.16); }
    #tabs-container .dashboard-row.list:hover .info-container h4 {
      color: #0945a5; }
    #tabs-container .dashboard-row.list:hover .right-content .btn, #tabs-container .dashboard-row.list:hover .right-content .icon-action {
      border-color: #000; }
      #tabs-container .dashboard-row.list:hover .right-content .btn.tasks-counter, #tabs-container .dashboard-row.list:hover .right-content .icon-action.tasks-counter {
        background-color: #0945a5;
        border-color: #0945a5; }
      #tabs-container .dashboard-row.list:hover .right-content .btn svg path, #tabs-container .dashboard-row.list:hover .right-content .icon-action svg path {
        fill: #000; }
      #tabs-container .dashboard-row.list:hover .right-content .btn.external-link svg path, #tabs-container .dashboard-row.list:hover .right-content .icon-action.external-link svg path {
        fill: #000;
        stroke: none; }
    #tabs-container .dashboard-row.list:hover .right-content .icon-favorite svg path, #tabs-container .dashboard-row.list:hover .right-content .icon-play svg path {
      stroke: #000 !important; }
    #tabs-container .dashboard-row.list:hover .right-content .icon-favorite.favorite svg path, #tabs-container .dashboard-row.list:hover .right-content .icon-play.favorite svg path {
      stroke: #000 !important;
      fill: #000 !important; }
  #tabs-container .dashboard-row.list .left-content {
    text-transform: capitalize; }
    #tabs-container .dashboard-row.list .left-content .info-container {
      padding-left: 20px; }
      #tabs-container .dashboard-row.list .left-content .info-container h4 {
        font-size: 18px;
        font-weight: bold;
        color: black;
        margin-bottom: 0; }
      #tabs-container .dashboard-row.list .left-content .info-container h5 {
        font-size: 18px;
        color: #6c6c6c;
        margin-bottom: 0; }
  #tabs-container .dashboard-row.list .right-content {
    padding-right: 10px;
    z-index: 1; }
    #tabs-container .dashboard-row.list .right-content .icons {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    #tabs-container .dashboard-row.list .right-content .btn, #tabs-container .dashboard-row.list .right-content .icon-action, #tabs-container .dashboard-row.list .right-content .clickable-metric {
      width: 48px;
      height: 48px;
      padding: 0px;
      margin-right: 30px;
      background-color: white; }
      #tabs-container .dashboard-row.list .right-content .btn.clickable-metric, #tabs-container .dashboard-row.list .right-content .icon-action.clickable-metric, #tabs-container .dashboard-row.list .right-content .clickable-metric.clickable-metric {
        border: none; }
      #tabs-container .dashboard-row.list .right-content .btn:last-child, #tabs-container .dashboard-row.list .right-content .icon-action:last-child, #tabs-container .dashboard-row.list .right-content .clickable-metric:last-child {
        margin-right: 0; }
      #tabs-container .dashboard-row.list .right-content .btn.tasks-counter, #tabs-container .dashboard-row.list .right-content .btn.icon-counter, #tabs-container .dashboard-row.list .right-content .icon-action.tasks-counter, #tabs-container .dashboard-row.list .right-content .icon-action.icon-counter, #tabs-container .dashboard-row.list .right-content .clickable-metric.tasks-counter, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-counter {
        background-color: transparent;
        border: none; }
        #tabs-container .dashboard-row.list .right-content .btn.tasks-counter .count, #tabs-container .dashboard-row.list .right-content .btn.icon-counter .count, #tabs-container .dashboard-row.list .right-content .icon-action.tasks-counter .count, #tabs-container .dashboard-row.list .right-content .icon-action.icon-counter .count, #tabs-container .dashboard-row.list .right-content .clickable-metric.tasks-counter .count, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-counter .count {
          color: #0945a5;
          font-size: 1rem;
          font-weight: normal; }
      #tabs-container .dashboard-row.list .right-content .btn svg, #tabs-container .dashboard-row.list .right-content .icon-action svg, #tabs-container .dashboard-row.list .right-content .clickable-metric svg {
        width: 16px;
        height: 16px; }
        #tabs-container .dashboard-row.list .right-content .btn svg path, #tabs-container .dashboard-row.list .right-content .icon-action svg path, #tabs-container .dashboard-row.list .right-content .clickable-metric svg path {
          fill: #72757e; }
          @media screen and (-ms-high-contrast: active) {
            #tabs-container .dashboard-row.list .right-content .btn svg path, #tabs-container .dashboard-row.list .right-content .icon-action svg path, #tabs-container .dashboard-row.list .right-content .clickable-metric svg path {
              fill: #fff !important; } }
      #tabs-container .dashboard-row.list .right-content .btn:hover svg path, #tabs-container .dashboard-row.list .right-content .icon-action:hover svg path, #tabs-container .dashboard-row.list .right-content .clickable-metric:hover svg path {
        fill: #0945a5 !important; }
      #tabs-container .dashboard-row.list .right-content .btn.external-link svg path, #tabs-container .dashboard-row.list .right-content .icon-action.external-link svg path, #tabs-container .dashboard-row.list .right-content .clickable-metric.external-link svg path {
        fill: #72757e;
        stroke: none; }
        #tabs-container .dashboard-row.list .right-content .btn.external-link svg path.empty, #tabs-container .dashboard-row.list .right-content .icon-action.external-link svg path.empty, #tabs-container .dashboard-row.list .right-content .clickable-metric.external-link svg path.empty {
          stroke: none !important;
          fill: none !important; }
      #tabs-container .dashboard-row.list .right-content .btn.external-link:hover, #tabs-container .dashboard-row.list .right-content .icon-action.external-link:hover, #tabs-container .dashboard-row.list .right-content .clickable-metric.external-link:hover {
        border-color: #0945a5; }
        #tabs-container .dashboard-row.list .right-content .btn.external-link:hover svg path, #tabs-container .dashboard-row.list .right-content .icon-action.external-link:hover svg path, #tabs-container .dashboard-row.list .right-content .clickable-metric.external-link:hover svg path {
          fill: #0945a5 !important; }
          #tabs-container .dashboard-row.list .right-content .btn.external-link:hover svg path.empty, #tabs-container .dashboard-row.list .right-content .icon-action.external-link:hover svg path.empty, #tabs-container .dashboard-row.list .right-content .clickable-metric.external-link:hover svg path.empty {
            stroke: none !important;
            fill: none !important; }
      #tabs-container .dashboard-row.list .right-content .btn.link:hover, #tabs-container .dashboard-row.list .right-content .btn.icon-info:hover, #tabs-container .dashboard-row.list .right-content .btn.icon-play:hover, #tabs-container .dashboard-row.list .right-content .icon-action.link:hover, #tabs-container .dashboard-row.list .right-content .icon-action.icon-info:hover, #tabs-container .dashboard-row.list .right-content .icon-action.icon-play:hover, #tabs-container .dashboard-row.list .right-content .clickable-metric.link:hover, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-info:hover, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-play:hover {
        border-color: #0945a5; }
        #tabs-container .dashboard-row.list .right-content .btn.link:hover svg path, #tabs-container .dashboard-row.list .right-content .btn.icon-info:hover svg path, #tabs-container .dashboard-row.list .right-content .btn.icon-play:hover svg path, #tabs-container .dashboard-row.list .right-content .icon-action.link:hover svg path, #tabs-container .dashboard-row.list .right-content .icon-action.icon-info:hover svg path, #tabs-container .dashboard-row.list .right-content .icon-action.icon-play:hover svg path, #tabs-container .dashboard-row.list .right-content .clickable-metric.link:hover svg path, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-info:hover svg path, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-play:hover svg path {
          fill: #0945a5 !important; }
      #tabs-container .dashboard-row.list .right-content .btn.link svg path, #tabs-container .dashboard-row.list .right-content .btn.icon-info svg path, #tabs-container .dashboard-row.list .right-content .btn.icon-play svg path, #tabs-container .dashboard-row.list .right-content .icon-action.link svg path, #tabs-container .dashboard-row.list .right-content .icon-action.icon-info svg path, #tabs-container .dashboard-row.list .right-content .icon-action.icon-play svg path, #tabs-container .dashboard-row.list .right-content .clickable-metric.link svg path, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-info svg path, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-play svg path {
        stroke: none; }
      #tabs-container .dashboard-row.list .right-content .btn.icon-favorite svg g path, #tabs-container .dashboard-row.list .right-content .btn.icon-play svg g path, #tabs-container .dashboard-row.list .right-content .icon-action.icon-favorite svg g path, #tabs-container .dashboard-row.list .right-content .icon-action.icon-play svg g path, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-favorite svg g path, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-play svg g path {
        stroke: #72757e;
        fill: none; }
      #tabs-container .dashboard-row.list .right-content .btn.icon-favorite:hover, #tabs-container .dashboard-row.list .right-content .btn.icon-play:hover, #tabs-container .dashboard-row.list .right-content .icon-action.icon-favorite:hover, #tabs-container .dashboard-row.list .right-content .icon-action.icon-play:hover, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-favorite:hover, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-play:hover {
        border-color: #0945a5; }
        #tabs-container .dashboard-row.list .right-content .btn.icon-favorite:hover svg path, #tabs-container .dashboard-row.list .right-content .btn.icon-play:hover svg path, #tabs-container .dashboard-row.list .right-content .icon-action.icon-favorite:hover svg path, #tabs-container .dashboard-row.list .right-content .icon-action.icon-play:hover svg path, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-favorite:hover svg path, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-play:hover svg path {
          stroke: #0945a5 !important;
          fill: none !important; }
      #tabs-container .dashboard-row.list .right-content .btn.icon-favorite.favorite svg g path, #tabs-container .dashboard-row.list .right-content .btn.icon-play.favorite svg g path, #tabs-container .dashboard-row.list .right-content .icon-action.icon-favorite.favorite svg g path, #tabs-container .dashboard-row.list .right-content .icon-action.icon-play.favorite svg g path, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-favorite.favorite svg g path, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-play.favorite svg g path {
        fill: #72757e;
        stroke: #72757e; }
      #tabs-container .dashboard-row.list .right-content .btn.icon-favorite.favorite:hover svg path, #tabs-container .dashboard-row.list .right-content .btn.icon-play.favorite:hover svg path, #tabs-container .dashboard-row.list .right-content .icon-action.icon-favorite.favorite:hover svg path, #tabs-container .dashboard-row.list .right-content .icon-action.icon-play.favorite:hover svg path, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-favorite.favorite:hover svg path, #tabs-container .dashboard-row.list .right-content .clickable-metric.icon-play.favorite:hover svg path {
        stroke: #0945a5 !important;
        fill: #0945a5 !important; }

.grid-slider {
  width: 70px; }
  .grid-slider .rc-slider {
    height: auto; }
    .grid-slider .rc-slider .rc-slider-rail {
      height: 1px;
      background-color: #72757e; }
      @media screen and (-ms-high-contrast: active) {
        .grid-slider .rc-slider .rc-slider-rail {
          border: 1px solid; } }
    .grid-slider .rc-slider .rc-slider-handle {
      border: solid 2px black;
      height: 10px;
      width: 10px; }
      .grid-slider .rc-slider .rc-slider-handle:active {
        box-shadow: 0 0 1px black; }
      .grid-slider .rc-slider .rc-slider-handle:focus {
        border-color: #000;
        box-shadow: 0 0 0 0px #000;
        outline: none; }
    .grid-slider .rc-slider .rc-slider-track {
      background-color: black;
      height: 2px;
      margin-top: -1px; }

@media (max-width: 635.98px) {
  .brandbar .container, .footer .container {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    height: 42px;
    align-items: center; }
  .brandbar .brand-logo, .footer .brand-logo, .brandbar .brand-claim, .footer .brand-claim {
    margin-top: 0px;
    font-size: 0.75rem;
    float: none; }
  .brandbar .brand-logo img, .footer .brand-logo img {
    height: 21px; }
  .brandbar .brand-claim, .footer .brand-claim {
    text-align: right; }
    .brandbar .brand-claim img, .footer .brand-claim img {
      width: 141px; }
  .brandbar, .footer,
  .brand-navbar {
    min-height: 42px;
    z-index: 1020; } }

.accordion-item {
  background: #E7E8EA; }

.accordion-header {
  background: #B9BCC0;
  border-bottom: 2px solid #fff;
  background-position: 98% center;
  background-repeat: no-repeat;
  background-size: 20px;
  background-image: url("/images/arrow_down.svg"); }
  .accordion-header.open {
    background-image: url("/images/arrow_up.svg"); }
    .accordion-header.open button {
      color: #0945a5; }
  .accordion-header button {
    font-size: .85em;
    text-transform: uppercase;
    display: block;
    width: 100%;
    text-align: left;
    color: #72757e; }
    .accordion-header button:hover {
      color: #0945a5; }

.accordion-body {
  padding: 5px 13px 20px;
  font-size: .85em; }

.empty-state {
  color: #b2b2b2; }
  .empty-state .title {
    margin-top: 10px;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: .3rem; }
  .empty-state .subtitle {
    font-weight: 200;
    font-size: .95em; }

.search-form {
  position: absolute;
  right: 205px;
  flex-grow: 1;
  z-index: 2;
  width: 450px;
  padding-top: 0 !important; }
  .search-form .form-group {
    width: 100%;
    margin-bottom: 0; }
    .search-form .form-group .search-icon {
      top: 0;
      right: 0px; }
    .search-form .form-group:hover input.form-control, .search-form .form-group:hover input.close-search-form {
      border-color: #000;
      background: #e6e6e6; }
    .search-form .form-group:hover svg {
      fill: #0945a5; }
    .search-form .form-group .form-control, .search-form .form-group .close-search-form {
      display: none; }
      .search-form .form-group .form-control::-ms-clear, .search-form .form-group .close-search-form::-ms-clear {
        display: none; }
  @media (min-width: 1200px) {
    .search-form > .container {
      padding: 0;
      margin: 0; } }
  @media (min-width: 320px) {
    .search-form {
      padding-top: .55rem;
      border-top: none;
      border-bottom-color: #0945a5;
      height: auto; } }
  @media (max-width: 1200px) {
    .search-form {
      width: 75%;
      left: 0; }
      .search-form > .container {
        position: relative; } }
  .search-form.active .form-control, .search-form.active .close-search-form {
    display: block; }
  .search-form .close-search-form {
    width: 1rem !important;
    flex: none !important;
    padding-right: 1.25rem; }
  .search-form input.form-control, .search-form input.close-search-form {
    border: none;
    border-radius: 100px;
    background-color: #e6e6e6;
    padding: 14px 20px;
    height: auto;
    font-size: 0.87rem; }
    .search-form input.form-control:focus, .search-form input.close-search-form:focus {
      border-color: #0945a5 !important;
      background: #e6e6e6 !important; }
    .search-form input.form-control:active, .search-form input.close-search-form:active {
      border-color: #0945a5 !important;
      background: #e6e6e6 !important; }
    @media (max-width: 1200px) {
      .search-form input.form-control, .search-form input.close-search-form {
        border: none; } }
  @media (max-width: 636px) {
    .search-form {
      width: 70%; }
      .search-form input.form-control, .search-form input.close-search-form {
        padding: 8px 5px;
        font-size: 0.8rem; } }
  .search-form .input-group-append .btn-search-submit {
    background-color: #b2b2b2;
    border-color: transparent;
    padding-top: 2px; }
  .search-form .icon {
    width: 16px; }
  .search-form ul.suggestions {
    position: absolute;
    padding: 0;
    background-color: #fff;
    width: 100%;
    z-index: 99999;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #72757e;
    border-radius: 0.25rem;
    overflow: hidden; }
    @media (max-width: 636px) {
      .search-form ul.suggestions .btn {
        font-size: 0.9rem; } }
    .search-form ul.suggestions li {
      color: #000;
      padding: 10px 0; }
      .search-form ul.suggestions li:not(:last-child) {
        border-bottom: solid 1px #72757e; }
      .search-form ul.suggestions li button, .search-form ul.suggestions li .no-suggestions {
        width: 100%;
        border: 0;
        background: transparent;
        margin: 0;
        border-radius: 0;
        padding: 0.375rem 0; }
      .search-form ul.suggestions li button {
        text-align: left; }
        .search-form ul.suggestions li button:hover, .search-form ul.suggestions li button.highlighted {
          color: #0945a5; }
      .search-form ul.suggestions li .no-suggestions {
        font-size: 1rem;
        line-height: 1.21;
        letter-spacing: 0.8px;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        text-align: center;
        color: #6c6c6c; }
    @media (max-width: 1200px) {
      .search-form ul.suggestions {
        border: none;
        border-radius: 0;
        top: 64px;
        left: 0;
        overflow: hidden; } }
    @media (max-width: 636px) {
      .search-form ul.suggestions {
        top: 42px; } }

.badge {
  border: 0;
  font-weight: 500;
  height: 23px;
  min-width: 23px;
  text-align: center;
  border-radius: 50%; }
  @media (max-width: 635.98px) {
    .badge {
      min-width: 13px;
      height: 13px; } }

.badge {
  font-size: 1em;
  color: #6c6c6c;
  line-height: 13.5px;
  background-color: #E7E8EA; }
  .badge.badge-primary {
    color: white;
    background-color: #0945a5; }

.search-results {
  list-style: none;
  margin: 0;
  padding: 10px 0 0; }

.search-result {
  display: flex;
  border-bottom: none;
  padding: 0 !important;
  position: relative;
  background: white;
  margin-top: 10px; }
  .search-result .search-result-link {
    align-items: baseline;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    border-bottom: 1px solid #B9BCC0;
    color: #4b4b4b;
    text-decoration: none; }
    .search-result .search-result-link:hover {
      text-decoration: none; }
      .search-result .search-result-link:hover:after {
        content: "";
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 1px;
        z-index: -1;
        transform: scale(0.987);
        box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.16); }
  .search-result .search-result-info {
    flex-grow: 1; }
    .search-result .search-result-info .search-result-title {
      color: #000;
      font-weight: bold;
      font-size: 1.1rem;
      margin-bottom: 0.3rem; }
    .search-result .search-result-info .search-result-subtitle {
      color: #6c6c6c;
      font-size: 1.1rem;
      margin-bottom: 0; }
  .search-result .type {
    font-size: 1rem; }
  .search-result .results-icon {
    object-fit: cover;
    width: 130px;
    height: 87px;
    margin-right: 15px;
    display: flex;
    background: #dcdcdc; }
    .search-result .results-icon svg {
      width: 24px;
      height: 24px;
      margin: auto;
      fill: #72757e; }
  @media (max-width: 636px) {
    .search-result .results-icon {
      display: none; }
    .search-result .search-result-title, .search-result .search-result-subtitle {
      font-size: 1rem; }
    .search-result .search-result-link {
      padding: 15px 0; } }

.search-filter-tabs {
  margin: 40px 0;
  background: #B9BCC0; }

.search-filter-tabs-container {
  display: flex;
  flex: 1 1 auto; }

.search-filter-tab {
  display: flex;
  min-height: 130px;
  align-items: stretch;
  flex-grow: 1;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #B9BCC0; }
  .search-filter-tab:hover, .search-filter-tab.active {
    border-bottom: 1px solid #0945a5; }
    .search-filter-tab:hover .search-filter-tab-link, .search-filter-tab.active .search-filter-tab-link {
      background: #e2e3e5; }
      .search-filter-tab:hover .search-filter-tab-link .search-filter-tab-title, .search-filter-tab.active .search-filter-tab-link .search-filter-tab-title {
        color: #0945a5; }
  .search-filter-tab:first-child {
    border-left: 1px solid #fff; }

.search-filter-tab-link {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  padding: 30px 0; }
  .search-filter-tab-link .search-filter-tab-title {
    align-self: center;
    text-transform: uppercase;
    flex-grow: 1; }
  .search-filter-tab-link .search-filter-tab-count {
    font-size: .9em;
    color: #a4a4a4;
    align-self: center; }
  .search-filter-tab-link:hover {
    background: #e2e3e5; }
    .search-filter-tab-link:hover .search-filter-tab-title {
      color: #0945a5; }

.Toastify__toast-container {
  width: auto !important;
  z-index: 99999 !important; }
  @media (max-width: 636px) {
    .Toastify__toast-container {
      width: calc(100% - 20px) !important; } }

.Toastify__toast-container--top-right {
  top: 115px !important;
  right: 55px !important; }
  @media (max-width: 1200px) {
    .Toastify__toast-container--top-right {
      top: 193px !important;
      right: 31px !important; } }
  @media (max-width: 636px) {
    .Toastify__toast-container--top-right {
      top: 10px !important;
      right: 10px !important;
      left: 10px !important; } }

.Toastify__toast.toast-message {
  min-height: 40px;
  min-width: 270px;
  max-width: 470px;
  font-size: .85em;
  background: #B9BCC0;
  padding: 0; }
  @media (max-width: 636px) {
    .Toastify__toast.toast-message {
      width: 100%;
      min-height: 36px !important; } }
  .Toastify__toast.toast-message .toast-text {
    align-self: center;
    margin: -0.2rem 15px 0 15px;
    overflow: hidden;
    font-weight: bold;
    font-size: 1.12rem;
    padding: 10px 0;
    max-width: 354px; }
    @media (max-width: 636px) {
      .Toastify__toast.toast-message .toast-text {
        font-size: 0.87rem; } }
  .Toastify__toast.toast-message .Toastify__toast-body {
    display: flex;
    margin: 0; }
  .Toastify__toast.toast-message.Toastify__toast {
    min-height: 48px;
    border-radius: 4px; }
  .Toastify__toast.toast-message.Toastify__toast--success {
    background: #fff;
    color: #1c761c;
    border: 1px solid #1c761c; }
    .Toastify__toast.toast-message.Toastify__toast--success svg path {
      fill: #1c761c;
      width: 24px; }
  .Toastify__toast.toast-message.Toastify__toast--error {
    background: #fff;
    color: #951c1c;
    border: 1px solid #951c1c; }
    .Toastify__toast.toast-message.Toastify__toast--error svg path {
      fill: #951c1c;
      width: 24px; }
  .Toastify__toast.toast-message .toast-icon {
    display: inline-block;
    width: 62px;
    min-height: 48px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #fff; }
    .Toastify__toast.toast-message .toast-icon.success {
      width: 56px; }
    @media (max-width: 636px) {
      .Toastify__toast.toast-message .toast-icon {
        min-height: 36px !important; }
        .Toastify__toast.toast-message .toast-icon.success {
          width: 45px !important; }
        .Toastify__toast.toast-message .toast-icon svg {
          width: 16px !important; } }
  .Toastify__toast.toast-message .Toastify__close-button {
    margin: auto 16px;
    padding-top: 0;
    text-indent: -9999px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    opacity: 1;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M15.81,14.99l-6.99-7l6.99-7c0.24-0.24,0.2-0.63-0.04-0.83c-0.24-0.2-0.59-0.2-0.79,0l-6.99,7l-6.99-7 C0.75-0.08,0.36-0.04,0.16,0.2c-0.2,0.24-0.2,0.59,0,0.79l6.99,7l-6.99,7c-0.24,0.24-0.2,0.63,0.04,0.83c0.24,0.2,0.59,0.2,0.79,0 l6.99-7l6.99,7c0.24,19.24,0.59,0.24,0.83,0.04C16.04,15.66,16.08,15.26,15.81,14.99C15.85,15.03,15.81,15.03,15.81,14.99z' fill='192130'/%3E%3C/svg%3E"); }

.spinner-container {
  display: flex;
  flex-grow: 1; }

.spinner-inner {
  display: flex;
  flex-grow: 1; }

.spinner {
  position: relative;
  width: 100px; }
  .spinner:before {
    content: '';
    display: block;
    padding-top: 100%; }

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }

.path {
  stroke-dasharray: 89,200;
  stroke-dashoffset: 0;
  animation: dash 1s ease-in-out infinite;
  stroke-linecap: round;
  stroke: #0945a5; }

@keyframes rotate {
  100% {
    transform: rotate(360deg); } }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }

.profile-menu button.nav-link {
  border: none;
  text-align: left; }

.profile-menu .profile-link {
  color: #b2b2b2; }

.profile-menu .show .triangle {
  background-image: url("../images/dropdown-arrow.png");
  background-size: cover;
  position: absolute;
  top: 30px;
  left: calc(50% - 5px);
  width: 30px;
  height: 30px;
  z-index: 9999999; }

.profile-menu .dropdown-menu {
  background-color: #fff;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #72757e;
  left: 10px !important;
  margin-top: 1.25rem;
  padding-top: 0;
  width: 209px; }
  .profile-menu .dropdown-menu .dropdown-item {
    color: #000;
    padding: 7px 30px;
    cursor: pointer;
    font-size: 1rem; }
    .profile-menu .dropdown-menu .dropdown-item.profile-name {
      color: #6c6c6c;
      border-bottom: solid 1px #72757e;
      padding: 15px 30px;
      margin-bottom: 7px; }
    .profile-menu .dropdown-menu .dropdown-item:hover {
      background: none;
      font-weight: bold;
      color: #000; }

@media (min-width: 636px) {
  .profile-menu .dropdown-menu {
    left: 7px; }
  .profile-menu .username {
    line-height: 1;
    vertical-align: bottom; }
  .profile-menu .show .username {
    color: #0945a5; } }

@media (max-width: 636px) {
  .profile-menu .dropdown-menu {
    left: 7px !important; }
  .profile-menu .show .triangle {
    top: 22px;
    left: calc(50% - 1px); } }

.header-title {
  font-size: 1.2em; }

.header-subtitle {
  font-size: 1em; }

@media (max-width: 636px) {
  .magazine_page {
    padding: 0; }
    .magazine_page > .container {
      padding: 0 !important; }
      .magazine_page > .container > span {
        display: none; } }

.magazine_page > .container {
  padding: 15px 0; }

.magazine-header {
  margin-top: 32px;
  width: 100%;
  position: relative;
  padding: 30px 0 38px 0; }
  .magazine-header .magazine_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: url("/images/magazin-banner-wfilter.jpg") no-repeat;
    background-size: cover;
    backdrop-filter: blur(47px);
    -webkit-backdrop-filter: blur(47px); }
  .magazine-header h2 {
    z-index: 2;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    opacity: 0.99;
    font-size: 6.25rem;
    color: #0945a5;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin: 0;
    padding: 0; }
  @media (max-width: 1200px) {
    .magazine-header h2 {
      font-size: 1.93rem; } }
  @media (max-width: 636px) {
    .magazine-header {
      margin-top: 0;
      padding: 16px 0 24px 0; }
      .magazine-header h2 {
        font-size: 0.18rem; } }

.carousel.articles {
  margin: 30px 0;
  background-color: #383838;
  min-height: 410px;
  padding-bottom: 90px; }
  .carousel.articles h2 {
    font-size: 2.25rem;
    text-transform: uppercase;
    margin-bottom: 30px; }
  .carousel.articles .carousel__inner-slide {
    color: white;
    padding: 30px;
    font-size: 1.125rem; }
    .carousel.articles .carousel__inner-slide .slider-content {
      display: flex;
      justify-content: space-between;
      min-height: 168px; }
      .carousel.articles .carousel__inner-slide .slider-content .rotation-wrapper {
        position: relative;
        width: 20px;
        min-height: 168px;
        margin-left: -5px; }
      .carousel.articles .carousel__inner-slide .slider-content .article-category {
        display: inline-block;
        transform: rotate(-90deg);
        position: absolute;
        top: 0;
        right: 100%;
        white-space: nowrap;
        transform-origin: right top;
        text-transform: uppercase;
        font-size: 1.31rem;
        font-weight: bold; }
      .carousel.articles .carousel__inner-slide .slider-content .article-content {
        flex-grow: 1;
        width: calc(100% - 55px);
        margin-left: 55px;
        margin-top: -5px; }
      .carousel.articles .carousel__inner-slide .slider-content .article-image-container {
        float: right;
        clip-path: inset(0 0 0 0);
        margin: -53px 0px 20px 20px;
        height: 273px;
        width: 289px; }
        .carousel.articles .carousel__inner-slide .slider-content .article-image-container .smart_tile {
          margin: 0 !important; }
    .carousel.articles .carousel__inner-slide .show-more {
      background: none;
      color: #b2d0ff;
      font-size: 1rem;
      padding: 0;
      border: none;
      display: inline-block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      padding: 5px 0;
      background-color: #383838; }
  .carousel.articles .actions {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    border-top: solid 1px #fff;
    position: absolute;
    width: 100%;
    bottom: 0; }
    .carousel.articles .actions .carousel__dot {
      border-radius: 50%;
      padding: 0;
      width: 10px;
      height: 10px;
      background-color: #a4a4a4;
      border: none;
      margin: 0 7px; }
      .carousel.articles .actions .carousel__dot.carousel__dot--selected {
        background-color: white; }
    .carousel.articles .actions .carousel__back-button, .carousel.articles .actions .carousel__next-button {
      font-size: 1rem;
      color: white;
      background: none;
      border: none;
      width: 68px; }
    .carousel.articles .actions .carousel__back-button {
      padding-left: 25px;
      background: url("../images/arrow-left.png") left center no-repeat;
      background-size: 15px 15px; }
    .carousel.articles .actions .carousel__next-button {
      padding-right: 25px;
      background: url("../images/arrow-right.png") right center no-repeat;
      background-size: 15px 15px; }
  .carousel.articles .expanded {
    height: auto;
    padding-bottom: 0 !important; }
    .carousel.articles .expanded .carousel__inner-slide {
      height: auto;
      position: relative;
      overflow: hidden; }
  @media (max-width: 1200px) {
    .carousel.articles {
      min-height: 346px;
      padding-bottom: 90px; }
      .carousel.articles h2 {
        font-size: 1.5rem; }
      .carousel.articles .carousel__slider {
        min-height: 285px; }
        .carousel.articles .carousel__slider .carousel__slide {
          min-height: 310px; }
          .carousel.articles .carousel__slider .carousel__slide .carousel__inner-slide {
            padding: 20px; }
        .carousel.articles .carousel__slider .slider-content .article-content {
          margin-left: 20px; }
        .carousel.articles .carousel__slider .slider-content .article-image-container {
          height: 198px;
          width: 214px; }
          .carousel.articles .carousel__slider .slider-content .article-image-container .article-image, .carousel.articles .carousel__slider .slider-content .article-image-container .smart_tile {
            height: 198px !important;
            width: 214px !important; }
        .carousel.articles .carousel__slider .slider-content .article-category {
          font-size: 1.12rem; } }
  @media (max-width: 636px) {
    .carousel.articles {
      margin-top: 0;
      padding-bottom: 73px; }
      .carousel.articles h2 {
        margin-bottom: 20px;
        font-size: 1.31rem; }
      .carousel.articles .carousel__slider {
        min-height: 250px; }
        .carousel.articles .carousel__slider .carousel__slide {
          min-height: 275px; }
      .carousel.articles .rotation-wrapper {
        display: none; }
      .carousel.articles .carousel__inner-slide {
        padding: 30px 16px; }
        .carousel.articles .carousel__inner-slide .slider-content .article-content {
          margin-left: 0;
          font-size: 15px; }
        .carousel.articles .carousel__inner-slide .slider-content .article-image-container {
          display: none; }
      .carousel.articles .actions {
        padding: 20px 16px 30px; }
        .carousel.articles .actions .carousel__back-button, .carousel.articles .actions .carousel__next-button {
          display: none; }
        .carousel.articles .actions .carousel__dot {
          width: 5px;
          height: 5px; }
          .carousel.articles .actions .carousel__dot:first-child {
            margin-left: 0; }
          .carousel.articles .actions .carousel__dot:last-child {
            margin-right: 0; } }

.task-tools-container {
  margin: 0; }
  .task-tools-container .tasks-container {
    padding: 15px 15px 0 0; }
    .task-tools-container .tasks-container .header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: solid 1px #a4a4a4;
      margin-bottom: 30px; }
      .task-tools-container .tasks-container .header h3 {
        text-transform: uppercase;
        font-size: 1.31rem;
        font-weight: 500;
        margin: 0; }
      @media (max-width: 636px) {
        .task-tools-container .tasks-container .header h3 {
          font-size: 1.12rem; } }
  .task-tools-container .tools-container {
    padding: 15px 0 15px 15px; }
    .task-tools-container .tools-container .carousel.tools {
      min-height: 300px; }
      .task-tools-container .tools-container .carousel.tools .header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: solid 1px #a4a4a4;
        margin-bottom: 30px; }
        .task-tools-container .tools-container .carousel.tools .header h3 {
          text-transform: uppercase;
          font-size: 1.31rem;
          font-weight: 500;
          margin: 0; }
        @media (max-width: 636px) {
          .task-tools-container .tools-container .carousel.tools .header h3 {
            font-size: 1.12rem; } }
        .task-tools-container .tools-container .carousel.tools .header .actions button {
          border-radius: 50%;
          padding: 0;
          width: 10px;
          height: 10px;
          background-color: #a4a4a4;
          border: none;
          margin: 0 7px; }
          .task-tools-container .tools-container .carousel.tools .header .actions button:disabled {
            background-color: #000; }
        .task-tools-container .tools-container .carousel.tools .header .actions .carousel__dot {
          width: 6px;
          height: 6px; }
      .task-tools-container .tools-container .carousel.tools .carousel__slider {
        margin: 0 -15px; }
      .task-tools-container .tools-container .carousel.tools .carousel__inner-slide {
        padding: 0 15px; }
        .task-tools-container .tools-container .carousel.tools .carousel__inner-slide .smart_tile {
          width: 100%; }
  @media (max-width: 1200px) {
    .task-tools-container {
      flex-direction: column-reverse;
      margin-top: 60px; }
      .task-tools-container .tools-container {
        padding: 0; }
        .task-tools-container .tools-container .smart_tile.layout-3 {
          margin: 0 0 15px 0 !important; }
      .task-tools-container .tasks-container {
        padding-right: 0; }
        .task-tools-container .tasks-container .magazine_tasks_list li:first-child {
          padding-top: 0 !important; }
        .task-tools-container .tasks-container .magazine_tasks_list .text-muted {
          padding: 0 15px; } }
  @media (max-width: 636px) {
    .task-tools-container .tools-container {
      padding: 0 16px; }
      .task-tools-container .tools-container .smart_tile.layout-2 {
        margin: 0 !important; }
      .task-tools-container .tools-container .carousel.tools {
        min-height: 180px; }
        .task-tools-container .tools-container .carousel.tools .carousel__slider {
          margin: 0 -6px; }
        .task-tools-container .tools-container .carousel.tools .carousel__inner-slide {
          padding: 0 6px; }
        .task-tools-container .tools-container .carousel.tools .header {
          border-bottom: none;
          margin-bottom: 5px; }
        .task-tools-container .tools-container .carousel.tools .actions .carousel__dot-group button:nth-child(2n+2) {
          display: none; }
        .task-tools-container .tools-container .carousel.tools .actions .carousel__dot-group button:nth-child(5) {
          margin-right: 0; }
    .task-tools-container .tasks-container .header {
      padding: 0 15px 15px; }
    .task-tools-container .tasks-container .task {
      padding: 0 15px 15px !important; } }

.folders-container .header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: solid 1px #a4a4a4;
  margin-bottom: 30px;
  margin: 0; }
  .folders-container .header h3 {
    text-transform: uppercase;
    font-size: 1.31rem;
    font-weight: 500;
    margin: 0; }
  @media (max-width: 636px) {
    .folders-container .header h3 {
      font-size: 1.12rem; } }

.folders-container .folders-content {
  margin: 0; }
  .folders-container .folders-content .folder {
    background-color: #E7E8EA;
    color: black;
    width: 23.5%;
    height: 255px;
    float: left;
    margin: 2% 2% 0 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer; }
    .folders-container .folders-content .folder:hover {
      color: black;
      text-decoration: none; }
    .folders-container .folders-content .folder:nth-child(4n+4) {
      margin-right: 0; }
    .folders-container .folders-content .folder h4 {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 1.125rem;
      margin: 16px; }
    .folders-container .folders-content .folder .image {
      height: 182px;
      width: 100%;
      border-bottom: solid 1px #d0d0d0;
      background: url("/images/folder.png") no-repeat center; }
    .folders-container .folders-content .folder.smart_tile {
      background-color: #fff; }
      .folders-container .folders-content .folder.smart_tile .smart_tile_img {
        height: 182px; }

@media (max-width: 1200px) {
  .folders-container {
    margin-top: 60px; }
    .folders-container .folders-content .folder {
      width: 31%;
      margin: 3.5% 3.5% 0 0 !important; }
      .folders-container .folders-content .folder:nth-child(4n+4) {
        margin-right: 3.5% !important; }
      .folders-container .folders-content .folder:nth-child(3n+3) {
        margin-right: 0 !important; } }

@media (max-width: 636px) {
  .folders-container {
    margin-top: 40px;
    padding: 0 15px; }
    .folders-container .header {
      border-bottom: none;
      margin-bottom: 20px; }
    .folders-container .folders-content .folder {
      height: 131px;
      width: 48%;
      margin: 4% 4% 0 0 !important; }
      .folders-container .folders-content .folder:nth-child(3n+3) {
        margin-right: 4% !important; }
      .folders-container .folders-content .folder:nth-child(2n+2) {
        margin-right: 0 !important; }
      .folders-container .folders-content .folder h4 {
        font-size: 0.75rem;
        margin: 0 7px;
        line-height: 38px; }
      .folders-container .folders-content .folder .image {
        height: 93px;
        background-size: 55%; }
      .folders-container .folders-content .folder.smart_tile .smart_tile_img {
        height: 93px; }
      .folders-container .folders-content .folder.smart_tile .smart_tile_info {
        padding-top: 3px; } }

.main-underline-tabs {
  background-color: #fff;
  border-bottom: none;
  position: relative; }
  .main-underline-tabs .nav-item {
    border: 0;
    margin-bottom: -1px;
    padding-top: 10px;
    font-size: .75rem; }
    .main-underline-tabs .nav-item .nav-link {
      text-align: center;
      font-weight: 400;
      color: black;
      border: 0;
      border-bottom: 1px solid transparent;
      padding: .5rem 0;
      margin-right: 40px; }
      .main-underline-tabs .nav-item .nav-link:hover {
        border: 0;
        color: #6c6c6c;
        color: black;
        border-bottom: 3px solid #a4a4a4; }
      .main-underline-tabs .nav-item .nav-link.active {
        border: 0;
        color: #000;
        font-weight: 500;
        text-shadow: -0.03ex 0 #4b4b4b, 0.03ex 0 #4b4b4b;
        border-bottom: 3px solid #0945a5; }
    @media (max-width: 636px) {
      .main-underline-tabs .nav-item {
        padding-top: 0;
        font-size: 1rem; }
        .main-underline-tabs .nav-item .nav-link {
          margin-top: 15px;
          padding: 5px 0 10px !important;
          margin-right: 1rem; }
        .main-underline-tabs .nav-item .smart-nav-link {
          margin-top: 0;
          padding-top: 0; } }
  @media (min-width: 636px) {
    .main-underline-tabs .nav-item {
      padding-top: 0;
      font-size: 1.1em; } }

.switch.link {
  font-size: 75%;
  align-self: flex-end; }

.task-form input[readonly]:focus {
  background-color: #ededed;
  border: 1px solid rgba(0, 0, 0, 0.3); }

.task-form button.btn-info:not(:disabled):not(.disabled):hover {
  background-color: #083b8d; }

.task-form button.btn-info:not(:disabled):not(.disabled):active {
  background-color: #063175; }

.task-form button.btn-info:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 69, 165, 0.25); }

.task-form legend {
  font-size: 16px; }

.task-form #root__description {
  font-size: 15px; }

.task-form .radio-inline {
  margin-right: 10px;
  font-size: 16px;
  color: #6c6c6c; }
  .task-form .radio-inline input {
    margin-right: 5px; }

.task-form input[type="radio"] {
  margin: 0 10px; }

.task-form .form-group p {
  font-size: 15px; }

.task-form .form-group .control-label {
  font-size: 16px;
  color: #6c6c6c; }

.task-form .array-item {
  margin-top: 20px;
  border-bottom: 1px solid #E7E8EA; }

.task-form .array-item-add {
  max-width: 100% !important;
  width: 100% !important;
  margin: 20px 0 0 !important;
  text-align: left !important; }

.task-form .array-item-remove {
  margin-top: 30px; }

.form-actions-container {
  margin-top: 30px;
  float: right; }

@media (max-width: 635.98px) {
  .form-actions-container button {
    font-size: 18px; } }

.task-status.created .task-status-label {
  color: #1c761c; }

.task-status.created .inner-circle {
  background: #1c761c; }

.task-status.created .outer-circle {
  background: #bbdc9b; }
  @media screen and (-ms-high-contrast: active) {
    .task-status.created .outer-circle {
      border: 0.1875em solid #0F1C3F; }
      .task-status.created .outer-circle .inner-circle {
        border: 0.1875em solid #0F1C3F; } }

.task-status.ongoing .task-status-label, .task-status.processing .task-status-label {
  color: #ddae14; }

.task-status.ongoing .inner-circle, .task-status.processing .inner-circle {
  background: #ddae14; }

.task-status.ongoing .outer-circle, .task-status.processing .outer-circle {
  border: 1px solid #ddae14; }

.task-status.closed .task-status-label, .task-status.with_errors .task-status-label {
  color: #951c1c; }

.task-status.closed .inner-circle, .task-status.with_errors .inner-circle {
  background: #951c1c; }

.task-status.closed .outer-circle, .task-status.with_errors .outer-circle {
  background: #ed8b8b; }

.task-status .outer-circle {
  position: relative;
  height: 60px;
  width: 60px;
  display: inline-block;
  border-radius: 50%; }

.task-status .inner-circle {
  position: relative;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  border-radius: 50%; }

.task-status .task-status-label {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0; }
  @media (max-width: 1499.98px) {
    .task-status .task-status-label {
      height: 11px; } }

@media (max-width: 635.98px) {
  .task-status {
    display: flex;
    align-items: center;
    margin-top: 10px; }
    .task-status .outer-circle {
      border-width: 0px;
      height: 12px;
      width: 12px;
      margin: 0 10px 0 0; }
    .task-status .task-status-label {
      font-size: 12px; } }

.task-info-container .task-details-section-title {
  margin-bottom: 1rem; }

.task-info-container .task-details-section-text {
  margin-bottom: 0; }

.task-info-container h2 {
  font-size: 24px;
  color: #000;
  font-weight: 700; }

.task-info-container h5, .task-info-container p {
  color: #6c6c6c; }

@media (max-width: 635.98px) {
  .task-info-container .task-details-section-title {
    margin-top: 0;
    margin-bottom: 0; }
  .task-info-container h2, .task-info-container h5, .task-info-container p {
    font-size: 15px; }
  .task-info-container h5 {
    margin: 0; } }

.main-tabs-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d0d0d0;
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 636px) {
    .main-tabs-container {
      margin: unset; } }
  @media (max-width: 636px) {
    .main-tabs-container .nav-link {
      margin-top: 5px !important;
      margin-right: 12px !important; } }
  .main-tabs-container .nav-tabs {
    flex-grow: 1; }
  .main-tabs-container .change-view-icons, .main-tabs-container .filters {
    height: 100%;
    display: flex;
    align-items: center;
    overflow: visible;
    margin-left: 15px;
    margin-right: 15px; }
    .main-tabs-container .change-view-icons button, .main-tabs-container .filters button {
      background-color: transparent;
      border: none; }
      .main-tabs-container .change-view-icons button.grid-icon, .main-tabs-container .filters button.grid-icon {
        fill: #72757e; }
        .main-tabs-container .change-view-icons button.grid-icon svg g, .main-tabs-container .filters button.grid-icon svg g {
          stroke: #72757e; }
        .main-tabs-container .change-view-icons button.grid-icon.active, .main-tabs-container .filters button.grid-icon.active {
          fill: #000; }
          @media screen and (-ms-high-contrast: active) {
            .main-tabs-container .change-view-icons button.grid-icon.active svg path, .main-tabs-container .filters button.grid-icon.active svg path {
              fill: none;
              stroke: #000;
              stroke-width: 0.5; } }
          .main-tabs-container .change-view-icons button.grid-icon.active svg g, .main-tabs-container .filters button.grid-icon.active svg g {
            stroke: #000; }
        .main-tabs-container .change-view-icons button.grid-icon:hover, .main-tabs-container .filters button.grid-icon:hover {
          fill: #000; }
          @media screen and (-ms-high-contrast: active) {
            .main-tabs-container .change-view-icons button.grid-icon:hover svg path, .main-tabs-container .filters button.grid-icon:hover svg path {
              fill: none;
              stroke: #000;
              stroke-width: 0.5; } }
          .main-tabs-container .change-view-icons button.grid-icon:hover svg g, .main-tabs-container .filters button.grid-icon:hover svg g {
            stroke: #000; }
        @media screen and (-ms-high-contrast: active) {
          .main-tabs-container .change-view-icons button.grid-icon, .main-tabs-container .filters button.grid-icon {
            fill: #fff; }
            .main-tabs-container .change-view-icons button.grid-icon svg g, .main-tabs-container .filters button.grid-icon svg g {
              stroke: #fff; }
            .main-tabs-container .change-view-icons button.grid-icon.active, .main-tabs-container .filters button.grid-icon.active {
              fill: #0945a5; } }
    @media screen and (-ms-high-contrast: active) and (-ms-high-contrast: active) {
      .main-tabs-container .change-view-icons button.grid-icon.active svg path, .main-tabs-container .filters button.grid-icon.active svg path {
        fill: none;
        stroke: #0945a5;
        stroke-width: 0.5; } }
        @media screen and (-ms-high-contrast: active) {
              .main-tabs-container .change-view-icons button.grid-icon.active svg g, .main-tabs-container .filters button.grid-icon.active svg g {
                stroke: #0945a5; }
            .main-tabs-container .change-view-icons button.grid-icon:hover, .main-tabs-container .filters button.grid-icon:hover {
              fill: #0945a5; } }
    @media screen and (-ms-high-contrast: active) and (-ms-high-contrast: active) {
      .main-tabs-container .change-view-icons button.grid-icon:hover svg path, .main-tabs-container .filters button.grid-icon:hover svg path {
        fill: none;
        stroke: #0945a5;
        stroke-width: 0.5; } }
        @media screen and (-ms-high-contrast: active) {
              .main-tabs-container .change-view-icons button.grid-icon:hover svg g, .main-tabs-container .filters button.grid-icon:hover svg g {
                stroke: #0945a5; } }
      .main-tabs-container .change-view-icons button.list-icon, .main-tabs-container .filters button.list-icon {
        fill: #72757e; }
        .main-tabs-container .change-view-icons button.list-icon svg g, .main-tabs-container .filters button.list-icon svg g {
          stroke: #72757e; }
        .main-tabs-container .change-view-icons button.list-icon.active, .main-tabs-container .filters button.list-icon.active {
          fill: #000; }
          @media screen and (-ms-high-contrast: active) {
            .main-tabs-container .change-view-icons button.list-icon.active svg path, .main-tabs-container .filters button.list-icon.active svg path {
              fill: none;
              stroke: #000;
              stroke-width: 0.5; } }
          .main-tabs-container .change-view-icons button.list-icon.active svg g, .main-tabs-container .filters button.list-icon.active svg g {
            stroke: #000; }
        .main-tabs-container .change-view-icons button.list-icon:hover, .main-tabs-container .filters button.list-icon:hover {
          fill: #000; }
          @media screen and (-ms-high-contrast: active) {
            .main-tabs-container .change-view-icons button.list-icon:hover svg path, .main-tabs-container .filters button.list-icon:hover svg path {
              fill: none;
              stroke: #000;
              stroke-width: 0.5; } }
          .main-tabs-container .change-view-icons button.list-icon:hover svg g, .main-tabs-container .filters button.list-icon:hover svg g {
            stroke: #000; }
        @media screen and (-ms-high-contrast: active) {
          .main-tabs-container .change-view-icons button.list-icon, .main-tabs-container .filters button.list-icon {
            fill: #fff; }
            .main-tabs-container .change-view-icons button.list-icon svg g, .main-tabs-container .filters button.list-icon svg g {
              stroke: #fff; }
            .main-tabs-container .change-view-icons button.list-icon.active, .main-tabs-container .filters button.list-icon.active {
              fill: #0945a5; } }
    @media screen and (-ms-high-contrast: active) and (-ms-high-contrast: active) {
      .main-tabs-container .change-view-icons button.list-icon.active svg path, .main-tabs-container .filters button.list-icon.active svg path {
        fill: none;
        stroke: #0945a5;
        stroke-width: 0.5; } }
        @media screen and (-ms-high-contrast: active) {
              .main-tabs-container .change-view-icons button.list-icon.active svg g, .main-tabs-container .filters button.list-icon.active svg g {
                stroke: #0945a5; }
            .main-tabs-container .change-view-icons button.list-icon:hover, .main-tabs-container .filters button.list-icon:hover {
              fill: #0945a5; } }
    @media screen and (-ms-high-contrast: active) and (-ms-high-contrast: active) {
      .main-tabs-container .change-view-icons button.list-icon:hover svg path, .main-tabs-container .filters button.list-icon:hover svg path {
        fill: none;
        stroke: #0945a5;
        stroke-width: 0.5; } }
        @media screen and (-ms-high-contrast: active) {
              .main-tabs-container .change-view-icons button.list-icon:hover svg g, .main-tabs-container .filters button.list-icon:hover svg g {
                stroke: #0945a5; } }
        .main-tabs-container .change-view-icons button.list-icon g, .main-tabs-container .filters button.list-icon g {
          stroke-width: 0.6; }
  @media (min-width: 636px) {
    .main-tabs-container {
      margin-left: unset;
      margin-right: unset;
      box-shadow: none; }
      .main-tabs-container .change-view-icons {
        margin-right: unset; } }

.filters-container {
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 0;
  border-bottom: 1px solid #d0d0d0; }
  @media (min-width: 1200px) {
    .filters-container {
      border-bottom: none;
      padding: 1.2rem 0 0.8rem; } }
  .filters-container .filter {
    display: flex; }
    .filters-container .filter .filter-label,
    .filters-container .filter .filter-value {
      color: #6c6c6c;
      font-family: OpenSans, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: normal;
      text-align: left;
      text-overflow: ellipsis;
      text-decoration: none;
      margin-right: 1.5rem;
      background: none;
      border: none; }
      .filters-container .filter .filter-label.active,
      .filters-container .filter .filter-value.active {
        color: #000;
        font-weight: 700; }
      .filters-container .filter .filter-label:hover,
      .filters-container .filter .filter-value:hover {
        color: #000; }
      .filters-container .filter .filter-label.selected,
      .filters-container .filter .filter-value.selected {
        color: #000;
        font-weight: 700; }

.filters svg {
  cursor: pointer; }

.filters.is-open svg path {
  fill: #000 !important; }

.filters.active svg path {
  fill: #0945a5; }

@media (min-width: 1200px) {
  .workflows-container .filters-container {
    justify-content: flex-end; }
    .workflows-container .filters-container .filter-value:last-child {
      margin-right: 0; } }

.workflows-container .filters-container .change-view-icons button {
  border: none;
  background: none; }

.workflows-container .filters-container .change-view-icons .grid-icon {
  fill: #72757e; }
  .workflows-container .filters-container .change-view-icons .grid-icon svg g {
    stroke: #72757e; }
  .workflows-container .filters-container .change-view-icons .grid-icon.active {
    fill: #000; }
    @media screen and (-ms-high-contrast: active) {
      .workflows-container .filters-container .change-view-icons .grid-icon.active svg path {
        fill: none;
        stroke: #000;
        stroke-width: 0.5; } }
    .workflows-container .filters-container .change-view-icons .grid-icon.active svg g {
      stroke: #000; }
  .workflows-container .filters-container .change-view-icons .grid-icon:hover {
    fill: #000; }
    @media screen and (-ms-high-contrast: active) {
      .workflows-container .filters-container .change-view-icons .grid-icon:hover svg path {
        fill: none;
        stroke: #000;
        stroke-width: 0.5; } }
    .workflows-container .filters-container .change-view-icons .grid-icon:hover svg g {
      stroke: #000; }

.workflows-container .filters-container .change-view-icons .list-icon {
  fill: #72757e; }
  .workflows-container .filters-container .change-view-icons .list-icon svg g {
    stroke: #72757e; }
  .workflows-container .filters-container .change-view-icons .list-icon.active {
    fill: #000; }
    @media screen and (-ms-high-contrast: active) {
      .workflows-container .filters-container .change-view-icons .list-icon.active svg path {
        fill: none;
        stroke: #000;
        stroke-width: 0.5; } }
    .workflows-container .filters-container .change-view-icons .list-icon.active svg g {
      stroke: #000; }
  .workflows-container .filters-container .change-view-icons .list-icon:hover {
    fill: #000; }
    @media screen and (-ms-high-contrast: active) {
      .workflows-container .filters-container .change-view-icons .list-icon:hover svg path {
        fill: none;
        stroke: #000;
        stroke-width: 0.5; } }
    .workflows-container .filters-container .change-view-icons .list-icon:hover svg g {
      stroke: #000; }

.task-details .task-details-section {
  border-right: 1px solid #d0d0d0;
  padding-left: 30px;
  padding-right: 30px; }
  @media (max-width: 635.98px) {
    .task-details .task-details-section {
      border-right: unset;
      padding-right: 0px; } }
  .task-details .task-details-section:first-child {
    padding-left: 0; }
  .task-details .task-details-section:last-child {
    padding-right: 0;
    border-right: 0; }

.task-details .main-underline-tabs {
  font-size: 18px; }

.task-details .errors-content ul {
  margin-bottom: 5px; }

.task-form-container {
  flex-grow: 1;
  margin-right: 200px;
  min-width: 50%; }

.form-submit-info {
  background-color: #E7E8EA;
  border-left: 1px solid #0945a5;
  padding: 30px 30px;
  margin: 50px 0; }
  .form-submit-info h5 {
    font-size: 21px; }
  @media (max-width: 635.98px) {
    .form-submit-info {
      margin: 30px 0;
      padding: 30px 25px; }
      .form-submit-info h5, .form-submit-info p {
        font-size: 15px; } }

.form-tab-info {
  height: auto;
  background-color: #E7E8EA;
  padding: 30px 30px;
  font-family: OpenSans, sans-serif;
  border-left: 1px solid #0945a5;
  margin-left: 200px;
  min-width: 400px;
  max-width: 400px; }

.full-width-break {
  margin: 0; }

@media (max-width: 1199.98px) {
  .form-tab-container {
    width: 65%;
    margin: auto; }
  .form-actions-container {
    float: none;
    float: none;
    display: flex;
    justify-content: space-between; } }

@media (max-width: 635.98px) {
  .task-form-container {
    margin-top: 30px !important; }
  .form-tab-container {
    width: 100%;
    margin-left: 0;
    margin-right: 0; } }

.profile-container {
  padding-top: 1rem; }
  .profile-container .row {
    margin: 0; }
  .profile-container button {
    padding-left: 0px; }
  .profile-container h2 {
    font-size: 1.5rem;
    font-weight: bold; }
  .profile-container .profile-page-title {
    color: #000;
    margin-top: 32px;
    border-bottom: 1px solid #d0d0d0; }
    .profile-container .profile-page-title .nav-tabs {
      border: none; }
      .profile-container .profile-page-title .nav-tabs .nav-link {
        border: none;
        cursor: pointer;
        padding: 0;
        padding-bottom: 0.8rem;
        display: inline-block;
        font-size: 1.31rem;
        color: #000;
        margin-right: 30px;
        background: none; }
        .profile-container .profile-page-title .nav-tabs .nav-link:hover {
          border-bottom: solid 2px #d0d0d0; }
        .profile-container .profile-page-title .nav-tabs .nav-link.active {
          border-bottom: solid 2px #0945a5;
          font-weight: bold; }
    .profile-container .profile-page-title span {
      display: none; }
  .profile-container .profile-attributes-container {
    padding: 3rem 0 0;
    display: flex;
    flex-direction: column;
    flex-grow: 2; }
    .profile-container .profile-attributes-container .user-name {
      font-size: 3em;
      margin-bottom: 30.5px; }
    .profile-container .profile-attributes-container .profile-attributes {
      padding: 15px 15px 0;
      border-top: 1px solid #d0d0d0;
      color: #6c6c6c; }
      .profile-container .profile-attributes-container .profile-attributes .row:not(:last-child) {
        margin-bottom: 5px; }
        .profile-container .profile-attributes-container .profile-attributes .row:not(:last-child) .value {
          margin-bottom: 20px; }
      .profile-container .profile-attributes-container .profile-attributes .row .value {
        color: #000;
        font-weight: 500; }
  .profile-container .profile-attribute-container {
    padding: 3rem 0 0;
    display: flex;
    flex-direction: column;
    flex-grow: 2; }
    .profile-container .profile-attribute-container .profile-attributes-title {
      font-weight: bold;
      font-size: 1.12rem;
      color: #000; }
    .profile-container .profile-attribute-container .profile-attribute {
      padding: 15px 0;
      border-top: 1px solid #d0d0d0;
      border-bottom: 1px solid #d0d0d0;
      color: #6c6c6c; }
      .profile-container .profile-attribute-container .profile-attribute .col {
        padding: 0; }
      .profile-container .profile-attribute-container .profile-attribute .title {
        padding-bottom: 15px;
        font-size: 1rem; }
      .profile-container .profile-attribute-container .profile-attribute .attribute-value {
        font-size: 1.12rem; }
      .profile-container .profile-attribute-container .profile-attribute form {
        width: 100%; }
        .profile-container .profile-attribute-container .profile-attribute form .editable-row {
          display: flex;
          justify-content: space-between; }
    .profile-container .profile-attribute-container .edit-button {
      padding: 0;
      color: #000;
      background: none;
      box-shadow: none;
      border: none;
      font-size: 1.12rem;
      font-weight: 500;
      text-align: right;
      float: right; }
    .profile-container .profile-attribute-container input {
      width: 428px;
      padding: 15px;
      border-radius: 4px;
      border: solid 1px #72757e; }
    .profile-container .profile-attribute-container button:not(.edit-button) {
      padding: 16px 30px;
      border-radius: 4px;
      background-color: #0945a5;
      font-size: 18px;
      font-weight: 500;
      border: none; }
  .profile-container .integrations-attributes-container {
    padding: 3rem 0 0;
    display: flex;
    flex-direction: column;
    flex-grow: 2; }
    .profile-container .integrations-attributes-container .user-name {
      font-size: 3em;
      margin-bottom: 30.5px; }
    .profile-container .integrations-attributes-container .integrations-attributes {
      color: #6c6c6c;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start; }
      .profile-container .integrations-attributes-container .integrations-attributes .row:not(:last-child) {
        margin-bottom: 5px; }
        .profile-container .integrations-attributes-container .integrations-attributes .row:not(:last-child) .value {
          margin-bottom: 20px; }
      .profile-container .integrations-attributes-container .integrations-attributes .row .value {
        color: #000;
        font-weight: 500; }
  .profile-container .policy-container {
    padding: 3rem 1.5rem 0;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    color: #6c6c6c; }
    .profile-container .policy-container h3 {
      color: #000;
      font-size: 1.2rem; }
    .profile-container .policy-container div {
      margin-bottom: 0.8rem; }
  .profile-container .memberships-container {
    padding: 50px 0 0; }
    .profile-container .memberships-container h3 {
      font-size: 1.12rem;
      font-weight: bold;
      padding-bottom: 10px;
      border-bottom: solid 1px #d0d0d0; }
    .profile-container .memberships-container p {
      font-size: 1rem;
      color: #6c6c6c; }
    .profile-container .memberships-container a {
      font-weight: 500; }
  @media (max-width: 1200px) {
    .profile-container > .container {
      display: none; }
    .profile-container .profile-page-title {
      margin-top: 0; }
    .profile-container .tab-pane > .container {
      padding: 0 92px; }
    .profile-container .profile-attributes-container {
      padding-bottom: 0; }
      .profile-container .profile-attributes-container .profile-attributes {
        padding: 15px 0; }
    .profile-container .integrations-attributes-container {
      padding-bottom: 0; }
      .profile-container .integrations-attributes-container .integrations-attributes {
        padding: 15px 0; }
    .profile-container .integrations-attributes-container {
      padding-bottom: 0; }
      .profile-container .integrations-attributes-container .integrations-attributes {
        padding: 15px 0; }
    .profile-container .policy-container {
      padding: 3rem 0 0; }
      .profile-container .policy-container .policy-body {
        margin-bottom: 0; } }
  @media (max-width: 760px) {
    .profile-container .profile-attribute input {
      width: 100%; }
    .profile-container .profile-page-title .nav-tabs .nav-link {
      font-size: 0.97rem; }
    .profile-container .integrations-attributes-container {
      padding-top: 1rem; } }
  @media (max-width: 636px) {
    .profile-container > .container {
      padding: 0; }
    .profile-container .tab-pane > .container {
      padding: 0 16px; }
    .profile-container .profile-attributes-container, .profile-container .policy-container {
      padding: 3rem 0 0; }
    .profile-container .profile-attribute-container {
      padding: 30px 0; }
      .profile-container .profile-attribute-container .profile-attribute h2, .profile-container .profile-attribute-container .profile-attribute .attribute-value {
        font-size: 0.93rem;
        color: #000; }
      .profile-container .profile-attribute-container .profile-attribute .row {
        margin-bottom: 0 !important; }
      .profile-container .profile-attribute-container .profile-attribute .title {
        padding-bottom: 5px; }
      .profile-container .profile-attribute-container .profile-attribute .title, .profile-container .profile-attribute-container .profile-attribute button {
        font-size: 0.75rem; }
        .profile-container .profile-attribute-container .profile-attribute .title.edit-button, .profile-container .profile-attribute-container .profile-attribute button.edit-button {
          color: #6c6c6c; }
      .profile-container .profile-attribute-container .profile-attribute input {
        width: 201px;
        padding: 8px;
        font-size: 0.75rem; }
      .profile-container .profile-attribute-container button:not(.edit-button) {
        padding: 9px 15px;
        min-width: inherit; } }

.btn-action-round {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  border: 1px solid #000;
  align-items: center; }

.clickable-metric {
  background: none;
  border: none; }

.MuiButtonBase-root {
  min-height: 40px;
  font-family: inherit; }
  .MuiButtonBase-root.MuiButton-containedPrimary {
    background: #246ee3; }

@media (max-width: 636px) {
  .MuiButton-root {
    min-height: 48px; }
    .MuiButton-root.MuiButton-containedPrimary {
      background: #246ee3; }
    .MuiButton-root .MuiButton-label {
      font-size: 16px; } }

.task-actions {
  display: flex; }
  @media screen and (-ms-high-contrast: active) {
    .task-actions .goto-link svg path {
      fill: #fff; } }
  .task-actions .icon-action {
    height: 60px;
    width: 60px;
    border: solid 1px #000; }
    .task-actions .icon-action svg path {
      stroke-width: 1;
      stroke: #000; }
    .task-actions .icon-action.goto-link svg path {
      stroke: none; }
    .task-actions .icon-action:hover {
      border-color: #0945a5; }
  .task-actions .task-link {
    margin-left: 30px; }
  @media (max-width: 635.98px) {
    .task-actions {
      flex-direction: column;
      justify-content: space-between; }
      .task-actions .icon-action {
        height: 32px;
        width: 32px;
        display: flex; }
      .task-actions .task-link {
        margin-left: 0px; } }

.task-header {
  margin: 50px 0; }
  .task-header .task-actions-container {
    height: 100%; }
  @media (min-width: 636px) and (max-width: 1199.98px) {
    .task-header {
      margin: 30px 0 30px 0; } }
  @media (min-width: 320px) and (max-width: 635.98px) {
    .task-header {
      margin: 30px 0;
      display: flex; }
      .task-header .task-header-container {
        width: 100%; }
      .task-header .task-details-section {
        padding-left: 0; } }

.kebab-dropdown {
  border: none;
  position: relative;
  line-height: 0px; }
  .kebab-dropdown.btn-group {
    line-height: 0px;
    display: unset; }
  .kebab-dropdown .kebab-menu {
    position: absolute;
    right: 34px;
    top: 0px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 2px;
    min-width: 126px; }
    .kebab-dropdown .kebab-menu .dropdown-item, .kebab-dropdown .kebab-menu a {
      color: black;
      font-weight: 500;
      padding-top: 14px;
      padding-bottom: 14px; }
      .kebab-dropdown .kebab-menu .dropdown-item:hover, .kebab-dropdown .kebab-menu a:hover {
        color: #0945a5;
        background-color: unset; }
        .kebab-dropdown .kebab-menu .dropdown-item:hover a, .kebab-dropdown .kebab-menu a:hover a {
          color: #0945a5; }
    .kebab-dropdown .kebab-menu .dropdown-divider:last-child {
      display: none; }
  .kebab-dropdown .toggle-button {
    padding: 0;
    border: 0;
    background-color: #fff; }
    .kebab-dropdown .toggle-button svg {
      cursor: pointer;
      fill: #6c6c6c; }
    .kebab-dropdown .toggle-button:hover svg {
      fill: #0945a5; }
  .kebab-dropdown button.kebab-menu {
    border: none;
    padding: 0px; }
    .kebab-dropdown button.kebab-menu:hover {
      background-color: #fff; }
      .kebab-dropdown button.kebab-menu:hover .icon {
        fill: #0945a5; }

.tool-header, .workflow-header {
  width: 100%;
  height: 40px;
  position: relative; }
  .tool-header .back-btn, .workflow-header .back-btn {
    position: absolute;
    top: 30px;
    background: #fff;
    margin-top: 0 !important; }
    .tool-header .back-btn:hover, .workflow-header .back-btn:hover {
      text-decoration: none; }
  .tool-header .title-bar, .workflow-header .title-bar {
    display: none;
    position: absolute;
    bottom: 16px;
    z-index: 99999; }
    .tool-header .title-bar h2, .tool-header .title-bar .category, .workflow-header .title-bar h2, .workflow-header .title-bar .category {
      color: white;
      margin-bottom: 0;
      float: left; }
    .tool-header .title-bar .category, .workflow-header .title-bar .category {
      clear: left; }
  .tool-header .tile-gradient, .workflow-header .tile-gradient {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.75;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 9%, rgba(32, 32, 32, 0.5) 62%, #383838); }

.tool-top h2, .workflow-header h2, .workflow-top h2 {
  text-transform: uppercase;
  font-size: 2.25rem;
  color: #000;
  margin-bottom: 0; }

.tool-top .category, .workflow-header .category, .workflow-top .category {
  font-size: 1.5rem;
  color: #72757e; }

.tool-top, .workflow-top {
  padding: 36px 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  .tool-top .icons, .workflow-top .icons {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .tool-top .icons .icon-action, .workflow-top .icons .icon-action {
      border: solid 1px #000;
      margin-left: 40px;
      padding: 0; }
      @media screen and (-ms-high-contrast: active) {
        .tool-top .icons .icon-action svg path, .workflow-top .icons .icon-action svg path {
          fill: #fff; }
          .tool-top .icons .icon-action svg path:hover, .workflow-top .icons .icon-action svg path:hover {
            fill: #0945a5; } }
      .tool-top .icons .icon-action:hover, .workflow-top .icons .icon-action:hover {
        border-color: #0945a5; }
      .tool-top .icons .icon-action.icon-favorite svg path, .workflow-top .icons .icon-action.icon-favorite svg path {
        stroke: #000; }

.tool-content, .workflow-content {
  padding: 46px 0 0; }
  .tool-content .sidebar-header, .workflow-content .sidebar-header {
    margin-top: 0 !important;
    padding-bottom: 0 !important; }
  .tool-content .label, .workflow-content .label {
    font-size: 1.5rem;
    color: #000;
    font-weight: 500; }
  .tool-content p, .workflow-content p {
    margin: 7px 0 0;
    color: #000;
    font-size: 1.2rem; }

@media (max-width: 1200px) {
  .tool-header, .workflow-header {
    height: 180px;
    z-index: 99; }
  .tool-top, .workflow-top {
    padding: 30px 0; }
    .tool-top h2, .workflow-top h2 {
      font-size: 1.5rem; }
    .tool-top .category, .workflow-top .category {
      font-size: 1.3rem; }
    .tool-top .icon-action, .workflow-top .icon-action {
      width: 48px;
      height: 48px; }
  .workflow-top .icon-action {
    margin-left: 8% !important; }
    .workflow-top .icon-action:first-child {
      margin-left: 3px !important; } }

@media (max-width: 636px) {
  .tool-header, .workflow-header {
    height: 90px; }
    .tool-header .back-btn, .workflow-header .back-btn {
      display: none; }
  .tool-top h2, .tool-top .category, .workflow-header h2, .workflow-header .category {
    font-size: 0.9rem; }
  .workflow-header .title-bar {
    display: block; }
  .workflow-header .tile-gradient {
    display: block; }
  .tool-top, .workflow-top {
    padding: 16px 0; }
    .tool-top .icons .icon-action, .workflow-top .icons .icon-action {
      width: 32px;
      height: 32px;
      margin-left: 20px; }
      .tool-top .icons .icon-action.icon-counter, .workflow-top .icons .icon-action.icon-counter {
        font-size: 1rem; }
      .tool-top .icons .icon-action svg, .workflow-top .icons .icon-action svg {
        width: 14px;
        height: 14px; } }
      @media screen and (max-width: 636px) and (-ms-high-contrast: active) {
        .tool-top .icons .icon-action svg path, .workflow-top .icons .icon-action svg path {
          stroke: #fff; } }

@media (max-width: 636px) {
  .workflow-top .title-bar {
    display: none; }
  .workflow-top .icons {
    justify-content: space-between; }
    .workflow-top .icons .icon-action {
      margin-left: 0; }
  .tool-content, .workflow-content {
    padding: 10px 0; }
    .tool-content .label, .tool-content p, .workflow-content .label, .workflow-content p {
      font-size: 0.9rem; }
  .tool-content {
    padding: 30px 0; }
  .workflow-content {
    padding: 10px 0; } }

.icon-action {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #E7E8EA;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: none;
  fill: black;
  cursor: pointer; }
  .icon-action svg {
    width: 16px;
    height: 16px; }
    .icon-action svg path {
      stroke: none;
      fill: #8b8f97; }
  .icon-action.icon-favorite:not(.favorite) svg path {
    fill: none;
    stroke: #8b8f97; }
  .icon-action.icon-favorite:not(.favorite):hover svg path {
    fill: none !important;
    stroke: #0945a5 !important; }
  .icon-action:hover svg path {
    stroke: none !important;
    fill: #0945a5; }
  @media screen and (-ms-high-contrast: active) {
    .icon-action .clickable-metric svg path, .icon-action.goto-link svg path {
      stroke: #fff !important; }
    .icon-action .clickable-metric:hover svg path, .icon-action.goto-link:hover svg path {
      stroke: #0945a5 !important; } }
  .icon-action.icon-favorite:hover svg path, .icon-action.group-icon:hover svg path {
    stroke: none; }
  .icon-action.icon-counter {
    cursor: default;
    border: solid 1px #8b8f97;
    background-color: #fff;
    color: #8b8f97;
    font-size: 0.8rem;
    font-weight: normal; }
    @media screen and (-ms-high-contrast: active) {
      .icon-action.icon-counter {
        border: 1px solid !important; } }

.filter-tabs {
  margin: 45px 0;
  border-bottom: 1px solid #d0d0d0; }
  .filter-tabs .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
  .filter-tabs .filter-count {
    font-size: 1rem;
    color: #6c6c6c;
    align-items: center;
    padding-bottom: 15px; }
  .filter-tabs .filter-links {
    align-items: flex-end;
    display: flex;
    font-size: 1.1rem; }
    .filter-tabs .filter-links .task {
      padding: 0 !important;
      border-bottom: none; }
    .filter-tabs .filter-links a {
      color: #000;
      padding: 0 0 15px;
      margin-left: 30px;
      margin-bottom: -1px; }
      .filter-tabs .filter-links a:hover {
        border-bottom: 2px solid #72757e;
        padding-bottom: 13px; }
    .filter-tabs .filter-links .active a {
      border-bottom: 2px solid #0945a5 !important;
      font-weight: bold;
      padding-bottom: 13px;
      margin-left: 28px; }

@media (max-width: 1200px) {
  .filter-tabs .filter-count {
    padding-bottom: 9px; }
  .filter-tabs .filter-links a {
    padding-bottom: 9px; }
    .filter-tabs .filter-links a:hover {
      padding-bottom: 7px; }
  .filter-tabs .filter-links .active a {
    padding-bottom: 7px; } }

@media (max-width: 636px) {
  .filter-tabs {
    margin: 24px 0 10px; }
    .filter-tabs .container {
      align-items: flex-end; }
    .filter-tabs .filter-links {
      font-size: 0.8rem;
      justify-content: space-between;
      flex-grow: 1; }
      .filter-tabs .filter-links a {
        margin-left: 5px; }
      .filter-tabs .filter-links li.active:not(:first-child) a {
        margin-left: 3px; }
      .filter-tabs .filter-links .filter-tab:first-child a {
        margin-left: 0; }
  .filter-count {
    font-size: 0.75rem; } }

@media (max-width: 310px) {
  .filter-tabs {
    height: auto; }
    .filter-tabs a {
      height: auto !important;
      padding-bottom: 5px !important; } }

.burger-menu {
  position: relative; }
  .burger-menu .bm-burger-button {
    position: relative;
    margin: 5px 0 0 30px; }
    .burger-menu .bm-burger-button svg path {
      fill: #a4a4a4; }
    .burger-menu .bm-burger-button button {
      width: 24px !important;
      height: 24px !important; }
    .burger-menu .bm-burger-button .bm-icon.close-menu svg {
      stroke: #0945a5;
      width: 24px !important; }
  .burger-menu .bm-overlay {
    width: 100vw !important;
    height: 100vh !important;
    top: 160px;
    right: 0;
    background: rgba(75, 75, 75, 0.75) !important;
    z-index: 1100; }
  .burger-menu.open .bm-menu-wrap {
    transform: translate3d(0, 0px, 0px); }
  .burger-menu .bm-menu-wrap {
    background: white;
    top: 160px;
    height: calc(100vh - 75px) !important; }
    .burger-menu .bm-menu-wrap .bm-item-list > .menu-item:first-child {
      background: #4b4b4b;
      color: #fff; }
    .burger-menu .bm-menu-wrap .bm-item-list .menu-item {
      position: relative;
      background: white;
      border: none;
      text-align: left;
      width: 100%;
      padding: 20px;
      border-bottom: 1px solid #d0d0d0;
      color: black;
      font-size: 21px; }
      .burger-menu .bm-menu-wrap .bm-item-list .menu-item:hover {
        text-decoration: none; }
      .burger-menu .bm-menu-wrap .bm-item-list .menu-item.name {
        cursor: default; }
      .burger-menu .bm-menu-wrap .bm-item-list .menu-item svg {
        width: 16px;
        height: 16px;
        margin: 0 20px 3px 0 !important;
        fill: #6c6c6c; }
        .burger-menu .bm-menu-wrap .bm-item-list .menu-item svg g, .burger-menu .bm-menu-wrap .bm-item-list .menu-item svg path {
          stroke: #6c6c6c; }
      .burger-menu .bm-menu-wrap .bm-item-list .menu-item.magazine {
        padding-left: 17px; }
        .burger-menu .bm-menu-wrap .bm-item-list .menu-item.magazine svg {
          width: 22px;
          height: 16px; }
          .burger-menu .bm-menu-wrap .bm-item-list .menu-item.magazine svg path {
            stroke: none; }
      .burger-menu .bm-menu-wrap .bm-item-list .menu-item.admin {
        font-size: 18px; }
      .burger-menu .bm-menu-wrap .bm-item-list .menu-item.profile svg, .burger-menu .bm-menu-wrap .bm-item-list .menu-item.notifications svg {
        width: 19px;
        height: 19px; }
    .burger-menu .bm-menu-wrap .bm-item-list .logout-links .menu-item {
      color: #6c6c6c;
      font-size: 18px; }
  @media (max-width: 636px) {
    .burger-menu .bm-burger-button svg, .burger-menu .bm-burger-button button {
      margin-top: 2px;
      width: 20px !important;
      height: 20px !important; }
    .burger-menu .bm-burger-button .bm-icon.close-menu svg {
      margin-top: 2px;
      width: 18px !important; }
    .burger-menu .bm-overlay, .burger-menu .bm-menu-wrap {
      top: 85px; }
    .burger-menu .bm-menu-wrap {
      width: 80% !important;
      margin-bottom: 30px; }
      .burger-menu .bm-menu-wrap .bm-item-list .menu-item {
        padding: 13px;
        font-size: 18px; }
        .burger-menu .bm-menu-wrap .bm-item-list .menu-item.magazine {
          padding-left: 8px; }
        .burger-menu .bm-menu-wrap .bm-item-list .menu-item.admin {
          font-size: 16px; }
      .burger-menu .bm-menu-wrap .bm-item-list .logout-links .menu-item {
        font-size: 16px; } }
  @media (max-width: 350px) {
    .burger-menu .bm-menu-wrap .bm-item-list .menu-item {
      padding: 11px; } }
  .burger-menu.over-menu .bm-overlay, .burger-menu.over-menu .bm-menu-wrap {
    top: 0px;
    height: 100vh !important; }
    .burger-menu.over-menu .bm-overlay .bm-cross-button svg, .burger-menu.over-menu .bm-menu-wrap .bm-cross-button svg {
      stroke: white;
      width: 16px; }
  @media (min-width: 636px) {
    .burger-menu.over-menu .menu-item.name {
      height: 85px; }
    .burger-menu.over-menu .bm-menu-wrap .bm-cross-button {
      top: 28px !important;
      right: 20px !important; }
      .burger-menu.over-menu .bm-menu-wrap .bm-cross-button svg {
        width: 24px;
        height: 24px; } }

.folder-page {
  margin-top: 12px; }
  .folder-page .header {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: solid 1px #a4a4a4; }
    .folder-page .header .container h2 {
      font-size: 1rem;
      width: 100%;
      color: #6c6c6c;
      font-weight: normal;
      margin: 12px 0 0; }
    .folder-page .header .container h3 {
      display: inline-block;
      margin-top: 33px; }
    .folder-page .header .container > .container {
      display: none; }
    @media (max-width: 636px) {
      .folder-page .header > .container {
        padding: 0; }
        .folder-page .header > .container h3 {
          margin-top: 20px; }
        .folder-page .header > .container .filter-tabs {
          width: 100%; } }
  @media (max-width: 636px) {
    .folder-page > .container {
      padding: 0; } }
  .folder-page .folders-path {
    margin-top: 10px;
    text-transform: uppercase;
    color: #72757e;
    font-size: 1rem;
    font-weight: 500; }
    .folder-page .folders-path .current {
      color: black; }
    .folder-page .folders-path a {
      color: #72757e;
      text-decoration: none; }
    .folder-page .folders-path .signal {
      color: #0945a5;
      display: inline-block;
      margin: 0 5px 0 10px; }
  .folder-page .filter-tabs {
    float: right;
    border-bottom: none;
    margin: 33px 0 0; }
    .folder-page .filter-tabs .container {
      padding: 0; }
    .folder-page .filter-tabs .filter-count {
      display: none; }
  .folder-page .folders-content {
    margin-top: 30px; }

.image {
  position: relative;
  background-position-y: center; }
  .image .black-overlay-20 {
    background: black;
    opacity: 0.2;
    position: absolute;
    width: 100%;
    height: 100%; }

.static-page h1 {
  margin-bottom: 0; }

.static-page p {
  margin-top: 0.5rem; }
  .static-page p:last-child {
    margin-bottom: 0; }

.error-page {
  padding: 100px 170px 0 170px;
  text-align: left; }
  .error-page h1 {
    font-family: 'OpenSans';
    font-size: 2.25rem;
    text-align: left; }
  .error-page p {
    text-align: left;
    font-weight: 500;
    font-size: 1.5rem; }
  @media (max-width: 1200px) {
    .error-page {
      padding: 100px 152px 0 152px; } }
  @media (max-width: 636px) {
    .error-page {
      padding: 30px 15px 0; }
      .error-page h1 {
        font-size: 1.5rem; }
      .error-page p {
        font-size: 1.12rem; }
      .error-page a {
        font-size: 1.12rem;
        width: 100%;
        height: auto;
        padding: 13px 0 18px 0; } }

.layout-switch {
  background: #fff;
  position: fixed;
  right: 0;
  top: 100px;
  border: 1px solid #b2b2b2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 999;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1); }
  .layout-switch button {
    background: transparent !important;
    border: none;
    padding: 5px;
    outline: none !important;
    box-shadow: none !important; }
    .layout-switch button svg, .layout-switch button g {
      fill: #b2b2b2; }
      .layout-switch button svg:hover *, .layout-switch button g:hover * {
        fill: #0945a5; }
    .layout-switch button.active svg * {
      fill: #72757e; }

#bpmn-container, #bpmn-container-viewer {
  border: solid 1px #d0d0d0;
  border-radius: 10px;
  height: 500px;
  width: 100%;
  overflow: hidden; }
  #bpmn-container .djs-minimap, #bpmn-container-viewer .djs-minimap {
    border: none;
    box-shadow: none;
    border-radius: 6px;
    top: 14px;
    right: 19px; }
    #bpmn-container .djs-minimap .toggle, #bpmn-container-viewer .djs-minimap .toggle {
      text-indent: -9999px;
      width: 32px;
      height: 32px;
      box-shadow: none;
      border: none;
      background-position: cover; }
    #bpmn-container .djs-minimap:not(.open), #bpmn-container-viewer .djs-minimap:not(.open) {
      background: none;
      top: 15px;
      right: 20px; }
      #bpmn-container .djs-minimap:not(.open) .toggle, #bpmn-container-viewer .djs-minimap:not(.open) .toggle {
        background: url("../images/mini-map-default.png") no-repeat center;
        cursor: pointer; }
        #bpmn-container .djs-minimap:not(.open) .toggle:hover, #bpmn-container-viewer .djs-minimap:not(.open) .toggle:hover {
          background: url("../images/mini-map-default-hover.png") no-repeat center; }
    #bpmn-container .djs-minimap.open, #bpmn-container-viewer .djs-minimap.open {
      border: solid 1px #d0d0d0;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
      #bpmn-container .djs-minimap.open .toggle, #bpmn-container-viewer .djs-minimap.open .toggle {
        background: url("../images/mini-map-default-hover.png") no-repeat center; }
    #bpmn-container .djs-minimap .viewport-dom, #bpmn-container-viewer .djs-minimap .viewport-dom {
      border-radius: 6px;
      border: solid 1px #246ee3;
      background: rgba(36, 110, 227, 0.3); }

.chart-editor-page {
  margin-bottom: -60px;
  border-bottom: solid 4px #fff; }
  .chart-editor-page .header {
    z-index: 999;
    width: 100%;
    position: fixed;
    padding: 18px 0;
    background-color: #f8f8f8;
    border-bottom: solid 4px #fff; }
    .chart-editor-page .header > .container {
      display: flex;
      justify-content: flex-end; }
    .chart-editor-page .header h2 {
      font-size: 1rem;
      text-transform: uppercase;
      margin: 0;
      flex-grow: 1;
      color: #8b8f97;
      font-weight: 600;
      margin-top: 7px; }
    .chart-editor-page .header .btn {
      padding: 7px 20px;
      font-size: 0.87rem;
      text-transform: uppercase;
      color: #fff;
      font-weight: 600;
      margin-left: 10px; }
  .chart-editor-page #bpmn-container, .chart-editor-page #bpmn-container-viewer {
    position: absolute;
    height: calc(100% - 113px);
    border: 0;
    border-radius: 0;
    background: #f8f8f8;
    border-bottom: solid 4px #fff; }
    .chart-editor-page #bpmn-container #propview, .chart-editor-page #bpmn-container-viewer #propview {
      width: 333px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      overflow: auto;
      width: 25%;
      float: right;
      height: 100%;
      background-color: #fff; }
      .chart-editor-page #bpmn-container #propview .main-underline-tabs, .chart-editor-page #bpmn-container-viewer #propview .main-underline-tabs {
        background-color: rgba(248, 248, 248, 0.5); }
        .chart-editor-page #bpmn-container #propview .main-underline-tabs .nav-link, .chart-editor-page #bpmn-container-viewer #propview .main-underline-tabs .nav-link {
          text-transform: uppercase;
          font-size: 1rem;
          font-weight: normal;
          padding-bottom: 15px;
          color: #8b8f97;
          text-shadow: none; }
          .chart-editor-page #bpmn-container #propview .main-underline-tabs .nav-link:hover, .chart-editor-page #bpmn-container #propview .main-underline-tabs .nav-link.active, .chart-editor-page #bpmn-container-viewer #propview .main-underline-tabs .nav-link:hover, .chart-editor-page #bpmn-container-viewer #propview .main-underline-tabs .nav-link.active {
            border-bottom: 2px solid #246ee3; }
          .chart-editor-page #bpmn-container #propview .main-underline-tabs .nav-link.active, .chart-editor-page #bpmn-container-viewer #propview .main-underline-tabs .nav-link.active {
            color: #246ee3;
            font-weight: 600; }
      .chart-editor-page #bpmn-container #propview .nav-tabs, .chart-editor-page #bpmn-container-viewer #propview .nav-tabs {
        padding: 12px 0 0 30px; }
        .chart-editor-page #bpmn-container #propview .nav-tabs .nav-link.active, .chart-editor-page #bpmn-container-viewer #propview .nav-tabs .nav-link.active {
          background-color: transparent; }
    .chart-editor-page #bpmn-container #bpmnview, .chart-editor-page #bpmn-container-viewer #bpmnview {
      height: 100%;
      float: left;
      width: 75%; }
    .chart-editor-page #bpmn-container .djs-container, .chart-editor-page #bpmn-container-viewer .djs-container {
      background-color: white;
      border-right: solid 4px #f8f8f8; }
    .chart-editor-page #bpmn-container .djs-palette, .chart-editor-page #bpmn-container-viewer .djs-palette {
      top: 0;
      left: 0;
      border: none;
      border-right: solid 4px #fff;
      width: 116px;
      padding-left: 5px;
      padding-bottom: 10px;
      overflow: auto;
      height: 100%;
      background: #f8f8f8; }
      .chart-editor-page #bpmn-container .djs-palette .separator, .chart-editor-page #bpmn-container-viewer .djs-palette .separator {
        padding-top: 23px;
        margin: 15px -4px;
        border-bottom: solid 4px white; }
      .chart-editor-page #bpmn-container .djs-palette .entry, .chart-editor-page #bpmn-container-viewer .djs-palette .entry {
        color: #72757e;
        cursor: pointer;
        height: 40px; }
        .chart-editor-page #bpmn-container .djs-palette .entry:before, .chart-editor-page #bpmn-container-viewer .djs-palette .entry:before {
          font-size: 30px; }
        .chart-editor-page #bpmn-container .djs-palette .entry:hover, .chart-editor-page #bpmn-container-viewer .djs-palette .entry:hover {
          color: #0945a5; }
    .chart-editor-page #bpmn-container .bpp-properties-panel, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel {
      background: #fff;
      min-height: 100%; }
      .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-header .label, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-header .label {
        display: none; }
      .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tab-bar, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tab-bar {
        padding: 0; }
        .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tab-bar .scroll-tabs-button, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tab-bar .scroll-tabs-button {
          display: none; }
        .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tab-bar .bpp-properties-tabs-links, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tab-bar .bpp-properties-tabs-links {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 0 16px; }
          .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tab-bar .bpp-properties-tabs-links li a, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tab-bar .bpp-properties-tabs-links li a {
            text-transform: uppercase;
            background: none;
            margin: 0 5px;
            padding: 10px 0;
            border: none;
            cursor: pointer;
            color: #8b8f97;
            font-size: 0.87rem;
            font-weight: 600; }
            .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tab-bar .bpp-properties-tabs-links li a:hover, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tab-bar .bpp-properties-tabs-links li a:hover {
              color: #0945a5; }
          .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tab-bar .bpp-properties-tabs-links li.bpp-active a, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tab-bar .bpp-properties-tabs-links li.bpp-active a {
            border-bottom: solid 2px #246ee3; }
      .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container {
        color: #8b8f97; }
        .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container input, .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container label, .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container .group-label, .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container select, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container input, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container label, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container .group-label, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container select {
          color: #8b8f97; }
        .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container .group-label, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container .group-label {
          font-size: 1.12rem;
          font-weight: 600;
          margin-bottom: 12px; }
        .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container label, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container label {
          font-size: 1rem;
          font-weight: normal; }
        .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container .bpp-field-description, .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container .bpp-error-message, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container .bpp-field-description, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container .bpp-error-message {
          font-size: 0.87rem; }
        .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container .bpp-checkbox input, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container .bpp-checkbox input {
          margin-right: 10px; }
        .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container [contenteditable=true], .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container select, .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container input, .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container textarea, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container [contenteditable=true], .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container select, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container input, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container textarea {
          padding: 16px 10px;
          margin: 12px 0;
          border-radius: 4px; }
        .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container .bpp-field-wrapper .clear, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container .bpp-field-wrapper .clear {
          display: none; }
        .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container .bpp-properties-group, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container .bpp-properties-group {
          margin: 0 16px;
          padding: 30px 0; }
          .chart-editor-page #bpmn-container .bpp-properties-panel .bpp-properties-tabs-container .bpp-properties-group:hover .group-toggle, .chart-editor-page #bpmn-container-viewer .bpp-properties-panel .bpp-properties-tabs-container .bpp-properties-group:hover .group-toggle {
            display: none; }

/* activity */
g[data-element-id^=Task] > g > text,
g[data-element-id^=Activity] > g > text {
  fill: #EDF0FF !important; }

g[data-element-id^=Task] > g > path,
g[data-element-id^=Activity] > g > path {
  display: none; }

g[data-element-id^=Task] > g > rect,
g[data-element-id^=Activity] > g > rect {
  stroke: white !important;
  stroke-width: 1px !important;
  border-radius: 3px;
  fill: #0945A5 !important; }

.icon-red {
  width: 10px;
  height: 10px;
  background: red !important; }

/* start-event */
g[data-element-id^=StartEvent] > g > circle {
  stroke: #6c798d !important;
  fill: #dfdfdf !important;
  stroke-width: 4px !important; }

/* end-event */
g[data-element-id^=Event] > g > circle {
  stroke: #6c798d !important;
  fill: #dfdfdf !important;
  stroke-width: 4px !important; }

g[data-element-id^=EndEvent] > g > circle {
  stroke: #6c798d !important;
  fill: #dfdfdf !important;
  stroke-width: 8px !important; }

/* lane */
g[data-element-id^=Lane] > g > rect {
  stroke: white !important;
  stroke-width: 8px !important;
  fill: #f8f8f8 !important; }

g[data-element-id^=Participant] > g > rect {
  stroke: white !important;
  stroke-width: 8px !important;
  fill: #dfdfdf !important; }

g[data-element-id^=Gateway] > g > path {
  fill: #6c798d !important;
  stroke: #6c798d !important; }

g[data-element-id^=Gateway] > g > polygon {
  fill: transparent !important;
  stroke: #6c798d !important; }

.main-templates-container,
.main-workflows-container,
.header-groups-container {
  margin-bottom: 40px; }
  .main-templates-container h2,
  .main-workflows-container h2,
  .header-groups-container h2 {
    font-size: 0.87rem;
    text-transform: uppercase;
    color: #8b8f97;
    font-weight: 600;
    padding: 22px 0;
    border-bottom: 1px solid #E7E8EA;
    margin-bottom: 30px;
    background-color: #E7E8EA; }
    @media (max-width: 636px) {
      .main-templates-container h2,
      .main-workflows-container h2,
      .header-groups-container h2 {
        margin-bottom: 10px; } }
  .main-templates-container .container-header,
  .main-workflows-container .container-header,
  .header-groups-container .container-header {
    width: 100%;
    border-bottom: solid 0.5px #d0d0d0;
    margin-bottom: 20px;
    color: #8b8f97;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0; }
    .main-templates-container .container-header h3,
    .main-workflows-container .container-header h3,
    .header-groups-container .container-header h3 {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 600;
      display: inline-block;
      margin: 0 10px 0 0; }
    .main-templates-container .container-header .description,
    .main-workflows-container .container-header .description,
    .header-groups-container .container-header .description {
      font-size: 0.9rem; }
    .main-templates-container .container-header .btn,
    .main-workflows-container .container-header .btn,
    .header-groups-container .container-header .btn {
      margin-left: 30px; }
    @media (max-width: 636px) {
      .main-templates-container .container-header,
      .main-workflows-container .container-header,
      .header-groups-container .container-header {
        flex-direction: column; }
        .main-templates-container .container-header h3,
        .main-workflows-container .container-header h3,
        .header-groups-container .container-header h3 {
          width: 100%;
          margin: 0 0 10px 0; }
        .main-templates-container .container-header .btn,
        .main-workflows-container .container-header .btn,
        .header-groups-container .container-header .btn {
          margin: 20px 0 10px 0; } }
  .main-templates-container .empty-state,
  .main-workflows-container .empty-state,
  .header-groups-container .empty-state {
    margin-top: 30px; }
    .main-templates-container .empty-state .title,
    .main-workflows-container .empty-state .title,
    .header-groups-container .empty-state .title {
      font-size: 0.9rem; }

.dashboard-row {
  margin: 0; }

.workflows-container.listView .main-templates-container > .container, .workflows-container.listView .main-workflows-container > .container {
  max-width: 100%;
  padding: 0;
  margin: 0;
  width: 100%;
  border-bottom: solid 0.5px #d0d0d0; }
  .workflows-container.listView .main-templates-container > .container .container-header, .workflows-container.listView .main-workflows-container > .container .container-header {
    max-width: 1200px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding: 10px 15px;
    border: 0;
    margin-bottom: 0; }

.workflow-detail-page .config-tab {
  width: 100%;
  background: #f8f8f8;
  color: #8b8f97;
  padding: 20px 0; }
  .workflow-detail-page .config-tab > .container {
    display: flex;
    justify-content: flex-end; }
  .workflow-detail-page .config-tab a {
    color: #8b8f97;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600; }
    .workflow-detail-page .config-tab a .title {
      vertical-align: middle; }
    .workflow-detail-page .config-tab a svg {
      fill: #8b8f97;
      margin-right: 10px;
      width: 20px;
      height: 20px; }
    .workflow-detail-page .config-tab a:hover {
      color: #0945a5;
      text-decoration: none; }
      .workflow-detail-page .config-tab a:hover svg {
        fill: #0945a5; }

.workflow-edit-page {
  height: 100%;
  padding-top: 118px; }
  .workflow-edit-page .nav-tabs {
    padding-top: 20px; }
    .workflow-edit-page .nav-tabs #nav-link-:hover {
      border-color: white; }
  .workflow-edit-page .tabs-header {
    z-index: 999;
    position: fixed;
    top: 70px;
    width: 100%;
    background: #8b8f97;
    color: #fff;
    padding: 0;
    border-bottom: solid 1px #fff; }
    .workflow-edit-page .tabs-header > .container {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .workflow-edit-page .tabs-header .main-underline-tabs {
      background: none; }
      .workflow-edit-page .tabs-header .main-underline-tabs .nav-link {
        background: none;
        font-size: 1rem;
        color: #fff;
        padding: 0 0 15px 0;
        text-transform: uppercase; }
        .workflow-edit-page .tabs-header .main-underline-tabs .nav-link.active {
          font-weight: 600;
          text-shadow: none;
          border-color: #fff; }
    .workflow-edit-page .tabs-header .close-editor {
      color: #fff;
      background-color: unset;
      font-weight: 600;
      padding: 7px 20px;
      font-size: 0.87rem;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin-left: 30px; }
      .workflow-edit-page .tabs-header .close-editor svg {
        width: 18px;
        fill: #fff;
        margin-right: 8px; }
      .workflow-edit-page .tabs-header .close-editor:hover {
        background-color: #B9BCC0;
        text-decoration: none; }
  .workflow-edit-page .tab-content.steps-content .tab-pane {
    height: 100%; }
  .workflow-edit-page .tab-content .MuiGrid-container {
    margin: 34px 0; }
    .workflow-edit-page .tab-content .MuiGrid-container .MuiFormControl-root {
      width: 100%;
      margin: 14px 0; }
      .workflow-edit-page .tab-content .MuiGrid-container .MuiFormControl-root .Mui-focused {
        border: 0.5px; }
      .workflow-edit-page .tab-content .MuiGrid-container .MuiFormControl-root .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #0945a5; }
      .workflow-edit-page .tab-content .MuiGrid-container .MuiFormControl-root input {
        font-size: 1rem;
        color: #24344e; }
      .workflow-edit-page .tab-content .MuiGrid-container .MuiFormControl-root .MuiInputBase-input {
        padding: 14px 14px; }
      .workflow-edit-page .tab-content .MuiGrid-container .MuiFormControl-root .MuiOutlinedInput-multiline {
        padding: 0; }

.workflow-edit .form-title {
  font-size: 12px;
  color: #0945a5;
  text-transform: uppercase; }

.workflow-edit .image-attributes {
  flex-grow: 1; }

.workflow-edit .workflow-image {
  border: solid 0.5px #8b8f97;
  border-radius: 10px; }

.workflow-edit .upload-image {
  margin: 14px 0;
  justify-content: stretch; }
  .workflow-edit .upload-image .file-name {
    flex-grow: 1;
    width: inherit !important; }
  .workflow-edit .upload-image .MuiFormControl-root {
    margin: 0 !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important; }
  .workflow-edit .upload-image .MuiOutlinedInput-root {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important; }
  .workflow-edit .upload-image button {
    padding: 0 10px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-left: 0px;
    text-transform: unset;
    font-size: 0.87rem;
    font-weight: 600;
    font-family: OpenSans;
    color: #246ee3; }

.workflow-edit .workflow-image {
  margin: 14px 0 14px 30px; }

.save-header {
  width: 100%;
  z-index: 998;
  padding: 11px 0;
  position: fixed;
  top: 128px;
  background-color: #f8f8f8;
  border-bottom: solid 4px #fff;
  height: 60px; }
  .save-header > .container {
    display: flex;
    justify-content: flex-end; }
  .save-header h2 {
    font-size: 1rem;
    text-transform: uppercase;
    margin: 0;
    flex-grow: 1;
    color: #8b8f97;
    font-weight: 600;
    margin-top: 7px; }
  .save-header .btn {
    padding: 7px 20px;
    font-size: 0.87rem;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    margin-left: 10px; }

.save-modal-body {
  background-color: white;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
  padding: 60px;
  border-radius: 10px;
  min-width: 580px; }
  .save-modal-body .header {
    margin-bottom: 30px; }
    .save-modal-body .header #title {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
      color: #0945a5; }
    .save-modal-body .header #description {
      font-size: 16px;
      color: #8b8f97;
      margin-bottom: 56px; }
  .save-modal-body .content {
    width: 100%;
    margin: 10px 0; }
    .save-modal-body .content .MuiFormControl-root {
      width: 100%; }
    .save-modal-body .content input {
      font-size: 0.87rem !important; }
  .save-modal-body .actions {
    margin-top: 60px;
    padding-top: 10px;
    border-top: solid 0.5px #8b8f97; }
    .save-modal-body .actions .discard-button {
      background-color: unset;
      padding: 0;
      border: none;
      font-size: 14px;
      font-weight: 600;
      color: #246ee3;
      text-transform: uppercase; }
      .save-modal-body .actions .discard-button:hover {
        color: #0945a5; }
    .save-modal-body .actions .save-button {
      padding: 7px 20px;
      font-size: 0.87rem;
      text-transform: uppercase;
      color: #fff;
      font-weight: 600; }

.steps-page {
  display: flex;
  height: 100%; }
  .steps-page .steps-tabs {
    background-color: rgba(248, 248, 248, 0.5);
    min-width: 320px;
    font-size: 0.87rem; }
    .steps-page .steps-tabs h3 {
      text-transform: uppercase;
      font-size: 0.87rem;
      margin: 30px 0;
      margin-left: 60px;
      margin-right: 30px;
      border-bottom: 1px solid #d0d0d0;
      padding-bottom: 3px;
      color: #0945a5;
      font-weight: 600; }
    .steps-page .steps-tabs .MuiTabs-vertical .MuiTabs-indicator {
      display: none; }
    .steps-page .steps-tabs .MuiTabs-vertical .MuiTab-root {
      text-transform: none;
      max-width: 100%;
      font-family: OpenSans;
      text-align: left;
      padding-left: 60px; }
      .steps-page .steps-tabs .MuiTabs-vertical .MuiTab-root .MuiTab-wrapper {
        align-items: flex-start; }
      .steps-page .steps-tabs .MuiTabs-vertical .MuiTab-root:hover {
        color: #0945a5; }
      .steps-page .steps-tabs .MuiTabs-vertical .MuiTab-root.Mui-selected {
        background: #E7E8EA;
        color: #0945a5; }
  .steps-page .steps-tab-content {
    flex-grow: 1;
    padding: 30px; }
    .steps-page .steps-tab-content h2 {
      font-size: 0.87rem;
      color: #0945a5;
      text-transform: uppercase;
      border-bottom: 1px solid #d0d0d0;
      padding-bottom: 3px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between; }
      .steps-page .steps-tab-content h2 button {
        background: none;
        border: none;
        display: flex;
        align-items: flex-end;
        font-size: 1rem;
        color: #246ee3; }
        .steps-page .steps-tab-content h2 button span {
          display: inline-block;
          margin-left: 6px; }
        .steps-page .steps-tab-content h2 button svg {
          fill: #246ee3; }
        .steps-page .steps-tab-content h2 button:hover {
          color: #0945a5; }
          .steps-page .steps-tab-content h2 button:hover svg {
            fill: #0945a5; }
    .steps-page .steps-tab-content .step-fields {
      padding-right: 30px; }
      .steps-page .steps-tab-content .step-fields .MuiFormControl-root:first-child {
        margin-top: 0; }
    .steps-page .steps-tab-content .step-config {
      background: #f8f8f8;
      padding: 30px;
      height: fit-content; }

.integration-item {
  width: 276px;
  height: 129px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-right: 30px;
  border-radius: 15px 15px 0px 15px; }
  .integration-item .integration-header {
    width: 100%;
    height: 90px;
    background: #f8f8f8;
    border-radius: 15px 15px 0px 0px; }
    .integration-item .integration-header.row {
      margin-bottom: 0px !important; }
    .integration-item .integration-header_logo {
      padding: 10px 20px 0 20px; }
      .integration-item .integration-header_logo-container {
        padding: 5px;
        width: 70px;
        height: 70px;
        border-radius: 40px;
        background: white; }
        .integration-item .integration-header_logo-container img {
          margin: 10px; }
          .integration-item .integration-header_logo-container img.installed {
            filter: grayscale(100%); }
    .integration-item .integration-header_title {
      padding: 24px 0 0 10px; }
      .integration-item .integration-header_title.support {
        margin: 0 10px 0 10px; }
    .integration-item .integration-header .title-main {
      font-size: 16px;
      font-weight: bold;
      color: #212f46;
      text-transform: uppercase; }
    .integration-item .integration-header .title-sub {
      font-size: 14px;
      font-weight: normal;
      color: #6c6c6c; }
  .integration-item .integration-content {
    height: 44px;
    line-height: 2;
    font-size: 14px;
    font-weight: bold; }
    .integration-item .integration-content-link {
      text-transform: uppercase;
      color: #246ee3;
      float: right;
      line-height: 44px;
      margin-right: 10px;
      padding: 0px;
      background: transparent;
      border: 0;
      text-transform: uppercase; }
      .integration-item .integration-content-link:hover {
        text-decoration: underline; }

@media (max-width: 635.98px) {
  .integration-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px; } }

.modal .modal-content {
  padding: 20px;
  border-radius: 10px; }
  .modal .modal-content .modal-header {
    border-bottom: 0 none; }
    .modal .modal-content .modal-header .modal-title {
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: normal;
      text-align: left;
      color: #0945a5;
      text-transform: uppercase; }
    .modal .modal-content .modal-header button.close {
      display: none; }
  .modal .modal-content .modal-body {
    font-size: 14px;
    padding: 0 1rem  0  1rem; }
    .modal .modal-content .modal-body hr {
      margin-top: 60px; }
  .modal .modal-content .modal-footer {
    border: 0 none;
    padding-top: 0;
    justify-content: space-between; }
    .modal .modal-content .modal-footer button {
      text-transform: uppercase;
      font-size: 14px; }
      .modal .modal-content .modal-footer button.cancel {
        float: 'left';
        background: 'transparent';
        color: #246ee3;
        padding: 0;
        border: 0 none; }

@media (max-width: 635.98px) {
  .modal .modal-dialog {
    margin: 70px 0.75rem;
    padding: 0; }
  .modal .modal-footer button {
    font-size: 14px; } }

.snack-task-icon {
  width: 18px;
  height: 20px;
  object-fit: contain;
  margin-right: 11px; }
  @media (max-width: 636px) {
    .snack-task-icon {
      display: none; } }

.row-message {
  margin-left: 2px; }
  .row-message .col {
    display: contents; }
  @media (max-width: 636px) {
    .row-message {
      margin-left: 0px; }
      .row-message .col {
        text-align: left;
        padding: 0px;
        display: block; } }

.workflow-name {
  margin-top: 2px;
  height: 19px;
  margin-left: 20.3px;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  margin-right: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px; }
  @media (max-width: 636px) {
    .workflow-name {
      margin-left: 0;
      margin-top: 0;
      margin-right: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 170px; } }

.data-content .steps-tab-content {
  color: #8b8f97;
  padding-right: 0; }
  .data-content .steps-tab-content h2 {
    margin-bottom: 30px; }
  .data-content .steps-tab-content .MuiGrid-container {
    margin: 0 !important; }
  .data-content .steps-tab-content .nav-tabs {
    position: fixed;
    margin-top: -97px;
    z-index: 999;
    background: transparent; }
    .data-content .steps-tab-content .nav-tabs .nav-link {
      color: #8b8f97;
      font-size: 1rem;
      padding-bottom: 14px; }
      .data-content .steps-tab-content .nav-tabs .nav-link.active, .data-content .steps-tab-content .nav-tabs .nav-link:hover {
        color: #8b8f97;
        background: transparent;
        border-bottom: 1px solid #8b8f97 !important; }
      .data-content .steps-tab-content .nav-tabs .nav-link.active {
        font-weight: 600;
        text-shadow: none; }
  .data-content .steps-tab-content .json-schema-preview {
    background-color: rgba(248, 248, 248, 0.5);
    padding: 30px;
    margin-top: -30px;
    transition: max-width 0.5s;
    overflow: hidden; }
    .data-content .steps-tab-content .json-schema-preview.hidden {
      max-width: 57px; }
      .data-content .steps-tab-content .json-schema-preview.hidden .contents {
        display: none; }
    .data-content .steps-tab-content .json-schema-preview h3 {
      color: #8b8f97;
      font-size: 0.87rem;
      font-weight: 600;
      text-transform: uppercase;
      border-bottom: 1px solid #d0d0d0;
      padding-bottom: 3px;
      margin-bottom: 27px; }
    .data-content .steps-tab-content .json-schema-preview .hide-show-icon {
      background: none;
      border: none;
      position: absolute;
      top: 130px;
      right: 15px; }
      .data-content .steps-tab-content .json-schema-preview .hide-show-icon:hover svg path {
        fill: #24344e; }
    .data-content .steps-tab-content .json-schema-preview input, .data-content .steps-tab-content .json-schema-preview select, .data-content .steps-tab-content .json-schema-preview textarea {
      background: transparent;
      border-radius: 4px;
      border: solid 1px #8b8f97;
      color: #8b8f97;
      font-size: 1rem; }
  .data-content .steps-tab-content .json-schema-editor {
    padding: 0 30px 30px 0;
    transition: max-width 0.5s; }
    .data-content .steps-tab-content .json-schema-editor.expanded {
      max-width: calc(100% - 60px);
      flex-basis: calc(100% - 60px); }
    .data-content .steps-tab-content .json-schema-editor .json-schema-react-editor .import-json-button {
      display: none; }
    .data-content .steps-tab-content .json-schema-editor .json-schema-react-editor .ant-row-flex {
      display: flex; }
  .data-content .steps-tab-content .monaco-editor-container {
    border-radius: 4px;
    border: solid 1px #a4a4a4;
    min-height: 635px;
    padding-bottom: 1px; }
    .data-content .steps-tab-content .monaco-editor-container .header {
      height: 48px;
      width: 100%;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 16px 10px;
      background-color: #f8f8f8;
      margin-bottom: 10px; }
      .data-content .steps-tab-content .monaco-editor-container .header h3 {
        font-size: 1rem;
        color: #a4a4a4; }
    .data-content .steps-tab-content .monaco-editor-container .react-monaco-editor-container .monaco-editor-background, .data-content .steps-tab-content .monaco-editor-container .react-monaco-editor-container .margin-view-overlays {
      background: #fff; }
    .data-content .steps-tab-content .monaco-editor-container .react-monaco-editor-container .monaco-mouse-cursor-text .mtk1 {
      color: #24344e; }
    .data-content .steps-tab-content .monaco-editor-container .react-monaco-editor-container .scroll-decoration {
      box-shadow: none; }
    .data-content .steps-tab-content .monaco-editor-container .react-monaco-editor-container .decorationsOverviewRuler {
      display: none; }
    .data-content .steps-tab-content .monaco-editor-container .react-monaco-editor-container .line-numbers {
      color: #0945a5; }

#propview .tab-content {
  background-color: white; }
  #propview .tab-content.steps-content {
    padding-top: 10px; }
  #propview .tab-content .description {
    font-size: 0.75rem;
    color: #8b8f97;
    width: 100%;
    margin-bottom: 20px; }
  #propview .tab-content .MuiAccordion-root {
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 0;
    margin-top: 0 !important;
    margin-bottom: 0;
    border-bottom: solid 1px #d0d0d0;
    font-size: 0.87rem;
    color: #8b8f97; }
    #propview .tab-content .MuiAccordion-root:before {
      display: none; }
    #propview .tab-content .MuiAccordion-root:hover > .MuiAccordionSummary-root .MuiAccordionSummary-content {
      color: #246ee3; }
    #propview .tab-content .MuiAccordion-root .MuiAccordionSummary-root {
      min-height: inherit; }
      #propview .tab-content .MuiAccordion-root .MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-content {
        color: #246ee3; }
    #propview .tab-content .MuiAccordion-root .MuiAccordionSummary-content {
      color: #0945a5;
      text-transform: uppercase;
      font-size: 0.87rem;
      font-weight: 600;
      margin: 20px 0; }
  #propview .tab-content .MuiAccordionSummary-root, #propview .tab-content .MuiAccordionDetails-root {
    padding: 0 30px; }
  #propview .tab-content .MuiAccordionDetails-root {
    flex-direction: column;
    margin-bottom: 20px; }
  #propview .tab-content .not-created {
    font-size: 0.87rem;
    color: #8b8f97;
    margin: 10px 0; }
  #propview .tab-content .step-list-accordion.MuiAccordion-root {
    border-bottom: none; }
    #propview .tab-content .step-list-accordion.MuiAccordion-root:hover > .MuiAccordionSummary-root .MuiAccordionSummary-content {
      color: #24344e;
      font-weight: 600; }
  #propview .tab-content .step-list-accordion .MuiAccordionSummary-root, #propview .tab-content .step-list-accordion .MuiAccordionDetails-root {
    padding: 0; }
  #propview .tab-content .step-list-accordion .MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-content {
    color: #24344e;
    font-weight: 600; }
  #propview .tab-content .step-list-accordion .MuiAccordionDetails-root {
    margin-bottom: 0;
    color: #a4a4a4;
    font-size: 0.87rem; }
  #propview .tab-content .step-list-accordion .MuiAccordionSummary-content {
    color: #24344e;
    font-size: 0.87rem;
    font-weight: normal;
    margin: 10px 0;
    text-transform: none; }

/**
 * color definitions
 */
.djs-container {
  --blue-base-65: #4d90ff;
  --blue-base-65-opacity-30: rgba(77, 144, 255, 0.3);
  --blue-darken-48: #005df7;
  --blue-darken-55: #1a70ff;
  --blue-lighten-82: #a2c5ff;
  --orange-base-60: #ffa533;
  --orange-base-60-opacity-30: rgba(255, 165, 51, 0.3);
  --orange-base-60-opacity-50: rgba(255, 165, 51, 0.5);
  --orange-lighten-85: #ffddb3;
  --red-base-62: #ff3d3d;
  --red-base-62-opacity-10: rgba(255, 61, 61, 0.1);
  --silver-darken-94: #efefef;
  --color-000000: #000000;
  --color-000000-opacity-05: rgba(0, 0, 0, 0.05);
  --color-000000-opacity-10: rgba(0, 0, 0, 0.1);
  --color-333333: #333333;
  --color-666666: #666666;
  --color-aaaaaa: #aaaaaa;
  --color-cccccc: #cccccc;
  --color-cdcdcd: #cdcdcd;
  --color-dddddd: #dddddd;
  --color-f6f6f6: #f6f6f6;
  --color-fafafa: #fafafa;
  --color-fefefe: #fefefe;
  --color-ffffff: #ffffff;
  --bendpoint-fill-color: var(--blue-base-65-opacity-30);
  --bendpoint-stroke-color: var(--blue-base-65);
  --context-pad-entry-background-color: var(--color-fefefe);
  --context-pad-entry-hover-background-color: var(--silver-darken-94);
  --element-dragger-color: var(--blue-base-65);
  --element-hover-outline-fill-color: var(--blue-darken-48);
  --element-selected-outline-stroke-color: var(--blue-base-65);
  --lasso-fill-color: var(--color-000000-opacity-05);
  --lasso-stroke-color: var(--color-000000);
  --palette-entry-color: var(--color-333333);
  --palette-entry-hover-color: var(--blue-darken-48);
  --palette-entry-selected-color: var(--blue-base-65);
  --palette-separator-color: var(--color-aaaaaa);
  --palette-toggle-hover-background-color: var(--color-666666);
  --palette-background-color: var(--color-fafafa);
  --palette-border-color: var(--color-cccccc);
  --popup-body-background-color: var(--color-fefefe);
  --popup-header-entry-selected-color: var(--blue-base-65);
  --popup-header-entry-selected-background-color: var(--color-000000-opacity-10);
  --popup-header-separator-color: var(--color-dddddd);
  --popup-background-color: var(--color-fafafa);
  --popup-border-color: var(--color-cccccc);
  --resizer-fill-color: var(--blue-base-65-opacity-30);
  --resizer-stroke-color: var(--blue-base-65);
  --search-container-background-color: var(--color-fafafa);
  --search-container-border-color: var(--blue-darken-55);
  --search-container-box-shadow-color: var(--blue-lighten-82);
  --search-container-box-shadow-inset-color: var(--color-cdcdcd);
  --search-input-border-color: var(--color-cccccc);
  --search-result-border-color: var(--color-aaaaaa);
  --search-result-highlight-color: var(--color-000000);
  --search-result-selected-color: var(--blue-base-65-opacity-30);
  --shape-attach-allowed-stroke-color: var(--blue-base-65);
  --shape-connect-allowed-fill-color: var(--color-000000-opacity-05);
  --shape-drop-allowed-fill-color: var(--color-000000-opacity-05);
  --shape-drop-not-allowed-fill-color: var(--red-base-62-opacity-10);
  --shape-resize-preview-stroke-color: var(--blue-base-65);
  --snap-line-stroke-color: var(--blue-base-65-opacity-30);
  --space-tool-crosshair-stroke-color: var(--color-000000);
  --tooltip-error-background-color: var(--red-base-62-opacity-10);
  --tooltip-error-border-color: var(--red-base-62);
  --tooltip-error-color: var(--red-base-62); }

/**
 * outline styles
 */
.djs-outline {
  fill: none;
  visibility: hidden; }

.djs-element.hover .djs-outline,
.djs-element.selected .djs-outline {
  visibility: visible;
  shape-rendering: geometricPrecision;
  stroke-dasharray: 3,3; }

.djs-element.selected .djs-outline {
  stroke: var(--element-selected-outline-stroke-color);
  stroke-width: 1px; }

.djs-element.hover .djs-outline {
  stroke: var(--element-hover-outline-fill-color);
  stroke-width: 1px; }

.djs-shape.connect-ok .djs-visual > :nth-child(1) {
  fill: var(--shape-connect-allowed-fill-color) !important; }

.djs-shape.connect-not-ok .djs-visual > :nth-child(1),
.djs-shape.drop-not-ok .djs-visual > :nth-child(1) {
  fill: var(--shape-drop-not-allowed-fill-color) !important; }

.djs-shape.new-parent .djs-visual > :nth-child(1) {
  fill: var(--shape-drop-allowed-fill-color) !important; }

svg.drop-not-ok {
  background: var(--shape-drop-not-allowed-fill-color) !important; }

svg.new-parent {
  background: var(--shape-drop-allowed-fill-color) !important; }

.djs-connection.connect-ok .djs-visual > :nth-child(1),
.djs-connection.drop-ok .djs-visual > :nth-child(1) {
  stroke: var(--shape-drop-allowed-fill-color) !important; }

.djs-connection.connect-not-ok .djs-visual > :nth-child(1),
.djs-connection.drop-not-ok .djs-visual > :nth-child(1) {
  stroke: var(--shape-drop-not-allowed-fill-color) !important; }

.drop-not-ok,
.connect-not-ok {
  stroke: var(--shape-attach-allowed-stroke-color) !important;
  cursor: not-allowed; }

.djs-element.attach-ok .djs-visual > :nth-child(1) {
  stroke-width: 5px !important; }

.djs-frame.connect-not-ok .djs-visual > :nth-child(1),
.djs-frame.drop-not-ok .djs-visual > :nth-child(1) {
  stroke-width: 3px !important;
  stroke: var(--shape-drop-not-allowed-fill-color) !important;
  fill: none !important; }

/**
* Selection box style
*
*/
.djs-lasso-overlay {
  fill: var(--lasso-fill-color);
  stroke-dasharray: 5 1 3 1;
  stroke: var(--lasso-stroke-color);
  shape-rendering: geometricPrecision;
  pointer-events: none; }

/**
 * Resize styles
 */
.djs-resize-overlay {
  fill: none;
  stroke-dasharray: 5 1 3 1;
  stroke: var(--shape-resize-preview-stroke-color);
  pointer-events: none; }

.djs-resizer-hit {
  fill: none;
  pointer-events: all; }

.djs-resizer-visual {
  fill: var(--resizer-fill-color);
  stroke-width: 1px;
  stroke-opacity: 0.5;
  stroke: var(--resizer-stroke-color);
  shape-rendering: geometricprecision; }

.djs-resizer:hover .djs-resizer-visual {
  stroke: var(--resizer-stroke-color);
  stroke-opacity: 1; }

.djs-cursor-resize-ns,
.djs-resizer-n,
.djs-resizer-s {
  cursor: ns-resize; }

.djs-cursor-resize-ew,
.djs-resizer-e,
.djs-resizer-w {
  cursor: ew-resize; }

.djs-cursor-resize-nwse,
.djs-resizer-nw,
.djs-resizer-se {
  cursor: nwse-resize; }

.djs-cursor-resize-nesw,
.djs-resizer-ne,
.djs-resizer-sw {
  cursor: nesw-resize; }

.djs-shape.djs-resizing > .djs-outline {
  visibility: hidden !important; }

.djs-shape.djs-resizing > .djs-resizer {
  visibility: hidden; }

.djs-dragger > .djs-resizer {
  visibility: hidden; }

/**
 * drag styles
 */
.djs-dragger * {
  fill: none !important;
  stroke: var(--element-dragger-color) !important; }

.djs-dragger tspan,
.djs-dragger text {
  fill: var(--element-dragger-color) !important;
  stroke: none !important; }

marker.djs-dragger circle,
marker.djs-dragger path,
marker.djs-dragger polygon,
marker.djs-dragger polyline,
marker.djs-dragger rect {
  fill: var(--element-dragger-color) !important;
  stroke: none !important; }

marker.djs-dragger text,
marker.djs-dragger tspan {
  fill: none !important;
  stroke: var(--element-dragger-color) !important; }

.djs-dragging {
  opacity: 0.3; }

.djs-dragging,
.djs-dragging > * {
  pointer-events: none !important; }

.djs-dragging .djs-context-pad,
.djs-dragging .djs-outline {
  display: none !important; }

/**
 * no pointer events for visual
 */
.djs-visual,
.djs-outline {
  pointer-events: none; }

.djs-element.attach-ok .djs-hit {
  stroke-width: 60px !important; }

/**
 * all pointer events for hit shape
 */
.djs-element > .djs-hit-all {
  pointer-events: all; }

.djs-element > .djs-hit-stroke,
.djs-element > .djs-hit-click-stroke {
  pointer-events: stroke; }

/**
 * all pointer events for hit shape
 */
.djs-drag-active .djs-element > .djs-hit-click-stroke {
  pointer-events: all; }

/**
 * shape / connection basic styles
 */
.djs-connection .djs-visual {
  stroke-width: 2px;
  fill: none; }

.djs-cursor-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab; }

.djs-cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing; }

.djs-cursor-crosshair {
  cursor: crosshair; }

.djs-cursor-move {
  cursor: move; }

.djs-cursor-resize-ns {
  cursor: ns-resize; }

.djs-cursor-resize-ew {
  cursor: ew-resize; }

/**
 * snapping
 */
.djs-snap-line {
  stroke: var(--snap-line-stroke-color);
  stroke-linecap: round;
  stroke-width: 2px;
  pointer-events: none; }

/**
 * snapping
 */
.djs-crosshair {
  stroke: var(--space-tool-crosshair-stroke-color);
  stroke-linecap: round;
  stroke-width: 1px;
  pointer-events: none;
  shape-rendering: crispEdges;
  stroke-dasharray: 5, 5; }

/**
 * palette
 */
.djs-palette {
  position: absolute;
  left: 20px;
  top: 20px;
  box-sizing: border-box;
  width: 48px; }

.djs-palette .separator {
  margin: 0 5px;
  padding-top: 5px;
  border: none;
  border-bottom: solid 1px var(--palette-separator-color);
  clear: both; }

.djs-palette .entry:before {
  vertical-align: text-bottom; }

.djs-palette .djs-palette-toggle {
  cursor: pointer; }

.djs-palette .entry,
.djs-palette .djs-palette-toggle {
  color: var(--palette-entry-color);
  font-size: 30px;
  text-align: center; }

.djs-palette .entry {
  float: left; }

.djs-palette .entry img {
  max-width: 100%; }

.djs-palette .djs-palette-entries:after {
  content: '';
  display: table;
  clear: both; }

.djs-palette .djs-palette-toggle:hover {
  background: var(--palette-toggle-hover-background-color); }

.djs-palette .entry:hover {
  color: var(--palette-entry-hover-color); }

.djs-palette .highlighted-entry {
  color: var(--palette-entry-selected-color) !important; }

.djs-palette .entry,
.djs-palette .djs-palette-toggle {
  width: 46px;
  height: 46px;
  line-height: 46px;
  cursor: default; }

/**
 * Palette open / two-column layout is controlled via
 * classes on the palette. Events to hook into palette
 * changed life-cycle are available in addition.
 */
.djs-palette.two-column.open {
  width: 94px; }

.djs-palette:not(.open) .djs-palette-entries {
  display: none; }

.djs-palette:not(.open) {
  overflow: hidden; }

.djs-palette.open .djs-palette-toggle {
  display: none; }

/**
 * context-pad
 */
.djs-overlay-context-pad {
  width: 72px; }

.djs-context-pad {
  position: absolute;
  display: none;
  pointer-events: none; }

.djs-context-pad .entry {
  width: 22px;
  height: 22px;
  text-align: center;
  display: inline-block;
  font-size: 22px;
  margin: 0 2px 2px 0;
  border-radius: 3px;
  cursor: default;
  background-color: var(--context-pad-entry-background-color);
  box-shadow: 0 0 2px 1px var(--context-pad-entry-background-color);
  pointer-events: all; }

.djs-context-pad .entry:before {
  vertical-align: top; }

.djs-context-pad .entry:hover {
  background: var(--context-pad-entry-hover-background-color); }

.djs-context-pad.open {
  background: white;
  display: block; }

.group {
  float: left; }
  .group .entry {
    margin: 0 5px; }
    .group .entry.bpmn-icon-data-object, .group .entry.bpmn-icon-data-store, .group .entry.bpmn-icon-group, .group .entry.bpmn-icon-task {
      display: none; }

/**
 * popup styles
 */
.djs-popup .entry {
  line-height: 20px;
  white-space: nowrap;
  cursor: default; }

/* larger font for prefixed icons */
.djs-popup .entry:before {
  vertical-align: middle;
  font-size: 20px; }

.djs-popup .entry > span {
  vertical-align: middle;
  font-size: 14px; }

.djs-popup .entry:hover,
.djs-popup .entry.active:hover {
  background: var(--popup-header-entry-selected-background-color); }

.djs-popup .entry.disabled {
  background: inherit; }

.djs-popup .djs-popup-header .entry {
  display: inline-block;
  padding: 2px 3px 2px 3px;
  border: solid 1px transparent;
  border-radius: 3px; }

.djs-popup .djs-popup-header .entry.active {
  color: var(--popup-header-entry-selected-color);
  border: solid 1px var(--popup-header-entry-selected-color);
  background-color: var(--popup-header-entry-selected-background-color); }

.djs-popup-body .entry {
  padding: 4px 10px 4px 5px; }

.djs-popup-body .entry > span {
  margin-left: 5px; }

.djs-popup-body {
  background-color: var(--popup-body-background-color); }

.djs-popup-header {
  border-bottom: 1px solid var(--popup-header-separator-color); }

.djs-popup-header .entry {
  margin: 1px;
  margin-left: 3px; }

.djs-popup-header .entry:last-child {
  margin-right: 3px; }

/**
 * popup / palette styles
 */
.djs-palette {
  background: var(--palette-background-color);
  border: solid 1px var(--palette-border-color);
  border-radius: 2px; }

.djs-popup {
  background: var(--popup-background-color);
  border: solid 1px var(--popup-border-color);
  border-radius: 2px; }

/**
 * touch
 */
.djs-shape,
.djs-connection {
  touch-action: none; }

.djs-segment-dragger,
.djs-bendpoint {
  display: none; }

/**
 * bendpoints
 */
.djs-segment-dragger .djs-visual {
  display: none;
  fill: var(--bendpoint-fill-color);
  stroke: var(--bendpoint-stroke-color);
  stroke-width: 1px;
  stroke-opacity: 1; }

.djs-segment-dragger:hover .djs-visual {
  display: block; }

.djs-bendpoint .djs-visual {
  fill: var(--bendpoint-fill-color);
  stroke: var(--bendpoint-stroke-color);
  stroke-width: 1px;
  stroke-opacity: 0.5; }

.djs-segment-dragger:hover,
.djs-bendpoints.hover .djs-segment-dragger,
.djs-bendpoints.selected .djs-segment-dragger,
.djs-bendpoint:hover,
.djs-bendpoints.hover .djs-bendpoint,
.djs-bendpoints.selected .djs-bendpoint {
  display: block; }

.djs-drag-active .djs-bendpoints * {
  display: none; }

.djs-bendpoints:not(.hover) .floating {
  display: none; }

.djs-segment-dragger:hover .djs-visual,
.djs-segment-dragger.djs-dragging .djs-visual,
.djs-bendpoint:hover .djs-visual,
.djs-bendpoint.floating .djs-visual {
  fill: var(--bendpoint-fill-color);
  stroke: var(--bendpoint-stroke-color);
  stroke-opacity: 1; }

.djs-bendpoint.floating .djs-hit {
  pointer-events: none; }

.djs-segment-dragger .djs-hit,
.djs-bendpoint .djs-hit {
  fill: none;
  pointer-events: all; }

.djs-segment-dragger.horizontal .djs-hit {
  cursor: ns-resize; }

.djs-segment-dragger.vertical .djs-hit {
  cursor: ew-resize; }

.djs-segment-dragger.djs-dragging .djs-hit {
  pointer-events: none; }

.djs-updating,
.djs-updating > * {
  pointer-events: none !important; }

.djs-updating .djs-context-pad,
.djs-updating .djs-outline,
.djs-updating .djs-bendpoint,
.connect-ok .djs-bendpoint,
.connect-not-ok .djs-bendpoint,
.drop-ok .djs-bendpoint,
.drop-not-ok .djs-bendpoint {
  display: none !important; }

.djs-segment-dragger.djs-dragging,
.djs-bendpoint.djs-dragging {
  display: block;
  opacity: 1.0; }

/**
 * tooltips
 */
.djs-tooltip-error {
  width: 160px;
  padding: 6px;
  background: var(--tooltip-error-background-color);
  border: solid 1px var(--tooltip-error-border-color);
  border-radius: 2px;
  color: var(--tooltip-error-color);
  font-size: 12px;
  line-height: 16px;
  opacity: 0.75; }

.djs-tooltip-error:hover {
  opacity: 1; }

/**
 * search pad
 */
.djs-search-container {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  min-width: 300px;
  max-width: 400px;
  z-index: 10;
  font-size: 1.05em;
  opacity: 0.9;
  background: var(--search-container-background-color);
  border: solid 1px var(--search-container-border-color);
  border-radius: 2px;
  box-shadow: 0 0 0 2px var(--search-container-box-shadow-color), 0 0 0 1px var(--search-container-box-shadow-inset-color) inset; }

.djs-search-container:not(.open) {
  display: none; }

.djs-search-input input {
  font-size: 1.05em;
  width: 100%;
  padding: 6px 10px;
  border: 1px solid var(--search-input-border-color); }

.djs-search-input input:focus {
  outline: none;
  border-color: var(--search-input-border-color); }

.djs-search-results {
  position: relative;
  overflow-y: auto;
  max-height: 200px; }

.djs-search-results:hover {
  cursor: pointer; }

.djs-search-result {
  width: 100%;
  padding: 6px 10px;
  background: white;
  border-bottom: solid 1px var(--search-result-border-color);
  border-radius: 1px; }

.djs-search-highlight {
  color: var(--search-result-highlight-color); }

.djs-search-result-primary {
  margin: 0 0 10px; }

.djs-search-result-secondary {
  font-family: monospace;
  margin: 0; }

.djs-search-result:hover {
  background: var(--search-result-selected-color); }

.djs-search-result-selected {
  background: var(--search-result-selected-color); }

.djs-search-result-selected:hover {
  background: var(--search-result-selected-color); }

.djs-search-overlay {
  background: var(--search-result-selected-color); }

/**
 * hidden styles
 */
.djs-element-hidden,
.djs-element-hidden .djs-hit,
.djs-element-hidden .djs-outline,
.djs-label-hidden .djs-label {
  display: none !important; }

.MuiDialog-paperFullWidth {
  padding: 40px; }

.MuiPaper-rounded {
  border-radius: 10px !important; }

.MuiDialogTitle-root {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #0945a5;
  text-transform: uppercase;
  padding-bottom: 0px !important; }
  .MuiDialogTitle-root h2 {
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    padding-bottom: 10px !important; }

.MuiDialogContent-root {
  padding: 0 24px; }
  .MuiDialogContent-root .MuiDialogTitle-root {
    padding: 16px 0 0px 0; }
    .MuiDialogContent-root .MuiDialogTitle-root h2 {
      margin-bottom: 14px; }

.dialog-actions-container {
  margin-top: 20px;
  border-top: 1px solid #ccc;
  width: 100%;
  padding: '0';
  padding-top: 20px;
  margin-bottom: 14px; }
  .dialog-actions-container .MuiButtonBase-root {
    border-radius: 24px; }

.MuiDialogActions-root {
  padding: 20px 0px !important;
  margin: 0 24px; }
  .MuiDialogActions-root .actions-container {
    border-top: 1px solid #ccc;
    width: 100%;
    padding: '0';
    padding-top: 20px; }

@media (max-width: 636px) {
  .MuiDialog-paperFullWidth {
    padding: 20px 0; }
  .MuiDialog-container {
    margin-top: 70px !important;
    padding-bottom: 70px !important; }
  .MuiPaper-rounded {
    border-radius: 0px !important; }
  .MuiBackdrop-root {
    opacity: 0 !important; } }

.header-groups-container {
  font-family: inherit; }
  .header-groups-container .container-header p {
    text-align: center;
    margin-bottom: 0;
    line-height: 42px; }
  .header-groups-container h2,
  .header-groups-container .description {
    font-size: 20px;
    font-weight: 600;
    color: #0945a5;
    text-transform: uppercase; }
  .header-groups-container h4 {
    font-size: 20px;
    font-weight: 600;
    color: #0945a5;
    text-transform: uppercase; }
  .header-groups-container .actionButtons {
    display: flex;
    justify-content: flex-end; }
  .header-groups-container .MuiButton-root {
    border-radius: 24px !important; }
  .header-groups-container .MuiIconButton-root {
    padding: 0 9px;
    margin-left: 30px; }
  .header-groups-container .MuiIconButton-colorSecondary {
    background: #246ee3;
    color: white; }
    .header-groups-container .MuiIconButton-colorSecondary svg {
      padding: 1px; }
    .header-groups-container .MuiIconButton-colorSecondary:hover {
      background: #1858bc; }

.MuiDataGrid-cellWithRenderer {
  flex-direction: row-reverse;
  align-items: end; }

.group_avatar {
  border-radius: 60px;
  padding: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  margin: 0;
  font-weight: 700;
  line-height: 30px;
  font-size: 70%; }
  .group_avatar.light {
    color: #444; }
  .group_avatar.dark {
    color: #eee; }

.MuiInputBase-root .group_avatar {
  width: 52px; }

.group-show {
  margin-bottom: 60px; }
  .group-show .MuiGrid-spacing-xs-3 {
    margin: 0; }
    .group-show .MuiGrid-spacing-xs-3 .MuiGrid-item {
      padding: 0; }
      .group-show .MuiGrid-spacing-xs-3 .MuiGrid-item:nth-child(2) {
        padding: 0 24px; }

.table-show-link {
  opacity: 1;
  color: #8b8f97; }
  .table-show-link svg {
    transform: scaleX(-1);
    background-color: #e3e4e5;
    border-radius: 32px;
    padding: 5px;
    color: #8b8f97;
    width: 30px;
    height: 30px; }

.MuiMenuItem-root {
  display: flex !important;
  justify-content: stretch; }

@media (max-width: 636px) {
  .header-groups-container {
    margin-top: 0px;
    margin-bottom: 30px; }
    .header-groups-container .container-header p {
      text-align: center;
      margin-bottom: 0;
      line-height: 42px; }
    .header-groups-container .container-header h4 {
      line-height: 2; }
    .header-groups-container .container-header .actionButtons {
      justify-content: center;
      margin: 30px 0; }
    .header-groups-container .MuiIconButton-root {
      margin-left: 4px; }
  .group_details_column {
    margin-bottom: 30px !important; }
  .table-show-link svg {
    transform: scaleX(-1);
    background-color: #f4f4f4;
    border-radius: 32px;
    padding: 6px;
    color: #8b8f97;
    width: 30px;
    height: 30px; }
  .group_avatar {
    width: 30px;
    height: 30px;
    line-height: 32px;
    font-size: 90%; }
  .group-show {
    margin-bottom: 0px; }
  .MuiInputBase-root .group_avatar {
    width: 36px; } }

.groups .MuiDataGrid-root {
  border-radius: 20px;
  font-family: inherit; }
  .groups .MuiDataGrid-root .MuiDataGrid-cell {
    padding-left: 56px; }
  .groups .MuiDataGrid-root .MuiDataGrid-mainGridContainer .MuiDataGrid-columnsContainer {
    border-bottom-width: 2px;
    padding: 0 40px; }
  .groups .MuiDataGrid-root .MuiDataGrid-mainGridContainer .MuiDataGrid-colCell {
    color: #8b8f97; }
    .groups .MuiDataGrid-root .MuiDataGrid-mainGridContainer .MuiDataGrid-colCell .MuiDataGrid-colCellTitle {
      font-weight: 700;
      font-size: 12px; }
  .groups .MuiDataGrid-root .MuiDataGrid-mainGridContainer .MuiDataGrid-columnSeparator {
    display: none; }

p.filterTitle {
  text-transform: uppercase;
  color: #8b8f97;
  font-weight: 800;
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 16px;
  margin-left: 0px; }

.group-show .filterTitle {
  margin-left: 15px; }

.MuiTableContainer-root {
  border-radius: 10px !important;
  border: 1px solid #ccc; }

.MuiTable-root {
  box-shadow: none; }
  .MuiTable-root .MuiTableCell-root {
    height: 62px;
    padding: 0; }
    .MuiTable-root .MuiTableCell-root.MuiTableCell-head, .MuiTable-root .MuiTableCell-root.MuiTableCell-body {
      padding: 0 15px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      color: #24344e; }
      .MuiTable-root .MuiTableCell-root.MuiTableCell-head .MuiButton-root, .MuiTable-root .MuiTableCell-root.MuiTableCell-body .MuiButton-root {
        min-width: 0; }
      .MuiTable-root .MuiTableCell-root.MuiTableCell-head.waiting_for_approval, .MuiTable-root .MuiTableCell-root.MuiTableCell-body.waiting_for_approval {
        color: #9f9f9f; }
    .MuiTable-root .MuiTableCell-root.avatarColumn {
      width: 40px;
      padding-left: 40px; }
    .MuiTable-root .MuiTableCell-root.actionColumn {
      padding-right: 40px; }

.MuiTableRow-head {
  border-bottom: 2px solid #ccc; }
  .MuiTableRow-head .MuiTableCell-head {
    font-weight: 700;
    font-size: 12px;
    color: #8b8f97; }

@media (max-width: 636px) {
  p.filterTitle.first_one {
    margin-top: 0; }
  .MuiTable-root {
    box-shadow: none; }
    .MuiTable-root .MuiTableCell-root.MuiTableCell-head, .MuiTable-root .MuiTableCell-root.MuiTableCell-body {
      padding: 0px 6px; } }

.MuiMenu-paper {
  font-family: inherit; }
  .MuiMenu-paper .MuiMenu-list {
    padding: 0; }
    .MuiMenu-paper .MuiMenu-list .MuiMenuItem-root {
      border-bottom: 1px solid #ccc; }
      .MuiMenu-paper .MuiMenu-list .MuiMenuItem-root.last {
        border-bottom: none; }

.MuiAppBar-colorPrimary {
  color: #246ee3 !important;
  background-color: #f8f8f8 !important;
  min-height: 60px;
  line-height: 60px;
  margin-bottom: 30px; }
  .MuiAppBar-colorPrimary .appbar-list {
    list-style: none;
    padding: 0;
    margin-bottom: 0; }
    .MuiAppBar-colorPrimary .appbar-list .appbar-item {
      float: left;
      border-bottom: 2px solid #0945a5; }
      .MuiAppBar-colorPrimary .appbar-list .appbar-item .appbar-link {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase; }

@media (max-width: 636px) {
  .MuiAppBar-colorPrimary {
    margin-bottom: 0; } }

.listView {
  margin: 0;
  padding: 0;
  max-width: 100%; }
  .listView > .row {
    padding: 0; }
  .listView .main-tabs-container {
    padding-top: 18px;
    background: #E7E8EA;
    border-bottom: none; }
    @media (max-width: 636px) {
      .listView .main-tabs-container {
        padding-top: 0; }
        .listView .main-tabs-container .nav-link {
          margin-top: 5px; } }
    .listView .main-tabs-container .header {
      display: flex;
      align-items: center; }
      .listView .main-tabs-container .header .main-underline-tabs {
        background: none; }
        .listView .main-tabs-container .header .main-underline-tabs .nav-link {
          padding-top: 0;
          padding-bottom: 18px; }
          .listView .main-tabs-container .header .main-underline-tabs .nav-link.active {
            background: none;
            color: #246ee3;
            border-bottom: 1px solid #246ee3 !important;
            font-weight: 600;
            text-shadow: none; }
          .listView .main-tabs-container .header .main-underline-tabs .nav-link:hover {
            border-bottom: 1px solid #72757e; }
      .listView .main-tabs-container .header .filters-container {
        padding-top: 0;
        color: #6c6c6c; }
        .listView .main-tabs-container .header .filters-container .filter-value.selected {
          color: #0945a5;
          font-weight: 300; }

.listView .workflows-container.dashboard .dashboard-row {
  max-width: 1200px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin: 10px auto !important;
  border-top: none !important; }
  .listView .workflows-container.dashboard .dashboard-row .smart_tile {
    margin: 0px 15px 20px !important; }
  @media (min-width: 1200px) {
    .listView .workflows-container.dashboard .dashboard-row .tile_grid:nth-child(4n+1) .smart_tile {
      margin-left: 0 !important; }
    .listView .workflows-container.dashboard .dashboard-row .tile_grid:nth-child(4n+4) .smart_tile {
      margin-right: 0 !important; } }

.listView #tabs-container .header {
  margin: 40px 0 5px;
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  .listView #tabs-container .header span, .listView #tabs-container .header div {
    font-size: 0.87rem;
    color: #a4a4a4;
    margin-right: 30px; }
    .listView #tabs-container .header span:first-child, .listView #tabs-container .header div:first-child {
      flex-grow: 1; }
    .listView #tabs-container .header span:last-child, .listView #tabs-container .header div:last-child {
      margin-right: 0; }

@media (min-width: 636px) {
  .listView #tabs-container > .dashboard-row:not(.dashboard) {
    border-top: solid 1px #B9BCC0; } }

.listView #tabs-container > .dashboard-row ~ .dashboard-row {
  border-top: none; }

.listView #tabs-container .dashboard-row.list {
  border-color: #B9BCC0; }
  .listView #tabs-container .dashboard-row.list > .col {
    max-width: 1200px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  .listView #tabs-container .dashboard-row.list .image {
    display: none; }
  .listView #tabs-container .dashboard-row.list .left-content .info-container {
    padding-left: 0; }
    .listView #tabs-container .dashboard-row.list .left-content .info-container h4 {
      color: #0945a5;
      font-size: 1rem;
      font-weight: normal; }
    .listView #tabs-container .dashboard-row.list .left-content .info-container h5 {
      color: #6c6c6c;
      font-weight: normal;
      font-size: 1rem; }
  .listView #tabs-container .dashboard-row.list .right-content {
    padding-right: 0; }
  .listView #tabs-container .dashboard-row.list .workflow .icon-action {
    margin-right: 37px; }
    .listView #tabs-container .dashboard-row.list .workflow .icon-action:last-child {
      margin-right: 0; }
  .listView #tabs-container .dashboard-row.list .tool .icon-action, .listView #tabs-container .dashboard-row.list .tool .clickable-metric {
    margin-right: 22px; }
    .listView #tabs-container .dashboard-row.list .tool .icon-action:last-child, .listView #tabs-container .dashboard-row.list .tool .clickable-metric:last-child {
      margin-right: 0; }

@media (max-width: 636px) {
  .listView .workflows-container {
    padding: 0; }
  .listView #tabs-container .header {
    display: none; }
  .listView #tabs-container .dashboard-row .smart_tile {
    margin-left: 20px !important; }
  .listView #tabs-container .dashboard-row:first-child {
    border-top: none; }
  .listView #tabs-container .dashboard-row .right-content {
    display: none !important; } }

.listView .tasks-header .container, .listView .task .container, .listView .empty-state {
  max-width: 1200px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.listView .empty-state {
  padding-top: 30px; }

.listView .tasks-header {
  border-bottom: 1px solid #d0d0d0; }
  .listView .tasks-header .container {
    padding-bottom: 0.5rem;
    color: #6c6c6c;
    font-size: 1rem;
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin: 40px 0 5px;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
    .listView .tasks-header .container.mobile > div {
      display: none !important; }
      .listView .tasks-header .container.mobile > div.task-inner {
        display: block !important; }
    .listView .tasks-header .container > div {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
      padding: 0.5rem; }
      .listView .tasks-header .container > div.small-column {
        flex: 0 0 8.33333%;
        max-width: 8.33333%; }
      .listView .tasks-header .container > div.task-inner {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
      .listView .tasks-header .container > div:last-child {
        padding-right: 0; }
    .listView .tasks-header .container span, .listView .tasks-header .container div {
      font-size: 0.87rem;
      color: #a4a4a4;
      margin-right: 30px; }
      .listView .tasks-header .container span:first-child, .listView .tasks-header .container div:first-child {
        flex-grow: 1; }
      .listView .tasks-header .container span:last-child, .listView .tasks-header .container div:last-child {
        margin-right: 0; }
    .listView .tasks-header .container div {
      margin-right: 0;
      padding: 0 0 0 5px; }

.listView .tasks ul .task {
  padding: 10px 0;
  padding: 20.4px 0;
  color: #72757e; }
  .listView .tasks ul .task .container {
    display: flex;
    justify-content: space-between; }
    .listView .tasks ul .task .container > *:not(.mobile) {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
      padding: 0.5rem; }
      .listView .tasks ul .task .container > *:not(.mobile).small-column {
        flex: 0 0 8.33333%;
        max-width: 8.33333%; }
      .listView .tasks ul .task .container > *:not(.mobile).task-inner {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
  .listView .tasks ul .task > div {
    font-size: 1rem; }
  .listView .tasks ul .task .more-options {
    padding-right: 17px; }
  .listView .tasks ul .task .actions-container {
    margin-right: 1.5rem; }
  .listView .tasks ul .task.group .task-owner {
    color: #72757e; }
  .listView .tasks ul .task div {
    font-size: 0.87rem; }
  .listView .tasks ul .task .actions-container svg, .listView .tasks ul .task .kebab-dropdown svg {
    vertical-align: top;
    width: 16px;
    height: 16px; }
  .listView .tasks ul .task .actions-container .group-icon, .listView .tasks ul .task .kebab-dropdown .group-icon {
    width: 16px; }
  .listView .tasks ul .task .kebab-dropdown svg {
    height: 24px; }
  .listView .tasks ul .task .task-inner {
    align-items: center; }
    .listView .tasks ul .task .task-inner h4 {
      font-size: 1rem;
      color: #192130; }
    .listView .tasks ul .task .task-inner h5 {
      color: #0945a5;
      font-size: 0.87rem; }
  @media (max-width: 636px) {
    .listView .tasks ul .task {
      margin-right: 0; }
      .listView .tasks ul .task > .container {
        padding: 0; }
      .listView .tasks ul .task .actions-container {
        margin-right: 0; } }

.groups .group-header {
  margin: 40px 0 5px;
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  .groups .group-header span, .groups .group-header div {
    font-size: 0.87rem;
    color: #a4a4a4;
    margin-right: 30px; }
    .groups .group-header span:first-child, .groups .group-header div:first-child {
      flex-grow: 1; }
    .groups .group-header span:last-child, .groups .group-header div:last-child {
      margin-right: 0; }

.react-admin-layout {
  margin-bottom: -150px;
  height: 100%; }
  .react-admin-layout .MuiGrid-spacing-xs-5 {
    width: calc(100% + 20px) !important; }
    .react-admin-layout .MuiGrid-spacing-xs-5 .MuiGrid-item {
      padding-bottom: 0; }
  .react-admin-layout [class^="RaLayout-contentWithSidebar-"] {
    width: 100vw; }
  .react-admin-layout .RaLayout-appFrame-154 {
    margin-top: 20px; }
  .react-admin-layout .MuiDrawer-root {
    background: url("../images/background-blue-lines.png") center center;
    border-radius: 4px;
    min-height: 100vh; }
    .react-admin-layout .MuiDrawer-root svg {
      fill: #d0d0d0; }
    .react-admin-layout .MuiDrawer-root a {
      color: #fff;
      font-weight: 600; }
      .react-admin-layout .MuiDrawer-root a:hover svg {
        fill: #fff; }
  .react-admin-layout .MuiCard-root {
    margin-bottom: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  .react-admin-layout .MuiTable-root,
  .react-admin-layout .MuiToolbar-root {
    background-color: white; }
  .react-admin-layout h2 {
    font-size: 1.3rem;
    color: #0945a5;
    text-transform: uppercase;
    margin: 10px 0 30px 0; }
  .react-admin-layout .recharts-wrapper {
    width: 100% !important;
    text-align: center; }

.project-management [class^="RaLayout-content-"] {
  padding: 10px 10px 30px 10px;
  background-color: white;
  overflow-x: scroll;
  overflow-y: hidden; }
  .project-management [class^="RaLayout-content-"] .MuiButton-root {
    background-color: #d0d0d0;
    font-weight: 600;
    font-family: OpenSans;
    color: #fff;
    padding: 5px 10px; }
    .project-management [class^="RaLayout-content-"] .MuiButton-root:hover {
      background-color: #a4a4a4; }

.project-management .MuiCard-root {
  background: url("../images/background-white-lines.png") center center; }

.project-management .react-trello-board {
  padding: 0;
  background-color: transparent;
  height: inherit; }
  .project-management .react-trello-board section {
    margin: 0;
    margin-right: 15px; }

.project-management .react-trello-lane {
  background-color: #B9BCC0; }

.project-management .react-trello-card:hover {
  background-color: #E7E8EA; }

.project-management .react-trello-card .sc-fzpans {
  color: #0945a5; }

.order-management .MuiCard-root {
  text-align: center; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

_:-ms-fullscreen, body {
  height: 100% !important; }
  _:-ms-fullscreen div#root, body div#root {
    height: 100% !important; }
    _:-ms-fullscreen div#root > main, body div#root > main {
      height: 100% !important; }

body {
  min-height: 100vh;
  -webkit-overflow-scrolling: touch; }
  body div#root {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh; }
    body div#root > main {
      flex-grow: 1;
      display: block;
      position: relative; }
      @media (max-width: 1199px) {
        body div#root > main.open .main.container {
          min-height: 409px; } }

.body {
  color: #383838; }

.bg-magenta {
  background: #0945a5; }

.navbar a {
  color: #383838; }

.no-side-padding {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.tooltip {
  z-index: 9999; }

@media (max-width: 1199px) {
  .main.container {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 15px; } }

.main.container h3,
[role=main] h3 {
  font-weight: 600; }

body.user-is-tabbing *:focus, body.user-is-tabbing .nav-item:focus, body.user-is-tabbing li:focus, body.user-is-tabbing .btn:focus, body.user-is-tabbing button:focus {
  outline: none;
  box-shadow: 0 0 0px 1px #0945a5, 0 0 0px 2px white, 0 0 0px 7px #b2d0ff !important; }

body.user-is-tabbing .focusRing___1airF.carousel__slide-focus-ring {
  outline-color: transparent;
  outline: none;
  box-shadow: none; }

*:focus, .form-control:focus, .search-form .close-search-form:focus, .btn:focus, button:focus {
  outline: none;
  box-shadow: none; }

@media (max-width: 636px) {
  h1 {
    font-size: 3rem; } }
