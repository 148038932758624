.task-header {
  margin: 50px 0;

 .task-actions-container {
   height: 100%;
 }

  @include media-breakpoint-only(md) {
    margin: 30px 0 30px 0;
  }

  @include media-breakpoint-only(sm) {
    margin: 30px 0;
    display: flex;

    .task-header-container {
     width: 100%;
    }

    .task-details-section {
      padding-left: 0;
    }
  }
}
