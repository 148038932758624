// List Group

.list-group-item {
  border-right: 0;
  border-left: 0;

  &:first-child,
  &:last-child {
    border-radius: 0;
  }
}
