.steps-page{
  display: flex;
  height: 100%;

  .steps-tabs{
    background-color: rgba($gray-50, 0.5);
    min-width: 320px;
    font-size: 0.87rem;

    h3{
      text-transform: uppercase;
      font-size: 0.87rem;
      margin: 30px 0;
      margin-left: 60px;
      margin-right: 30px;
      border-bottom: 1px solid $gray-300;
      padding-bottom: 3px;
      color: $primary;
      font-weight: 600;
    }

    .MuiTabs-vertical{
      .MuiTabs-indicator{
        display: none;
      }

      .MuiTab-root{
        text-transform: none;
        max-width: 100%;
        font-family: OpenSans;
        text-align: left;
        padding-left: 60px;

        .MuiTab-wrapper{
          align-items: flex-start;
        }

        &:hover{
          color: $primary
        }

        &.Mui-selected{
          background: $gray-100;
          color: $primary
        }
      }
    }
  }

  .steps-tab-content{
    flex-grow: 1;
    padding: 30px;

    h2{
      font-size: 0.87rem;
      color: $primary;
      text-transform: uppercase;
      border-bottom: 1px solid $gray-300;
      padding-bottom: 3px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      button{
        background: none;
        border: none;
        display: flex;
        align-items: flex-end;
        font-size: 1rem;
        color: $blue;

        span{
          display: inline-block;
          margin-left: 6px;
        }

        svg{
          fill: $blue;
        }
        
        &:hover{
          color: $primary;

          svg{
            fill: $primary;
          }
        }
      }
    }

    .step-fields{
      padding-right: 30px;

      .MuiFormControl-root:first-child{
        margin-top: 0
      }
    }

    .step-config{
      background: $gray-50;
      padding: 30px;
      height: fit-content;
    }
  }
}