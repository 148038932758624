.task-actions {
  display: flex;

  .goto-link {
    @media screen and (-ms-high-contrast: active) {
      svg path {
        fill: $white;
      }
    }
  }

  .icon-action {
    height: 60px;
    width: 60px;
    border: solid 1px $black;

    svg path{
      stroke-width: 1;
      stroke: $black;
    }

    &.goto-link {
      svg path{
        stroke: none;
      }
    }

    &:hover{
      border-color: $primary;
    }
  }

  .task-link {
    margin-left: 30px;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    justify-content:space-between;

    .icon-action {
      height: 32px;
      width: 32px;
      display: flex;
    }

    .task-link {
      margin-left: 0px;
    }
  }
}
