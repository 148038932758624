.workflow-detail-page{
  .config-tab{
    width: 100%;
    background: $gray-50;
    color: $gray-450;
    padding: 20px 0;

    >.container{
      display: flex;
      justify-content: flex-end;
    }

    a{
      color: $gray-450;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;

      .title{
        vertical-align: middle;
      }

      svg{
        fill: $gray-450;
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }

      &:hover{
        color: $primary;
        text-decoration: none;

        svg{
          fill: $primary
        }
      }
    }
  }
}

.workflow-edit-page{
  height: 100%;
  padding-top: 118px;
 
  .nav-tabs {
    padding-top: 20px;

    #nav-link- {
      &:hover {
        border-color: white;
      }
    }
  }

  .tabs-header{
    z-index: 999;
    position: fixed;
    top: 70px;
    width: 100%;
    background: $gray-450;
    color: $white;
    padding: 0;
    border-bottom: solid 1px $white;

    >.container{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .main-underline-tabs{
      background: none;

      .nav-link{
        background: none;
        font-size: 1rem;
        color: $white;
        padding: 0 0 15px 0;
        text-transform: uppercase;

        &.active{
          font-weight: 600;
          text-shadow: none;
          border-color: $white;
        }
      }
    }

    .close-editor{
      color: $white;
      background-color: unset;
      font-weight: 600;
      padding: 7px 20px;
      font-size: 0.87rem;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin-left: 30px;

      svg{
        width: 18px;
        fill: $white;
        margin-right: 8px;
      }

      &:hover{
        background-color: $gray-200;
        text-decoration: none;
      }
    }
  }

  .tab-content{
    &.steps-content{
      .tab-pane{
        height: 100%;
      }
    }

    .MuiGrid-container{
      margin: 34px 0;

      .MuiFormControl-root{
        width: 100%;
        margin: 14px 0;

        .Mui-focused {
          border: 0.5px;
        }

        .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
          border-color: $blue-medium;
        }

        input{
          font-size: 1rem;
          color: $blue-dark;
        }

        .MuiInputBase-input {
          padding: 14px 14px;
        }

        .MuiOutlinedInput-multiline {
          padding: 0;
        }
      }
    }
  }
}
