.tool-header, .workflow-header{
  width: 100%;
  height: 40px;
  position: relative;

  .back-btn{
    position: absolute;
    top: 30px;
    background: $white;
    margin-top: 0!important;

    &:hover{
      text-decoration: none;
    }
  }

  .title-bar{
    display: none;
    position: absolute;
    bottom: 16px;
    z-index: 99999;

    h2,.category{
      color: white;
      margin-bottom: 0;
      float:left;
    }

    .category{
      clear:left
    }
  }

  .tile-gradient{
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.75;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 9%, rgba(32, 32, 32, 0.5) 62%, $gray-800);
  }
}

.tool-top, .workflow-header, .workflow-top{
  h2{
    text-transform: uppercase;
    font-size: 2.25rem;
    color: $black;
    margin-bottom: 0;
  }

  .category{
    font-size: 1.5rem;
    color: $gray-500
  }
}

.tool-top, .workflow-top{
  padding: 36px 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .icons{
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .icon-action{
      border: solid 1px $black;
      margin-left: 40px;
      padding: 0;

      @media screen and (-ms-high-contrast: active) {
        svg path {
          fill: $white;

          &:hover{
            fill: $primary;
          }
        }
      }

      &:hover{
        border-color: $primary;
      }

      &.icon-favorite{
        svg path{
          stroke: $black
        }
      }
    }
  }
}

.tool-content, .workflow-content{
  padding: 46px 0 0;

  .sidebar-header{
    margin-top: 0!important;
    padding-bottom: 0!important
  }

  .label{
    font-size: 1.5rem;
    color: $black;
    font-weight: 500;
  }

  p{
    margin: 7px 0 0;
    color: $black;
    font-size: 1.2rem;
  }
}

@media (max-width: map-get($grid-breakpoints, 'lg')) {
  .tool-header, .workflow-header{
    height: 180px;
    z-index: 99;
  }

  .tool-top, .workflow-top{
    padding: 30px 0;

    h2{
      font-size: 1.5rem;
    }

    .category{
      font-size: 1.3rem;
    }

    .icon-action{
      width: 48px;
      height: 48px;
    }
  }

  .workflow-top{
    .icon-action{
      margin-left: 8%!important;

      &:first-child{
        margin-left: 3px!important
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
  .tool-header, .workflow-header{
    height: 90px;

    .back-btn{
      display: none;
    }
  }

  .tool-top, .workflow-header{
    h2,.category{
      font-size: 0.9rem;
    }
  }

  .workflow-header{
    .title-bar{
      display: block;
    }

    .tile-gradient{
      display: block;
    }
  }

  .tool-top, .workflow-top{
    padding: 16px 0;

    .icons{

      .icon-action{
        width: 32px;
        height: 32px;
        margin-left: 20px;

        &.icon-counter{
          font-size: 1rem;

        }

        svg{
          width: 14px;
          height: 14px;

          @media screen and (-ms-high-contrast: active) {
            path {
              stroke: $white;
            }
          }

        }
      }
    }
  }

  .workflow-top{
    .title-bar{
      display: none
    }

    .icons{
      justify-content: space-between;

      .icon-action{
        margin-left: 0;
      }
    }
  }

  .tool-content, .workflow-content{
    padding: 10px 0;

    .label, p{
      font-size: 0.9rem;
    }
  }

  .tool-content{
    padding: 30px 0;
  }

  .workflow-content{
    padding: 10px 0;
  }
}