.ant-select-dropdown-menu{
  padding: 5px 10px;
  margin: 0;
  list-style: none;
  color: $gray-450;

  li:not(:last-child){
    margin-bottom: 5px;
    cursor: pointer;

    &:hover{
      color: $gray-500;
    }
  }
}
