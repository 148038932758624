.card {
  background: $gray-200;

  .card-img-overlay {
    cursor: not-allowed;
    background-color: $white;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    z-index: 3;
  }

  .card-content  {
    background: none;
    padding: 0.5rem;

    &:last-child {
      overflow: hidden;
    }
  }
}
