.MuiMenu-paper {
  font-family: inherit;
  .MuiMenu-list{
    padding: 0;
    
    .MuiMenuItem-root{
      &.last{
        border-bottom: none;
      }  
      border-bottom: 1px solid #ccc;  
      
      
    }
  }
}