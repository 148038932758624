.background-layout {
  background: url('../images/background_image_login.png') center center;
  padding: 100px 0;
  padding: 100px 0 150px;
  margin-bottom: -150px;
  height: 100vh;

  @include media-breakpoint-only(md) {
    padding: 110px 0;
    margin-bottom: -90px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
    margin-bottom: -60px;
  }

  h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
      color: $white;
    }

    @include media-breakpoint-down(sm) {
      font-size: 21px;
    }
  }
}

.form-container {
  width: 770px;
  margin: auto;

  .form {
    margin: auto;

    .form-group {
      margin-top: 30px;

      a {
        font-size: 24px;

        @include media-breakpoint-down(sm) {
          font-size: 18px;
        }
      }
    }
  }

  .external-providers-actions{
    height: 52px;
    display: flex;
    justify-content: space-around;

    svg{
      path{
        fill: $white;
      }
    }
  }

  h2 {
    font-size: 24px;
    font-weight: 500;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }

  p {
    @include media-breakpoint-down(sm) {
      font-size: 15px;
    }
  }

  hr {
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-color: $gray-400;

    @include media-breakpoint-up(md) {
      margin: 48px 0;
    }

    @include media-breakpoint-down(sm) {
      margin: 30px 0;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 100px;
  }

  @include media-breakpoint-only(md) {
    padding: 48px 0 0;
    width: 463px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 30px;
    width: 100%;
  }
}

.intro-text {
  color: $black;
  font-size: 18px;
}

#tabs-container {
  .dashboard-row {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;

    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      justify-content: center;
    }

    &.layout-3 {
      margin-left: -10px;
      margin-right: -15px;
      margin-bottom: -20px;

      @media (max-width: map-get($grid-breakpoints, 'lg')) {
        margin-bottom: -12px;
      }
    }

    &.layout-2 {
      margin-left: -20px;
      margin-right: -25px;
      margin-bottom: -30px;
      
      @media (max-width: map-get($grid-breakpoints, 'md')) {
        margin-bottom: -6px;
      }
    }

    &.layout-4 {
      margin-left: -5px;
      margin-right: -10px;
      margin-bottom: -10px;
    }

    .tile_grid {
      .layout-3 {
        margin-left: 10px;
        margin-right: 10px;
      }

      .layout-2 {
        margin-left: 20px;
        margin-right: 20px;
      }

      .layout-4 {
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    .workflow-elements-container {
      .layout-3-cols & {
        padding-left: 12px;
        padding-right: 12px;
        margin-bottom: 24px;
      }

      .layout-4-cols & {
        padding-left: 4px;
        padding-right: 4px;
        margin-bottom: 8px;
      }
    }
  }
}

.panel-btn {
  width: calc(100% + 30px);
  background: white;
  border: none;
  text-align: left;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f5f1f3;
  height: 50px;
  vertical-align: middle;
  margin-left: -15px;
  margin-right: -15px;

  h5 {
    margin: 0;
    color: $gray-700;
    font-size: 15px;
  }

  .icon {
    @include arrow-icons(1.49625rem);
  }
}

.layout-controls {
  cursor: pointer;

  &.plus {
    padding-left: 7px;
  }

  &.minus {
    padding-right: 7px;
    vertical-align: text-bottom;
  }
}

.workflows-container{
  &.is-hidden{
    display: none;
  }
}
