.btn-action-round {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  border: 1px solid $black;
  align-items: center;
}

.clickable-metric{
  background: none;
  border: none;
}

.MuiButtonBase-root {  
  
  min-height: 40px;
  font-family: inherit;
  &.MuiButton-containedPrimary{
    background: #246ee3;
  }
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
  .MuiButton-root {  
  
    min-height: 48px;
    
    &.MuiButton-containedPrimary{
      background: #246ee3;
    }
    .MuiButton-label{
      font-size: 16px;
    }
  }
}