#propview{
  .tab-content{
    background-color: white;

    &.steps-content{
      padding-top: 10px;
    }

    .description{
      font-size: 0.75rem;
      color: $gray-450;
      width: 100%;
      margin-bottom: 20px;
    }

    .MuiAccordion-root{
      border-radius: 0!important;
      box-shadow: none!important;
      padding: 0;
      margin-top: 0!important;
      margin-bottom: 0;
      border-bottom: solid 1px $gray-300;
      font-size: 0.87rem;
      color: $gray-450;

      &:before{
        display: none;
      }

      &:hover{
        > .MuiAccordionSummary-root{
          .MuiAccordionSummary-content{
            color: $blue;
          }
        }
      }

      .MuiAccordionSummary-root{
        min-height: inherit;

        &.Mui-expanded{
          .MuiAccordionSummary-content{
            color: $blue
          }
        }
      }
      
      .MuiAccordionSummary-content{
        color: $primary;
        text-transform: uppercase;
        font-size: 0.87rem;
        font-weight: 600;
        margin: 20px 0;
      }
    }

    .MuiAccordionSummary-root, .MuiAccordionDetails-root{
      padding: 0 30px;
    }

    .MuiAccordionDetails-root{
      flex-direction: column;
      margin-bottom: 20px;
    }

    .not-created{
      font-size: 0.87rem;
      color: $gray-450;
      margin: 10px 0;
    }
    
    .step-list-accordion{
      &.MuiAccordion-root{
        border-bottom: none;

        &:hover{
          > .MuiAccordionSummary-root{
            .MuiAccordionSummary-content{
              color: $blue-dark;
              font-weight: 600;
            }
          }
        }
      }

      .MuiAccordionSummary-root, .MuiAccordionDetails-root{
        padding: 0;
      }

      .MuiAccordionSummary-root {
        &.Mui-expanded{
          .MuiAccordionSummary-content{
            color: $blue-dark;
            font-weight: 600;
          }
        }
      }

      .MuiAccordionDetails-root{
        margin-bottom: 0;
        color: $gray-400;
        font-size: 0.87rem;
      }

      .MuiAccordionSummary-content{
        color: $blue-dark;
        font-size: 0.87rem;
        font-weight: normal;
        margin: 10px 0;
        text-transform: none;
      }
    }
  }
}
