//
// dashboard animation easings
// ----------------------------------------
$dashboard-ease-in-light:             cubic-bezier(.7, 0, 1, 1) !default;
$dashboard-ease-in-medium:            cubic-bezier(.6, 0, .7, .2) !default;
$dashboard-ease-in-strong:            cubic-bezier(.9, .2, .8, .1) !default;
$dashboard-ease-out-light:            cubic-bezier(0, 0, .55, 1) !default;
$dashboard-ease-out-medium:           cubic-bezier(.2, .7, .3, 1) !default;
$dashboard-ease-out-strong:           cubic-bezier(.2, 1, .2, 1) !default;
$dashboard-ease-in-out:               cubic-bezier(.65, .045, .3, 1) !default;
$dashboard-ease-elastic:              cubic-bezier(.5, .2, .2, 1.25) !default;
