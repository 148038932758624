@mixin form-validation-state-dashboard($state, $color) {
  .form-control,
  .custom-select {
    .was-validated &:#{$state},
    &.is-#{$state} {
      &:not(:disabled) {
        background-color: mix($color,$white, 6.25%);

        &:hover {
          background-color: mix($color,$white, 12.5%);
        }

        &:focus {
          background-color:$white;
        }

        &:active {
          background-color: mix($color,$white, 18.75%);
        }
      }
    }
  }
}
