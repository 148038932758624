.data-content{
  .steps-tab-content{
    color: $gray-450;
    padding-right: 0;

    h2{
      margin-bottom: 30px;
    }

    .MuiGrid-container{
      margin: 0!important;
    }

    .nav-tabs{
      position: fixed;
      margin-top: -97px;
      z-index: 999;
      background: transparent;

      .nav-link{
        color: $gray-450;
        font-size: 1rem;
        padding-bottom: 14px;

        &.active, &:hover{
          color: $gray-450;
          background: transparent;
          border-bottom: 1px solid $gray-450!important;
        }

        &.active{
          font-weight: 600;
          text-shadow: none;
        }
      }
    }

    .json-schema-preview{
      background-color: rgba($gray-50, 0.5);
      padding: 30px;
      margin-top: -30px;
      transition: max-width 0.5s;
      overflow: hidden;

      &.hidden{
        max-width: 57px;

        .contents{
          display: none;
        }
      }

      h3{
        color: $gray-450;
        font-size: 0.87rem;
        font-weight: 600;
        text-transform: uppercase;
        border-bottom: 1px solid $gray-300;
        padding-bottom: 3px;
        margin-bottom: 27px;
      }

      .hide-show-icon{
        background: none;
        border: none;
        position: absolute;
        top: 130px;
        right: 15px;

        &:hover{
          svg path{
            fill: $blue-dark;
          }
        }
      }

      input, select, textarea{
        background: transparent;
        border-radius: 4px;
        border: solid 1px $gray-450;
        color: $gray-450;
        font-size: 1rem;
      }
    }

    .json-schema-editor{
      padding: 0 30px 30px 0;
      transition: max-width 0.5s;

      &.expanded{
        max-width: calc(100% - 60px);
        flex-basis: calc(100% - 60px);
      }

      .json-schema-react-editor{
        .import-json-button{
          display: none;
        }

        .ant-row-flex{
          display: flex;
        }
      }
    }
    
    .monaco-editor-container{
      border-radius: 4px;
      border: solid 1px $gray-400;
      min-height: 635px;
      padding-bottom: 1px;

      .header{
        height: 48px;
        width: 100%;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 16px 10px;
        background-color: $gray-50;
        margin-bottom: 10px;

        h3{
          font-size: 1rem;
          color: $gray-400;
        }
      }

      .react-monaco-editor-container{
        .monaco-editor-background, .margin-view-overlays{
          background: $white;
        }
 
        .monaco-mouse-cursor-text{
          .mtk1{
            color: $blue-dark;
          }
        }

        .scroll-decoration{
          box-shadow: none;
        }

        .decorationsOverviewRuler{
          display: none;
        }

        .line-numbers{
          color: $primary;
        }
      }
    }
  }
}