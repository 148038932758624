// Breadcrumb

.breadcrumb {
  font-size: .95rem;
}

.breadcrumb-item {
  &,
  a {
    color: #757575;
  }

  + .breadcrumb-item::before {
    padding-right: .4rem;
    padding-left: .4rem;
    line-height: 1;
    background-image: $breadcrumb-divider-icon;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  &.active {
    color: $primary;
  }
}
