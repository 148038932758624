.tasks {
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    ul{
      .task:first-child{
        padding-top: 0
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.sticky {
  .tasks {
    max-height: 100vh;
  }
}
