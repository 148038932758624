.json-schema-react-editor{
  .wrapper{
    padding-left: 0;
  }

  .down-style-col{
    width: 16px;
    max-width: 16px;
  }

  .option-formStyle{
    padding-top: 0!important;
  }

  .object-style{
    margin: 0!important;
    padding: 0!important;

    >div:not(.ant-row-flex):not(.schema-content){
      padding-left: 8px;
    }
  }
  
  .col-item{
    margin-bottom: 15px;
    padding-left: 0!important;
  }

  .col-item-name{
    flex: 0 0 23.33333333%;
    max-width: 23.33333333%;

    .down-style-col{
      flex: 0 0 12%;
      max-width: 12%;

      svg{
        width: 16px;
        height: 12px;
      }
    }

    .ant-col-22{
      flex: 0 0 88%;
      max-width: 88%;
    }
  }

  .col-item-type{
    flex: 0 0 16.5%;
    max-width: 16.5%;

    .ant-select-selection__rendered{
      text-align: left;
    }
  }

  .col-item-mock{
    flex: 0 0 23.83333333%;
    max-width: 23.83333333%;
  }

  .col-item-desc{
    flex: 0 0 24.83333333%;
    max-width: 24.83333333%;
  }

  .col-item-setting{
    padding-top: 10px;
    padding-left: 13px!important;
    display: flex;

    >span:not(:last-child){
      margin-right: 12px;
      padding: 0;
    }
  }

  input, .ant-select-selection{
    border: solid 1px $gray-400;
    border-radius: 4px!important;
    padding: 10px;
    color: $gray-450;
    font-size: 1rem;
  }

  .ant-input-wrapper{
    .ant-input-group-addon{
      z-index: 2;
      position: absolute;
      right: 18px;
      top: 12px;
    }
  }

  .col-item:not(.col-item-name){
    .ant-input-group-addon{
      display: none;
    }
  }

  svg{
    fill: $gray-450;
    width: 16px;
    height: 16px
  }

  .delete-item{
    padding-right: 0;
    display: inline-block;
    margin-top: 4px;
    width: 16px;
    height: 16px;
  }

  .ant-checkbox-inner{
    border: solid 2px $gray-400;
    background: none;
  }
}
