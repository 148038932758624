// Forms

// hover, focus and active states
.form-control,
.custom-select {
  &:not(:disabled) {
    &:hover {
      background-color: $white;
    }

    &:focus {
      background-color: $white;
    }

    &:active {
      background-color: $white;
    }
  }
}

@include form-validation-state-dashboard("valid", $form-feedback-valid-color);
@include form-validation-state-dashboard("invalid", $form-feedback-invalid-color);

.has-icon {
  position: relative;

  .form-control {
    padding-left: 36px;

    .input-group-large & {
      padding-left: 52px;
    }
  }
}

.form-icon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  pointer-events: none;
}

.MuiInputBase-input, .MuiMenuItem-root, .MuiFormControlLabel-label, .MuiFormLabel-root{
  font-size: 1rem!important;
  font-family: OpenSans!important
}

.MuiInputBase-input{
  background: transparent!important
}

.MuiInputBase-input, .MuiMenuItem-root, .MuiFormControlLabel-label{
  color: $blue-dark!important;
}

.MuiFormLabel-root.Mui-focused{
  color: $primary!important;
}

.MuiListItem-root{
  min-height: 36px!important;
}

.MuiFormHelperText-root{
  font-size: 0.87rem!important;
  padding: 0!important;
  margin: 5px 0 0!important;
  color: $gray-450!important;
  font-family: OpenSans!important;
}

.MuiCheckbox-colorPrimary.Mui-checked{
  color: $primary!important;
}

.MuiInputLabel-outlined:not(.MuiFormLabel-filled):not(.Mui-focused){
  transform: translate(14px, 16px) scale(1)!important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid $primary!important;
}

.MuiInputBase-root {
  legend {
    font-size: 13px!important;
  }
}

.MuiOutlinedInput-input.Mui-disabled{
  background-color: $gray-50!important;
}
