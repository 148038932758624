.accordion-item {
  background: $gray-100;
}

.accordion-header {
  background: $gray-200;
  border-bottom: 2px solid $white;
  background-position: 98% center;
  background-repeat: no-repeat;
  background-size: 20px;
  background-image: url('/images/arrow_down.svg');

  &.open {
    background-image: url('/images/arrow_up.svg');

    button {
      color: $primary;
    }
  }

  button {
    font-size: .85em;
    text-transform: uppercase;
    display: block;
    width: 100%;
    text-align: left;
    color: $gray-500;

    &:hover {
      color: $primary;
    }
  }
}

.accordion-body {
  padding: 5px 13px 20px;
  font-size: .85em;
}
