.task-details {
  .task-details-section {
    border-right: 1px solid $border-color;
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;

    @include media-breakpoint-down(sm) {
      border-right: unset;
      padding-right: 0px;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }

  .main-underline-tabs {
    font-size: 18px;
  }

  .errors-content{
    ul{
      margin-bottom: 5px;
    }
  }
}

.task-form-container {
  flex-grow: 1;
  margin-right: 200px;
  min-width: 50%;

}

.form-submit-info {
  background-color: $gray-100;
  border-left: 1px solid $primary;
  padding: 30px 30px;
  margin: 50px 0;

  h5 {
    font-size: 21px;
  }

  @include media-breakpoint-down(sm) {
    margin: 30px 0;
    padding: 30px 25px;
    h5, p {
      font-size: 15px;
    }
  }
}

.form-tab-info {
  height: auto;
  background-color: $gray-100;
  padding: 30px 30px;
  font-family: $font-family-opensans;
  border-left: 1px solid $primary;
  margin-left: 200px;
  // IE11 bug fix
  min-width: 400px;
  max-width: 400px;
}

.full-width-break {
  margin: 0;
}

@include media-breakpoint-down(md) {
  .form-tab-container {
    width: 65%;
    margin: auto;
  }

  .form-actions-container {
    float: none;
    float: none;
    display: flex;
    justify-content: space-between;
  }
}

@include media-breakpoint-down(sm) {
  .task-form-container {
    margin-top: 30px !important;
  }

  .form-tab-container {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
