.search-form {
  position: absolute;
  right: 205px;
  flex-grow: 1;
  z-index: 2;
  width: 450px;
  padding-top: 0 !important;

  .form-group {
    width: 100%;

    margin-bottom: 0;

    .search-icon {
      top: 0;
      right: 0px;
    }

    &:hover {
      input.form-control {
        border-color: $black;
        background: $gray-230;
      }

      svg {
        fill: $primary
      }
    }

    .form-control {
      display: none;

      &::-ms-clear {
        display: none;
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    > .container {
      padding: 0;
      margin: 0;
    }
  }

  @include media-breakpoint-up(sm) {
    padding-top: .55rem;
    border-top: none;
    border-bottom-color: $primary;
    height: auto;
  }

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    > .container {
      position: relative;
    }

    width: 75%;
    left: 0;
  }

  &.active {
    .form-control {
      display: block;
    }
  }

  .close-search-form {
    @extend .form-control;
    width: 1rem !important;
    flex: none !important;
    padding-right: 1.25rem;
  }

  input.form-control {
    border: none;
    border-radius: 100px;
    background-color: $gray-230;
    padding: 14px 20px;
    height: auto;
    font-size: 0.87rem;

    &:focus {
      border-color: $primary !important;
      background: $gray-230!important;
    }

    &:active {
      border-color: $primary !important;
      background: $gray-230!important;
    }

    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      border: none;
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    width: 70%;
    
    input.form-control {
      padding: 8px 5px;
      font-size: 0.8rem;
    }
  }

  .input-group-append {
    .btn-search-submit {
      background-color: $gray-350;
      border-color: transparent;
      padding-top: 2px;
    }
  }

  .icon {
    width: 16px;
  }

  ul.suggestions {
    position: absolute;
    padding: 0;
    background-color: $white;
    width: 100%;
    z-index: 99999;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px $gray-500;
    border-radius: 0.25rem;
    overflow: hidden;

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      .btn {
        font-size: 0.9rem;
      }
    }

    li {
      color: $black;
      padding: 10px 0;

      &:not(:last-child) {
        border-bottom: solid 1px $gray-500;
      }

      button, .no-suggestions {
        width: 100%;
        border: 0;
        background: transparent;
        margin: 0;
        border-radius: 0;
        padding: 0.375rem 0;
      }

      button {
        text-align: left;

        &:hover,
        &.highlighted {
          color: $primary;
        }
      }

      .no-suggestions {
        font-size: 1rem;
        line-height: 1.21;
        letter-spacing: 0.8px;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        text-align: center;
        color: $gray-600
      }
    }

    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      border: none;
      border-radius: 0;
      top: 64px;
      left: 0;
      overflow: hidden;
    }

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      top: 42px;
    }
  }
}
