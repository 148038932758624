// Close

.close {
  opacity: 1;

  &::before {
    display: block;
    width: 16px;
    height: 16px;
    content: " ";
    background-image: $close-icon;
  }

  > span {
    display: none;
  }
}

