// Navbar

.navbar {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.navbar-nav {
  .nav-item {
    position: static;
  }

  &.utilities-nav {
    .navbar-icon {
      height: 1em;
    }
  }
}

.navbar-expand {
  .navbar-nav {
    .nav-link {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }
}
