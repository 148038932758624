.search-results {
  list-style: none;
  margin: 0;
  padding: 10px 0 0;
}

.search-result {
  display: flex;
  border-bottom: none;
  padding: 0!important;
  position: relative;
  background: white;
  margin-top: 10px;

  .search-result-link {
    align-items: baseline;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    border-bottom: 1px solid $gray-200;
    color: $gray-700;
    text-decoration: none;

    &:hover {
      text-decoration: none;

      &:after {
        content:"";
        position:absolute;
        width:100%;
        left: 0;
        bottom:1px;
        z-index:-1;
        transform:scale(.987);
        box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.16);
      }
    }
  }

  .search-result-info{
    flex-grow: 1;

    .search-result-title {
      color: $black;
      font-weight: bold;
      font-size: 1.1rem;
      margin-bottom: 0.3rem;
    }
  
    .search-result-subtitle {
      color: $gray-600;
      font-size: 1.1rem;
      margin-bottom: 0
    }
  }

  .type{
    font-size: 1rem;
  }

  .results-icon {
    object-fit: cover;
    width: 130px;
    height: 87px;
    margin-right: 15px;
    display: flex;
    background: $gray-250;

    svg{
      width: 24px;
      height: 24px;
      margin: auto;
      fill: $gray-500;
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    .results-icon {
      display: none;
    }

    .search-result-title, .search-result-subtitle {
      font-size: 1rem;
    }

    .search-result-link {
      padding: 15px 0;
    }
  }
}

