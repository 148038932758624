@mixin triangle($top) {
  content: ' ';
  z-index: -1;
  position: relative;
  top: $top;
  left: calc(50% - 20px);
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $gray-200;
}
