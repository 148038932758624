//
// Brandnav
// ----------------------------------------

$custom-nav-icon-forward:  str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg class='navbar-nav-svg' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 20' focusable='false'%3E%3Cpath d='M1 1l9 9l-9 9' stroke='currentColor' fill='none'%3E%3C/path%3E%3C/svg%3E"), "#", "%23") !default;

@include media-breakpoint-down(md) {
  .brandnav {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    display: none;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    overflow: visible;
    background-color: $white;
    transition: margin-left .3s ease-in-out;

    .brandnav {
      top: 0;
    }

    .active > & {
      left: 100%;
      display: block;
    }

    &.brandnav-lvl-container-1 {
      left: 0;
      margin-top: 1px;
    }

    &.has-active {
      margin-left: -100%;
    }

    @supports (transform-style: preserve-3d) {
      transition: transform .3s ease-in-out;
      perspective: 1000px;

      &.has-active {
        margin-left: 0;
        transform: translate3d(-100%, 0, 0);
      }
    }

    .brandnav-lvl-1 > .nav-item {
      position: static;
      width: 100%;
      padding-right: 0;
      padding-left: 0;
    }

    .nav-item > .nav-link {
      font-size: 21px;

      &:not(:only-child) {
        &::after {
          float: right;
          width: .5rem;
          height: 1rem;
          margin-top: .2rem;
          margin-right: .1rem;
          content: "";
          background-image: $custom-nav-icon-forward;
        }
      }
    }
  }

  .brandnav-lvl-container-2 > .container {
    max-width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .brandnav {
    transition: color .3s ease-in-out;
    &.has-active {
      color: #000; // dummy property to force a transitionend event
    }
  }

  .brandnav-lvl-container-1 {
    float: left;
  }

  .brandnav-lvl-1 {
    > .nav-item {
      position: static;
      float: left;

      &:not(:last-child) {
        margin-right: 1.45rem;
      }

      > .nav-link {
        padding: .325rem .75rem;
        font-size: 21px;
        text-align: center;
        border-radius: 3px;
      }

      &.active > .nav-link {
        color: theme-color("primary");
      }
    }
  }

  .brandnav-lvl-container-2 {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 1px;
    overflow: hidden;
    background-color: $white;

    .brandnav-lvl-2 {
      margin-right: auto;
      margin-left: auto;
      @include clearfix();
      opacity: 0;
      transition: opacity .25s $dashboard-ease-out-medium;

      > .nav-item > .nav-link {
        padding-top: .8125rem;
        padding-bottom: .75rem;
        font-size: $font-size-base;
        font-weight: 600;
        color: $body-color;
        text-transform: uppercase;
        cursor: default;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .active > & {
      z-index: 1;
      height: auto;
      padding-top: 10px;
      padding-bottom: 18px;

      .brandnav-lvl-2 {
        opacity: 1;
      }
    }

    @supports (transform-style: preserve-3d) {
      height: auto;
      padding-top: 10px;
      padding-bottom: 18px;
      transition: transform .25s $dashboard-ease-in-medium;
      perspective: 1000px;
      transition-delay: .25s;
      transform: scaleY(0);
      transform-origin: top;

      .cancel {
        margin: .25rem .625rem;
        opacity: 0;
        transition: opacity .25s $dashboard-ease-out-medium;
        transition-delay: 0s;
      }

      .brandnav-lvl-2 {
        transition-delay: 0s;
      }

      .active > & {
        transition-delay: 0s;
        transform: scaleY(1);

        .brandnav-lvl-2 {
          transition-delay: .25s;
        }

        .cancel {
          opacity: 1;
          transition-delay: .25s;
        }
      }
    }
  }

  .nav-item-lvl-2 {
    > .nav-link {
      font-weight: 600;
      pointer-events: none;
    }
  }

  .brandnav-lvl-3 > .nav-item > .nav-link {
    padding-top: .8125rem;
    padding-bottom: .75rem;
    font-size: $font-size-base;
  }

}

.brandnav-lvl-2-head {
  display: none;
  width: 100%;
  min-height: building-units(2.5);
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
  @include clearfix();

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.brandnav-control-up:not(.in) {
  pointer-events: none;
  visibility: hidden;
}

.brandnavhead {
  display: none;
  min-height: building-units(6.5) - 1px;
  padding-right: 12px;
  padding-bottom: .3125rem;
  padding-left: 12px;
  background-color: $white;

  .active & {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  label {
    margin-top: .8rem;
  }

  .navbar-nav {
    align-items: center;
    justify-content: space-between;
    height: 100%;

    :nth-child(2) {
      overflow: hidden;
    }
  }
}

.brand-navbar-container:not(.active) {
  .brandnavhead {
    display: none;
  }
}

.navbar-header-label {
  font-size: 21px;
  color: theme-color("primary");
  text-align: center;
}
