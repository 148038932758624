@mixin list-view-header{
  margin: 40px 0 5px;
  display: flex;
  justify-content: space-between;
  max-width: map-get($grid-breakpoints, 'lg');
  @include make-container();

  span, div{
    &:first-child{
      flex-grow: 1
    }

    font-size: 0.87rem;
    color: $gray-400;
    margin-right: 30px;

    &:last-child{
      margin-right: 0;
    }
  }
}
