  .task-status {
    &.created {
      .task-status-label {
        color: $green;
      }
      .inner-circle {
        background: $green;
      }

      .outer-circle {
        background: $light-green;

        @media screen and (-ms-high-contrast: active) {
          border: 0.1875em solid #0F1C3F;
          .inner-circle {
            border: 0.1875em solid #0F1C3F;
          }
        }
      }
    }

    &.ongoing, &.processing {
      .task-status-label {
        color: $yellow;
      }

      .inner-circle {
        background: $yellow;
      }

      .outer-circle {
        border:1px solid $yellow;
      }
    }

    &.closed, &.with_errors {
      .task-status-label {
        color: $red;
      }
      .inner-circle {
        background: $red;
      }
      .outer-circle {
        background: $light-red;
      }
    }

    .outer-circle {
      position: relative;
      height: 60px;
      width: 60px;
      display: inline-block;
      border-radius: 50%;
    }

    .inner-circle {
      position: relative;
      top: 25%; left:25%; 
      width:50%;
      height:50%;
      border-radius: 50%;
    }

    .task-status-label {
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      font-size: 16px;
      margin-bottom: 0;
      @include media-breakpoint-down(lg) {
        height: 11px;
      }
    }

    @include media-breakpoint-down(sm) {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .outer-circle {
        border-width: 0px;
        height: 12px;
        width: 12px;
        margin: 0 10px 0 0;
      }

      .task-status-label {
        font-size: 12px;
      }
    }
  }
