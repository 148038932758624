.listView{
  .workflows-container.dashboard{
    .dashboard-row{
      max-width: map-get($grid-breakpoints, 'lg');
      @include make-container();
      margin: 10px auto!important;
      border-top: none!important;

      .smart_tile{
        margin: 0px 15px 20px!important;
      }

      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        .tile_grid:nth-child(4n+1){
          .smart_tile{
            margin-left: 0!important
          }
        }

        .tile_grid:nth-child(4n+4){
          .smart_tile{
            margin-right: 0!important
          }
        }
      }
    }
  }
  
  #tabs-container{
    .header{
      @include list-view-header;
    }

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      > .dashboard-row:not(.dashboard) {
        border-top: solid 1px $gray-200;
      }
    }

    > .dashboard-row ~ .dashboard-row {
      border-top: none;
    }

    .dashboard-row.list{
      border-color: $gray-200;

      >.col{
        max-width: map-get($grid-breakpoints, 'lg');
        @include make-container()
      }
      
      .image{
        display: none;
      }

      .left-content{
        .info-container{
          padding-left: 0;

          h4{
            color: $primary;
            font-size: 1rem;
            font-weight: normal;
          }

          h5{
            color: $gray-600;
            font-weight: normal;
            font-size: 1rem;
          }
        }
      }

      .right-content{
        padding-right: 0;
      }

      .workflow{
        .icon-action {
          margin-right: 37px;

          &:last-child{
            margin-right: 0;
          }
        }
      }

      .tool{
        .icon-action, .clickable-metric {
          margin-right: 22px;

          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    .workflows-container{
      padding: 0
    }
    
    #tabs-container{
      .header{
        display: none;
      }

      .dashboard-row{
        .smart_tile{
          margin-left: 20px !important;
        }

        &:first-child{
          border-top: none;
        }

        .right-content {
          display: none!important;
        }
      }
    }
  }
}
