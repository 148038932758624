.workflow-edit {
 .form-title {
  font-size: 12px;
  color: $blue-medium;
  text-transform: uppercase;
 }

 .image-attributes {
   flex-grow: 1;
 }

 .workflow-image {
  border: solid 0.5px $gray-450;
  border-radius: 10px;
 }

 .upload-image {
   margin: 14px 0;
   justify-content: stretch;

   .file-name{
     flex-grow: 1;
     width: inherit!important;
   }

  .MuiFormControl-root {
    margin: 0 !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .MuiOutlinedInput-root {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  button {
    padding: 0 10px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-left: 0px;
    text-transform: unset;
    font-size: 0.87rem;
    font-weight: 600;
    font-family: OpenSans;
    color: $blue;
  }
 }

 .workflow-image {
   margin: 14px 0 14px 30px;
 }
}