.MuiDialog-paperFullWidth{
  padding: 40px  
}
.MuiPaper-rounded {
  border-radius: 10px !important;
}

.MuiDialogTitle-root{
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #0945a5;
  text-transform: uppercase;
  padding-bottom: 0px !important; 
  h2{
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    padding-bottom: 10px !important;     
  }
}
.MuiDialogContent-root{
  padding: 0 24px;
  .MuiDialogTitle-root{
    padding: 16px 0 0px 0;
    h2{
      margin-bottom: 14px;
    }
  }
}
.dialog-actions-container{
  margin-top: 20px;
  border-top: 1px solid #ccc;
  width: 100%;
  padding: '0';
  padding-top: 20px;
  margin-bottom: 14px;
  .MuiButtonBase-root{
    border-radius: 24px;
  }
}

.MuiDialogActions-root{
  padding: 20px 0px !important;
  margin: 0 24px;
  .actions-container{
    border-top: 1px solid #ccc;
    width: 100%;
    padding: '0';
    padding-top: 20px;
    
  }
}


@media (max-width: map-get($grid-breakpoints, 'md')) {
  .MuiDialog-paperFullWidth{
    padding: 20px 0;
    
  }
  .MuiDialog-container{
    margin-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .MuiPaper-rounded {
    border-radius: 0px !important;
  }
  .MuiBackdrop-root {
    opacity: 0 !important;
  }
}