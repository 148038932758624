// Buttons

@mixin button-base($text-color) {
  min-width: 148px;
  color: $text-color;
  border-radius: 4px;
  font-family: $font-family-opensans;
  font-size: 24px;
  height: 50px;

  &:disabled, .disabled {
    box-shadow: none;
    border-color: $gray-600;
    color: $gray-600;
    background-color: $white;
    cursor: not-allowed;
  }
}

@mixin button-variant-filled($text-color, $text-color-hover, $button-color, $button-color-hover, $button-color-active) {
  @include button-base($text-color);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  @include hover {
    &:not(:disabled):not(.disabled) {
      color: $text-color-hover;
      background-color: $button-color-hover;
      border-color: $button-color-hover;
      box-shadow: none;
    }
  }

  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    box-shadow: none;
    color: $text-color-hover;
    background-color: $button-color-active;
    border-color: $button-color-active;
  }
}

@mixin button-variant-outline($color, $color-hover, $color-active) {
  @include button-base($color);

  border-color: $color;
  background-color: $white;
  
  @include hover {
  color: $color-hover;
  border-color: $color-hover;
  background-color: $white;
}

  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    box-shadow: none;
    color: $color-hover;
    border-color: $color-active;
    background-color: $white;
  }
}

// Alert

@mixin rm-alert-variant($color) {
  background-color: rgba($color, .05);
  border-color: $color;

  hr {
    border-top-color: darken($color, 5%);
  }

  .alert-link {
    color: $body-color;
  }
}

// stylelint-disable declaration-no-important

@mixin rm-text-emphasis-variant($parent) {
  #{$parent} {
    color: $gray-200 !important;
  }
  a#{$parent} {
    @include hover-focus {
      color: $link-color-secondary !important;
    }
  }
}

// stylelint-enable declaration-no-important
