.listView{
  .tasks-header .container, .task .container, .empty-state{
    max-width: map-get($grid-breakpoints, 'lg');
    @include make-container();
  }

  .empty-state{
    padding-top: 30px;
  }

  .tasks-header{
    border-bottom: 1px solid $gray-300;

    .container{
      @include task-header;
      @include list-view-header;

      div{
        margin-right: 0;
        padding: 0 0 0 5px;
      }
    }
  }
  
  .tasks ul{
    .task{
      padding: 10px 0;
      @include expanded-tasks;

      color: $gray-500;

      &.group{
        .task-owner{
          color: $gray-500;
        }
      }

      div{
        font-size: 0.87rem;
      }

      .actions-container, .kebab-dropdown{
        svg{
          vertical-align: top;
          width: 16px;
          height: 16px;
        }

        .group-icon{
          width: 16px;
        }
      }

      .kebab-dropdown{
        svg{
          height: 24px;
        }
      }

      .task-inner{
        align-items: center;

        h4{
          font-size: 1rem;
          color: $blue-darker;
        }
        
        h5{
          color: $primary;
          font-size: 0.87rem;
        }
      }

      @media (max-width: map-get($grid-breakpoints, 'md')) {
        margin-right: 0;

        >.container{
          padding: 0
        }

        .actions-container{
          margin-right: 0;
        }
      }
    }
  }
}