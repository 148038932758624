@include media-breakpoint-down(sm) {
  .brandbar {
    .container{
      display: flex;
      justify-content: space-between;
      margin:0;
      padding:0;
      height: 42px;
      align-items: center;
    }

    .brand-logo,
    .brand-claim {
      margin-top: 0px;
      font-size: 0.75rem;
      float: none;
    }

    .brand-logo{
      img{
        height: 21px;
      }
    }

    .brand-claim {
      text-align: right;

      img {
        width: 141px;
      }
    }
  }

  .brandbar,
  .brand-navbar {
    min-height: 42px;
    z-index: 1020;
  }
}

