.table-task-data {
  padding-top: 40px;
  margin-bottom: 0;

  @include media-breakpoint-only(sm) {
    padding-top: 30px;
  }
  .table-title {
    font-size: 18px;
    font-weight: 500;
    color: $black;
    @include media-breakpoint-only(md) {
      font-size: 16px;
    }
    @include media-breakpoint-only(sm) {
      font-size: 15px;
    }
  }

  .tr {
    min-height: 60px;
    .nested-row {
      width: 100%;
      &.key {
        padding-bottom: 5px;
      }
    }
    @include media-breakpoint-only(sm) {
      min-height: 48px;
    }
  }

  // workaround align-items on IE11
  .tr:after {
    content: '';
    min-height: inherit;
    font-size: 0;
  }

  .td {
    vertical-align: middle;
    border-top: none;
    font-size: 18px;
    @include media-breakpoint-only(md) {
      font-size: 16px;
    }
    @include media-breakpoint-only(sm) {
      font-size: 15px;
      width: 111px;
    }

    &.key {
      font-size: 16px;
      font-weight: 500;
      color: $gray-600;
      margin-right: 27px;
      width: 370px;
      align-self: center;

      @include media-breakpoint-only(md) {
        width: 300px;
        font-size: 15px;
      }
      @include media-breakpoint-only(sm) {
        font-size: 12px;
        width: 111px;
        margin-right: 10px;
      }
    }
  }

  @include media-breakpoint-only(sm) {
    .td:first-child {
      margin-right: 10px;
    }
  }

  .download-icon {
    #download_outline {
      path {
        fill: $gray-500;
      }
    }

    &:hover {
      #download_outline {
        path {
          fill: black;
        }
      }
    }
  }
}

.table-striped .table-body .tr:nth-of-type(odd):not(.nested-row){
  background-color: $gray-100;
}

.nested-table-container {

  .td {
    padding: 10px 0;
  }
  .tr {
    min-height: 48px;
    &.key {
      font-size: 16px;
      color: $gray-600;
      font-weight: 500;
      min-height: 37px;
      display: flex;
      align-items: flex-end;
      border-bottom: solid 0.5px $gray-400;
      @include media-breakpoint-only(md) {
        width: 300px;
        font-size: 15px;
      }
      @include media-breakpoint-only(sm) {
        font-size: 12px;
        height: 35px;
      }
    }

    .nested-table {
      .tr:not(:first-child) {
        border-top: solid 0.5px $gray-300;
      }
    }
  }

  // workaround align-items on IE11
  .tr:after {
    content: '';
    min-height: inherit;
    font-size: 0;
  }
}

