.react-admin-layout {
  margin-bottom: -150px;
  height: 100%;

  .MuiGrid-spacing-xs-5{
    width: calc(100% + 20px)!important;

    .MuiGrid-item{
      padding-bottom: 0;
    }
  }

  [class^="RaLayout-contentWithSidebar-"]{
    width: 100vw;
  }

  .RaLayout-appFrame-154 {
    margin-top: 20px;
  }

  .MuiDrawer-root {
    background: url('../images/background-blue-lines.png') center center;
    border-radius: 4px;
    min-height: 100vh;

    svg {
      fill: $gray-300;
    }

    a {
      color: $white;
      font-weight: 600;

      &:hover {
        svg {
          fill: $white;
        }
      }
    }
  }

  .MuiCard-root {
    margin-bottom: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .MuiTable-root,
  .MuiToolbar-root {
    background-color: white;
  }

  h2 {
    font-size: 1.3rem;
    color: $primary;
    text-transform: uppercase;
    margin: 10px 0 30px 0;
  }

  .recharts-wrapper {
    width: 100% !important;
    text-align: center;
  }
}