.grid-slider {
  width: 70px;

  .rc-slider{
    height: auto;
    
    .rc-slider-rail {
      height: 1px;
      background-color: $gray-500;

      @media screen and (-ms-high-contrast: active) {
        border: 1px solid;
      }

    }
  
    .rc-slider-handle {
      border: solid 2px black;
      height: 10px;
      width: 10px;
  
      &:active {
        box-shadow: 0 0 1px black;
      }

      &:focus {
        border-color: $black;
        box-shadow: 0 0 0 0px $black;
        outline: none;
      }
    }
  
    .rc-slider-track {
      background-color: black;
      height: 2px;
      margin-top: -1px;
    }
  }
}
