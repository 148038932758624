@mixin arrow-icons($size) {
  display: inline-block;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  width: $size;
  height: $size;
  float: right;

  &.expanded {
    background-image: url('/images/arrow_up.svg');
  }

  &.collapsed {
    background-image: url('/images/arrow_down.svg');
  }
}

@mixin icons-with-active($normal, $active, $hover) {
  fill: $normal;

  svg g {
    stroke: $normal;
  }
   
  &.active {
    fill: $active;

    @media screen and (-ms-high-contrast: active) {
      svg path {
        fill: none;
        stroke: $active;
        stroke-width: 0.5;
      }
    }

    svg g {
      stroke: $active;
    }
  }

  &:hover {
    fill: $hover;

    @media screen and (-ms-high-contrast: active) {
      svg path {
        fill: none;
        stroke: $active;
        stroke-width: 0.5;
      }
    }


    svg g{
      stroke: $hover;
    }
  }
}
