.listView{
  margin: 0;
  padding: 0;
  max-width: 100%;

  >.row{
    padding: 0;
  }

  .main-tabs-container{
    padding-top: 18px;
    background: $gray-100;
    border-bottom: none;

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      padding-top: 0;

      .nav-link{
        margin-top: 5px;
      }
    }

    .header{
      display: flex;
      align-items: center;

      .main-underline-tabs {
        background: none;

        .nav-link{
          padding-top: 0;
          padding-bottom: 18px;
          
          &.active{
            background: none;
            color: $blue;
            border-bottom: 1px solid $blue!important;
            font-weight: 600;
            text-shadow: none;
          }

          &:hover{
            border-bottom: 1px solid $gray-500;
          }
        }
      }

      .filters-container{
        padding-top: 0;
        color: $gray-600;

        .filter-value.selected{
          color: $primary;
          font-weight: 300;
        }
      }
    }
  }
}
