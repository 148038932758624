.integration-item{
  width: 276px;
  height: 129px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-right: 30px;
  border-radius: 15px 15px 0px 15px;
  
  .integration-header{
    width: 100%;
    height: 90px;
    background: #f8f8f8;
    border-radius: 15px 15px 0px 0px;
    
    &.row{
      margin-bottom: 0px !important;
    }
    &_logo{
      padding: 10px 20px 0 20px;
      &-container{
        padding: 5px;
        width: 70px;
        height: 70px;
        border-radius: 40px;
        background: white;
        img{
          margin: 10px;
          &.installed{
            filter: grayscale(100%);
          }
        }
      }    
    }
    &_title{
      padding: 24px 0 0 10px;
      &.support{
        margin: 0 10px 0 10px;
      }
    }
    .title-main{
      font-size: 16px;
      font-weight: bold;
      color: #212f46;
      text-transform: uppercase;
    }
    .title-sub{
      font-size: 14px;
      font-weight: normal;
      color: #6c6c6c;
    }
  }
  .integration-content{
    height: 44px;
    line-height: 2;
    font-size: 14px;
    font-weight: bold;  
    &-link{
      text-transform: uppercase;
      color: #246ee3;
      float: right;
      line-height: 44px;
      margin-right: 10px;
      padding: 0px;
      background: transparent;
      border: 0;
      text-transform: uppercase;
      &:hover{
        text-decoration: underline;
      }
    }
        
  } 
}

@include media-breakpoint-down(sm) {
  .integration-item { 
    width: 100%;
    margin-right: 0;  
    margin-bottom: 20px;    
  }
}
