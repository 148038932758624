.error-page{
  padding: 100px 170px 0 170px;
  text-align: left;

  h1{
    font-family: 'OpenSans';
    font-size: 2.25rem;
    text-align: left;
  }

  p{
    text-align: left;
    font-weight: 500;
    font-size: 1.5rem;
  }

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    padding: 100px 152px 0 152px;
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    padding: 30px 15px 0;

    h1{
      font-size: 1.5rem;
    }

    p{
      font-size: 1.12rem;
    }

    a{
      font-size: 1.12rem;
      width: 100%;
      height: auto;
      padding:13px 0 18px 0;
    }
  }
}