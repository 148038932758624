.workflow-box-container {
  position: relative;
  z-index: 0;
  width: calc(200% + 8px);

  .workflow-elements-container:nth-of-type(odd) & {
    left: 0;
  }

  .workflow-elements-container:nth-of-type(even) & {
    left: calc(-100% - 8px);
  }

  .workflow-box-inner {
    position: relative;
    background: $gray-200;
    padding: 0;
    left: 0;

    .layout-3-cols & {
      margin: -18px 0 14px;
    }

    .layout-4-cols & {
      margin: -18px 0 30px;
    }
  }

  @media (min-width: map-get($grid-breakpoints, "md")) {
    & {
      .layout-3-cols & {
        width: calc(300% + 48px);
      }

      .layout-4-cols & {
        width: calc(300% + 16px);
      }
    }

    .workflow-elements-container:nth-of-type(3n + 1) & {
      left: 0;
    }

    .workflow-elements-container:nth-of-type(3n + 2) & {
      .layout-3-cols & {
        left: calc(-100% - 24px);
      }

      .layout-4-cols & {
        left: calc(-100% - 8px);
      }
    }

    .workflow-elements-container:nth-of-type(3n + 3) & {
      .layout-3-cols & {
        left: calc(-200% - 48px);
      }

      .layout-4-cols & {
        left: calc(-200% - 16px);
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    .layout-3-cols & {
      width: calc(300% + 48px);
    }


    .layout-3-cols .workflow-elements-container:nth-of-type(3n + 1) & {
      left: 0;
    }

    .layout-3-cols .workflow-elements-container:nth-of-type(3n + 2) & {
      left: calc(-100% - 24px);
    }

    .layout-3-cols .workflow-elements-container:nth-of-type(3n + 3) & {
      left: calc(-200% - 48px);
    }
  }

  .layout-4-cols & {
    width: calc(400% + 24px);
  }

  .layout-4-cols .workflow-elements-container:nth-of-type(4n + 1) & {
    left: 0;
  }

  .layout-4-cols .workflow-elements-container:nth-of-type(4n + 2) & {
    left: calc(-100% - 8px) !important;
  }

  .layout-4-cols .workflow-elements-container:nth-of-type(4n + 3) & {
    left: calc(-200% - 16px) !important;
  }

  .layout-4-cols .workflow-elements-container:nth-of-type(4n + 4) & {
    left: calc(-300% - 24px) !important;
  }
}

.box-header {
  border-bottom: 2px solid $white;
  padding-top: 5px;

  .btn-toggle-description {
    float: right;

    &:hover {
      cursor: pointer;
    }
  }

  .magenta {
    color: $magenta;
  }
}


.box-sub-header {
  .col:nth-child(1) {
    border-right: 2px solid $white;
  }

  h6 {
    padding-top: 5px;
    font-size: .9em;
  }
}

.box-content {
  background-color: $gray-100;

  .col:nth-child(1) {
    border-right: 2px solid $white;
  }

  &.description {
    .col:nth-child(1) {
      border-right: none;
    }

    .collapse {
      width: 100%;
    }
  }
}

.box-actions {
  margin: 0;
  margin-top: 10px;

  .box-link {
    .icon {
      margin-right: 4px;
    }
  }

  .description & {
    padding: 5px 0 15px;
  }
}

ul.box-notification-list {
  margin: 0;
  margin-top: 10px;
  padding-left: 19px;
  font-size: .9em;

  li {
    padding-left: 0;
    margin-bottom: 5px;
  }
}

.box-aufgaben-list {
  min-height: 200px;
  max-height: 200px;
  padding-bottom: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 5px;
}
