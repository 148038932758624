.layout-switch{
  background: $white;
  position: fixed;
  right: 0;
  top: 100px;
  border: 1px solid $gray-350;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 999;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);

  button{
    background: transparent!important;
    border: none;
    padding: 5px;
    outline: none !important;
    box-shadow: none !important;

    svg, g{
      fill: $gray-350;

      &:hover{
        * { fill: $primary }
      }
    }

    &.active{
      svg {
        * { fill: $gray-500 }
      }
    }
  }
}