$badge-height: 23px;

%basic-badge {
  border: 0;
  font-weight: 500;
  height: $badge-height;
  min-width: $badge-height;
  text-align: center;
  border-radius: 50%;

  @include media-breakpoint-down(sm) {
    min-width: 13px;
    height: 13px;
  }
}

.badge {
  @extend %basic-badge;

  font-size: 1em;
  color: $gray-600;
  line-height: $badge-height - $badge-height/2 + 2px;
  background-color: $gray-100;

  &.badge-primary{
    color: white;
    background-color: $primary;
  }
}
