// Alert

.alert {
  color: $body-color;
}

@each $color, $value in $alert-colors {
  .alert-#{$color} {
    @include rm-alert-variant($value);
  }
}
