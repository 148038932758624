@mixin hover-focus-style {
  .smart_tile_actions_wrapper {
    .action, .icon-action{
      visibility: visible;
    }
  }

  .smart_tile_info {
    .smart_tile_name {
      color: $primary;
    }
  }

  &:not(.restricted){
    .tile-gradient{
      display: block;
    }
  }
}

.smart_tile {
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  button {
    border: none; // fix for IE11
  }

  .clickable-metric{
    padding: 0;
  }

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    @include transition(all, 0.4s, ease-out);
    
    &:hover {
      box-shadow: 0 6px 12px 0 rgba(124, 124, 124, 0.16);
      transform: translate(0px, -4px);

      .smart_tile_actions_wrapper{
        background: $white;
      }
    }
  }

  .card-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .tile-gradient{
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    opacity: 0.75;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 9%, rgba(32, 32, 32, 0.5) 62%, $gray-800);
  }

  &:hover {
    @include hover-focus-style;
  }

  // https://github.com/matteobad/focus-within-polyfill (For IE11)
  &.focus-within {
    @include hover-focus-style;
  }

  &:focus-within {
    @include hover-focus-style;
  }

  &.layout-2 {
    margin-bottom: 30px;

    .smart_tile_info {
      .smart_tile_name {
        font-size: $font-size-lg;
      }
    }

    .smart_tile_img {
      height: 268px;
    }

    .icon_wrapper {
      width: 60px;
      height: 60px;
      border-radius: 50px;

      &.smart_tile_actions_counter {
        div {
          font-size: $font-size-lg;
          font-weight: $font-weight-bold;
          text-overflow: ellipsis;
        }
      }

      svg {
        height: 32px;
        width: 32px;
      }
    }

    width: 400px;
    height: 377px;

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      width: 138px;
      height: 130px;
      margin: 6px!important;

      .smart_tile_info{
        min-height: 40px;

        .smart_tile_name,.smart_tile_category{
          font-size: 0.75rem!important;
        }
      }

      .smart_tile_img {
        height: 90px;
      }

      .smart_tile_actions{
        .smart_tile_actions_counter, .icon-counter{
          div{font-size: 0.75rem;}
        }

        .icon_wrapper, .icon-action{
          width: 25px;
          height: 25px;

          svg{
            width: 11px;
            height: 11px;
          }
        }
      }
    }
  }

  &.layout-3 {
    margin-bottom: 20px;

    .smart_tile_img {
      height: 195px;
    }

    width: 270px;
    height: 255px;

    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      width: 200px;
      height: 200px;

      margin: 12px!important;

      .smart_tile_img {
        height: 137px;
      }

      .icon_wrapper, .icon-action{
        width: 40px;
        height: 40px;

        svg{
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &.layout-4 {
    margin-bottom: 10px;

    .smart_tile_info {
      .smart_tile_name {
        font-size: $font-size-sm !important;
      }
    }

    .icon_wrapper, .icon-action {
      width: 32px;
      height: 32px;
      border-radius: 50px;

      &.smart_tile_actions_counter, &.icon-counter {
        div {
          font-size: $font-size-sm;
          font-weight: $font-weight-bold;
          text-overflow: ellipsis;
        }
      }

      svg {
        height: 16px;
        width: 16px;
      }
    }

    .smart_tile_img {
      height: 134px;
    }

    width: 200px;
    height: 189px;
  }

  a {
    text-decoration: none;
  }

  box-shadow: 0 3px 6px 0 rgba(124, 124, 124, 0.16);
  background-color: $white;

  .smart_tile_img {
    position: relative;
  }

  .smart_tile_actions_wrapper {
    position: absolute;
    top: -62px;
    left: 0;
    padding: 15px;
    width: 100%;
    z-index: 10;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);

    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      top: -72px;
      padding: 15px 5px;
    }

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      top: -55px;
    }

    .action, .icon-action{
      visibility: hidden;

      &.favorite{
        visibility: visible;
      }
    }
  }

  .smart_tile_info {
    padding: 9px 15px;
    min-height: 60px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    z-index: 99;
    bottom: 0;
    position: absolute;
    width: 100%;
    background: white;

    .smart_tile_name {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
      color: $primary;
    }

    .smart_tile_category {
      font-size: 1rem;
      font-weight: $font-weight-normal;
      color: $gray-600;
    }
  }
}