.anticon{
  &:hover{
    opacity: 1!important;

    svg{
      fill: $blue-dark;
    }
  }

  svg path {
    transform: translate(64px, 64px);
  }

  &.anticon-setting svg path {
    d: path('M792.574,493.9A339.785,339.785,0,0,0,795.8,450a339.786,339.786,0,0,0-3.224-43.9l97.18-73.92a22.188,22.188,0,0,0,5.527-28.672L803.167,148.5c-5.527-9.856-17.962-13.44-28.094-9.856l-114.681,44.8a338.885,338.885,0,0,0-77.836-43.9l-17.5-118.72C563.672,10.064,554,2,542.486,2H358.259c-11.514,0-21.186,8.064-22.568,18.816l-17.5,118.72c-28.095,11.2-53.886,26.432-77.836,43.9l-114.681-44.8A22.761,22.761,0,0,0,97.577,148.5L5.464,303.5a21.689,21.689,0,0,0,5.527,28.672l97.18,73.92a345.766,345.766,0,0,0-3.224,43.9,345.763,345.763,0,0,0,3.224,43.9l-97.18,73.92A22.188,22.188,0,0,0,5.464,596.5L97.577,751.5c5.527,9.856,17.962,13.44,28.095,9.856l114.681-44.8a338.891,338.891,0,0,0,77.836,43.9l17.5,118.72C337.073,889.936,346.744,898,358.259,898H542.486c11.514,0,21.186-8.064,22.568-18.816l17.5-118.72c28.095-11.2,53.887-26.432,77.836-43.9l114.681,44.8a22.761,22.761,0,0,0,28.094-9.856L895.281,596.5a22.188,22.188,0,0,0-5.527-28.672Zm-342.2,112.9c-88.89,0-161.2-70.336-161.2-156.8s72.309-156.8,161.2-156.8,161.2,70.336,161.2,156.8S539.262,606.8,450.372,606.8Z');
  }

  &.anticon-plus svg path {
    d: path('M901,517H517V901H389V517H5V389H389V5H517V389H901Z')
  }

  &.anticon-close {
    line-height: 0!important;

    &::before {
      display: none!important;
    }

    svg path{
      d: path('M745.191,79.973l-92.8-92.8L376.573,263,96.878-13.794,5,78.084,283.244,356.328,5.214,634.358l92.8,92.8,278.03-278.03,277.54,277.54,93.329-93.329L469.368,355.8Z');
      transform: translate(64px, 164px);
    }
  }

  &.anticon-caret-down svg path{
    d: path('M909.68,8.59,518,399.417,126.32,8.59,6,128.91l512,512,512-512Z')
  }

  &.anticon-caret-right svg path{
    d: path('M10,1031.045,522.022,519.023,10,7Z')
  }

  &.ant-select-arrow-icon svg {
    width: 12px;
    height: 10px;

    path{
      d: path('M111-20,623,492,1135-20Z');
      transform: none;
    }
  }
}
