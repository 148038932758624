.main-templates-container,
.main-workflows-container,
.header-groups-container{
  margin-bottom: 40px;
  
  h2{
    font-size: 0.87rem;
    text-transform: uppercase;
    color: $gray-450;
    font-weight: 600;
    padding: 22px 0;
    border-bottom: 1px solid $gray-100;
    margin-bottom: 30px;
    background-color: $gray-100;

    @media (max-width: map-get($grid-breakpoints, 'md')){
      margin-bottom: 10px;
    }
  }

  .container-header{
    width: 100%;
    border-bottom: solid 0.5px $gray-300;
    margin-bottom: 20px;
    color: $gray-450;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    h3{
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 600;
      display: inline-block;
      margin: 0 10px 0 0;
      
    }

    .description{
      font-size: 0.9rem;
    }

    .btn{
      margin-left: 30px;
    }

    @media (max-width: map-get($grid-breakpoints, 'md')){
      flex-direction: column;

      h3{
        width: 100%;
        margin: 0 0 10px 0;
      }

      .btn{
        margin: 20px 0 10px 0;
      }
    }
  }

  .empty-state{
    margin-top: 30px;

    .title{
      font-size: 0.9rem;
    }
  }
}

.dashboard-row{
  margin: 0;
}

.workflows-container.listView{
  .main-templates-container, .main-workflows-container{
    >.container{
      max-width: 100%;
      padding: 0;
      margin: 0;
      width: 100%;
      border-bottom: solid 0.5px $gray-300;

      .container-header{
        max-width: map-get($grid-breakpoints, 'lg');
        @include make-container();
        padding: 10px 15px;
        border: 0;
        margin-bottom: 0;
      }
    }
  }
}