@mixin tasks-columns {
  @include make-col(2);
  padding:0.5rem;

  &.small-column{
    @include make-col(1);
  }

  &.task-inner{
    @include make-col(4)
  }
}

@mixin task-header{
  padding-bottom: 0.5rem;
  color: $gray-600;
  font-size: 1rem;
  align-items: flex-end;
  display: flex;
  justify-content: space-between;

  &.mobile{
    > div{
      display: none!important;

      &.task-inner{
        display: block!important;
      }
    }
  }

  >div{
    @include tasks-columns;

    &:last-child{
      padding-right: 0;
    }
  }
}

@mixin expanded-tasks {
  padding: 20.4px 0;

  .container{
    display: flex;
    justify-content: space-between;

    >*{
      &:not(.mobile){
        @include tasks-columns;
      }
    }
  }

  >div{
    font-size: 1rem;
  }

  .more-options{
    padding-right: 17px;
  }

  .actions-container{
    margin-right: 1.5rem;
  }
}

.back-expanded{
  background: none;
  border: none;
  padding: 0;
}

.row{
  position: relative;

  .sidebar {
    @include transition(all, 0.5s); //TODO: check how collapsing transition can be improved

    .sidebar-header {
      border-bottom: 1px solid $gray-300;
      padding-top: $navbar-padding-y;

      .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &:not(.expanded){
      .sidebar-header, .tasks {
        .container{
          padding: 0;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .filters-container{
      @media (min-width: map-get($grid-breakpoints, 'md')) {
        justify-content: flex-start;

        .filter {
          margin-left: unset;
        }
      }
    }

    .overview-content {
      padding: 40px 0;
    }

    .sidebar-icon {
      cursor: pointer;
      background: none;
      border: none
    }

    .sidebar-title {
      text-transform: uppercase;
      line-height: $line-height-base;
      font-size: 1.1em;
      font-weight: 500;
      color: $gray-700;
      padding: 0;
      margin: 0;
      flex-grow: 1;
      padding-bottom: 0.1rem;

      .count{
        color: $gray-500
      }
    }

    .sidebar-buttons{
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
    }

    .sidebar-links{
      flex-grow: 2;
      font-size: 1rem;

      @media (max-width: map-get($grid-breakpoints, 'md')) {
        display: none!important;
      }
    }

    .tasks {
      overflow: visible;

      @include media-breakpoint-up(sm) {
        overflow-y: auto;
      }

      .tasks-header{
        border-bottom: 1px solid $gray-300;

        .container{
          @include task-header;
        }
      }

      &.notifications{
        .tasks-header{
          .container{
            margin-top: 0;

            >div:last-child{
              text-align: right;
              padding-right: 0.5rem;
            }
          }

          ul li{
            >:not(:first-child){
              text-align: left;
            }
          }
        }
      }
    }

    &.expanded{
      background-color: white;
      max-width: 100%;
      width: 100%;
      padding:0 15px 0 15px;

      .sidebar-header{
        margin-top: 40px;
      }

      .sidebar-buttons{
        justify-content: flex-start;
      }

      .tasks{
        .task{
          @include expanded-tasks;
        }
      }
    }
  }
}
