.Toastify__toast-container{
  width: auto!important;
  z-index: 99999!important;

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    width: calc(100% - 20px)!important;
  }
}

.Toastify__toast-container--top-right{
  top: 115px!important;
  right: 55px!important;

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    top: 193px!important;
    right: 31px!important;
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    top: 10px!important;
    right: 10px!important;
    left: 10px!important;
  }
}

.Toastify__toast.toast-message {
  min-height: 40px;
  min-width: 270px;
  max-width: 470px;
  font-size: .85em;
  background: $gray-200;
  padding: 0;

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    width: 100%;
    min-height: 36px!important;
  }

  .toast-text {
    align-self: center;
    margin: -0.2rem 15px 0 15px;
    overflow: hidden;
    font-weight: bold;
    font-size: 1.12rem;
    padding: 10px 0;
    max-width: 354px;

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      font-size: 0.87rem;
    }
  }

  .Toastify__toast-body {
    display: flex;
    margin: 0;
  }

  &.Toastify__toast {
    min-height: 48px;
    border-radius: 4px;
  }

  &.Toastify__toast--success {
    background: $white;
    color: $green;
    border: 1px solid $green;

    svg{
      path{
        fill: $green;
        width: 24px;
      }
    }
  }

  &.Toastify__toast--error {
    background: $white;
    color: $red;
    border: 1px solid $red;

    svg{
      path{
        fill: $red;
        width: 24px;
      }
    }
  }

  .toast-icon {
    display: inline-block;
    width: 62px;
    min-height: 48px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #fff;

    &.success{
      width: 56px;
    }

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      min-height: 36px!important;

      &.success{
        width: 45px!important;
      }
      
      svg{
        width: 16px!important;
      }
    }
  }

  .Toastify__close-button{
    margin: auto 16px;
    padding-top: 0;
    text-indent: -9999px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    opacity: 1;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M15.81,14.99l-6.99-7l6.99-7c0.24-0.24,0.2-0.63-0.04-0.83c-0.24-0.2-0.59-0.2-0.79,0l-6.99,7l-6.99-7 C0.75-0.08,0.36-0.04,0.16,0.2c-0.2,0.24-0.2,0.59,0,0.79l6.99,7l-6.99,7c-0.24,0.24-0.2,0.63,0.04,0.83c0.24,0.2,0.59,0.2,0.79,0 l6.99-7l6.99,7c0.24,19.24,0.59,0.24,0.83,0.04C16.04,15.66,16.08,15.26,15.81,14.99C15.85,15.03,15.81,15.03,15.81,14.99z' fill='192130'/%3E%3C/svg%3E");
  }
}
