.tile {
  border: 1px solid white;
  border-radius: 0;
  width: 100%;

  & ~ .triangle {
    @include triangle(-17px);
  }

  &:not(.restricted) {
    cursor: pointer;

    &:not(.tool) {
      &.open,
      &:hover {
        text-decoration: none;

        .card-title {
          color: $primary;
        }

        .badge {
          background-color: $primary;
        }
      }
    }

    .tool-link {
      &:hover {
        text-decoration: none;

        .card-title {
          color: $primary;
        }

        .badge {
          background-color: $primary;
        }
      }
    }
  }

  &.open {
    margin-bottom: 0;

    .card-title {
      color: $primary;
    }

    .badge {
      background-color: $primary;
    }
  }

  .card-status-icon {
    float: right;
    position: relative;
    width: 7px;
    height: 7px;
    right: .4rem;
    clear: both;
    background-position: center;
    background-size: contain;
  }

  .card-content {
    $badge-height: 25px;

    .badge {
      float: right;
      position: absolute;
      font-size: .75em;
      right: 10px;
      top: 151px;
      height: $badge-height;
      min-width: $badge-height;
      line-height: $badge-height / 2;
      text-align: center;
      font-weight: 500;
      background-color: $gray-600;
      border: 2px solid $gray-200;
      color: $white;
      border-radius: 50px;
    }

    .card-title,
    .card-subtitle {
      color: $gray-900;
      font-size: 11px;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
    .card-title {
      font-size: 12px;
      text-transform: uppercase;
      line-height: 1.7;
      letter-spacing: 0.2px;
      color: #707070;
    }
  }

  .card-footer {
    padding: 0;
    background-color: $gray-200;
    border-top: 2px solid white;

    .icon {
      vertical-align: text-bottom
    }
  }

  @media (min-width: map-get($grid-breakpoints, "sm")) {
    & ~.triangle {
      left: calc(6rem - 17px);
    }
  }

  .favorite {
    width: 27px;
    height: 27px;
    top: 115px;
    right: 10px;
    background: $gray-200;
    line-height: 27px;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    svg {
      margin: 0 auto;
      stroke: $gray-500;
      height: 12px;
      width: 12px;
      stroke-width: 0.6px;
    }

    &:hover {
      svg path {
        fill: $primary;
        stroke: $primary;
      }
    }

    &.active {
      svg path {
        stroke: $primary;
        fill: $primary;
      }

      &:hover {
        background: $primary;

        svg path {
          stroke: $white;
          fill: $white;
        }
      }
    }
  }
}

.workflow-elements-container {
  .icon {
    max-height: 1em;
    max-width: 1em;
  }
}
img.lock-icon{

  width: 25px;
}