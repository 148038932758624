$primary-color: #246ee3;
$prinary-color-text: #0945a5;

$primary-color: #246ee3;
$prinary-color-text: #0945a5;

.header-groups-container{     
  font-family: inherit;
  .container-header{
    p{
      text-align: center;
      margin-bottom: 0;
      line-height: 42px;
    }
  }
  
  h2,
  .description{
    font-size: 20px;
    font-weight: 600;
    color: $prinary-color-text;
    text-transform: uppercase;
  }
  h4{
    font-size: 20px;
    font-weight: 600;
    color: $prinary-color-text;
    text-transform: uppercase;
  }
  .actionButtons {
    display: flex;
    justify-content: flex-end;
  }

  .MuiButton-root {  
    border-radius: 24px !important;
  }
  
  .MuiIconButton-root{
    padding: 0 9px;
    margin-left: 30px;
  }
  .MuiIconButton-colorSecondary{
    background: $primary-color;
    color: white;
    svg {
      padding: 1px;
    }
      

    &:hover{
      background: darken($primary-color, 10%) 
    }
  }

}

.MuiDataGrid-cellWithRenderer{
  flex-direction: row-reverse;
  align-items: end;  
}

.group_avatar {
  border-radius: 60px;  
  padding: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  margin: 0;
  font-weight: 700;
  line-height: 30px;
  font-size: 70%;
  &.light {
    color: #444 
  }
  &.dark {
    color: #eee
  }
}

.MuiInputBase-root {
  .group_avatar {
    width: 52px;
  }
}

.group-show{
  margin-bottom: 60px;
  .MuiGrid-spacing-xs-3{
    margin: 0;
    
    .MuiGrid-item{
      padding: 0;
      &:nth-child(2){
        padding: 0 24px;
      }
    }
  }
}

.table-show-link{
  opacity: 1;
  color: #8b8f97;
  svg {
    transform: scaleX(-1);
    background-color: rgb(227, 228, 229);
    border-radius: 32px;
    padding: 5px;
    color: #8b8f97;
    width: 30px;
    height: 30px;
  }
}

.MuiMenuItem-root {
  display: flex !important;
  justify-content: stretch;
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
  .header-groups-container{
    margin-top: 0px;
    margin-bottom: 30px;
    .container-header{
      p{
        text-align: center;
        margin-bottom: 0;
        line-height: 42px;
      }
      h4 {
        line-height: 2;
      }
      .actionButtons {                
        justify-content: center;
        margin: 30px 0
      }
    }
    .MuiIconButton-root {
      margin-left: 4px;
    }
  }
  .group_details_column{
    margin-bottom: 30px !important;
  }
  .table-show-link{
    svg {
      transform: scaleX(-1);
      background-color: #f4f4f4;
      border-radius: 32px;
      padding: 6px;
      color: #8b8f97;
      width: 30px;
      height: 30px;
    }
  }
  .group_avatar {
    width: 30px;
    height: 30px;
    line-height: 32px;
    font-size: 90%;
  }
  .group-show{
    margin-bottom: 0px;
  }
  .MuiInputBase-root {
    .group_avatar {
      width: 36px;
    }
  }
}