//
// Search
// --------------------------------------------------

.search {
  display: none;
  &.in {
    display: block;
  }
}

.search-form-set {
  position: relative;
  .form-control {
    padding-right: 29px;
    &::-ms-clear {
      display: none;
    }
  }
  .close {
    position: absolute;
    top: 7px;
    right: 8px;
    z-index: 5; // set z-index at least to 5
    width: 20px;
    height: 20px;
    padding: 0;
    overflow: hidden;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    background: transparent;
    border: 0;
    // -webkit-appearance: none;
    &:hover,
    &:focus {
      text-decoration: none;
      cursor: pointer;
    }
  }
  &.in .close {
    display: block;
  }
}

.navbar-search-item {

  .form-icon {
    font-size: 24px;
  }

  > .navbar-search {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 3;
    padding-top: 19px;
    padding-right: 12px;
    padding-left: 12px;
  }
}

.navbar-search-item-regular {
  @include media-breakpoint-down(sm) {
    > .navbar-search {
      background-color:$white;
      &.slide {
        width: auto;
      }
      .close {
        font-size: 24px;
      }
    }
    .input-group > .form-control {
      border: 0;
      border-radius: 6px;
    }
  }

  @include media-breakpoint-up(md) {
    // stylelint-disable declaration-no-important
    position: relative !important;
    // stylelint-enable declaration-no-important
    > .navbar-search {
      left: auto;
      width: building-units(30);
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
      &.slide {
        width: 0;
        &.in {
          width: building-units(30);
        }
      }
    }
    .input-group > .form-control {
      border-radius: .25rem;
    }
    .slide {
      .form-control {
        transition: padding 150ms cubic-bezier(.6, 0, .7, .2);
      }
      &:not(.in) {
        .form-control {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
}

.navbar-search-item-block {
  .navbar-form {
    margin-right: auto;
    margin-left: auto;
  }

  > .navbar-search {
    width: 100%;
    background-color:$white;
    .close {
      font-size: 24px;
    }
  }

  .input-group > .form-control {
    border: 0;
  }
}

.navbar-left {
  float: left;
}

.navbar-right {
  float: right;
}
