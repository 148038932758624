.modal {
  .modal-content{
    padding: 20px;
    border-radius: 10px;
    .modal-header {
      border-bottom: 0 none;
      .modal-title {
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: normal;
        text-align: left;
        color: #0945a5;
        text-transform: uppercase;
      }
      button.close {
        display: none;
      }
    }
    .modal-body {
      font-size: 14px;
      padding: 0 1rem  0  1rem;
      hr{
        margin-top: 60px;
      }
    }
    .modal-footer{
      border: 0 none;
      padding-top: 0;
      justify-content: space-between;
      button{
        text-transform: uppercase;
        font-size: 14px;
        &.cancel{
          float: 'left';
          background: 'transparent';
          color: #246ee3;
          padding: 0;
          border: 0 none;
        }
      }
    }
  }
  
}

@include media-breakpoint-down(sm) {
  .modal { 
    
    
    .modal-dialog{
      margin: 70px 0.75rem;
      padding:0;
    }
    .modal-footer{     
      button{     
        font-size: 14px;
      }
    }
  }
}