.smart_tile_actions_wrapper{
  .smart_tile_actions, .icons {
    display: flex;
    flex: 0 0 1;
    flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .external-link {
      stroke-width: 1;

      svg path {
        fill: $gray-450;
        stroke: none;

        &.empty{
          stroke: none!important;
          fill: none!important;
        }
      }
    }

    .clickable-metric{
      padding: 0
    }

    .icon_wrapper, .icon-action {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      svg{
        height: 21px;
      }
    }

    .smart_tile_actions_info,
    .smart_tile_actions_link,
    .smart_tile_actions_favorite,
    .icon-action {

      @media screen and (-ms-high-contrast: active) {
        svg path{
          fill: $white;
          stroke: $white;
        }
      }

      &.icon-play{
        svg {
          margin-left: 2px;
        }
      }

      &:hover {
        svg path{
          fill: $primary;
          stroke: $primary;
        }
      }

      fill: black;
      stroke: black;

      &.info-action{
        stroke: none;
      }

      &:hover {
        &.info-action{
          g{
            fill: none;
          }
        }
      }
    }
  }
}
