.project-management{
  [class^="RaLayout-content-"]{
    padding: 10px 10px 30px 10px;
    background-color: white;
    overflow-x: scroll;
    overflow-y: hidden;

    .MuiButton-root{
      background-color: $gray-300;
      font-weight: 600;
      font-family: OpenSans;
      color: $white;
      padding: 5px 10px;

      &:hover{
        background-color: $gray-400;
      }
    }
  }

  .MuiCard-root {
    background: url('../images/background-white-lines.png') center center;
  }

  .react-trello-board{
    padding: 0;
    background-color: transparent;
    height: inherit;

    section{
      margin: 0;
      margin-right: 15px;
    }
  }

  .react-trello-lane{
    background-color: $gray-200;
  }

  .react-trello-card{
    &:hover{
      background-color: $gray-100;
    }

    .sc-fzpans{
      color: $primary
    }
  }
}