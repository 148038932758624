.task-inner-div{
  width: 100%;
}

.task {
  border-bottom: 1px solid $gray-300;
  padding: 15px 0;

  >.container{
    padding: 0;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    padding: 1.5rem 0!important;
  }

  &.task-highlighted {
    border-bottom-color: $gray-250;

    .task-inner {
      margin-top: 5px;
      text-align: left;
      border: 0;
      padding: 0;
      background: transparent;
      background-color: $gray-200;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .task-inner {
    display: flex;
    transition: margin-left .1s ease-in-out;

    &.just-in {
      margin-left: -100%;
    }
  }

  a {
    text-decoration: none;
  }

  .task-name {
    color: $black;
    font-size: 18px;
    margin-bottom: 2px;
  }

  .task-process-name {
    color: $gray-600;
    margin-bottom: .4rem;
    font-size: 16px;
    margin-bottom: 0;
  }

  .task-info {
    color: $gray-600;
    font-size: 16px;
  }

  &.group {
    float: none !important;
    .task-info,.task-owner {
      color: $primary;
    }
  }

  &.notification{
    .task-status{
      text-align: right;
    }
  }

  .task-menu {
    margin-left: auto;
  }

  &:hover {
    .task-name {
      span {
        color: $primary;
      }
    }

    .task-process-name, .task-info{
      color: $black;
    }

    .kebab-dropdown, .group-icon {
      svg {
        fill: $black!important;

        @media screen and (-ms-high-contrast: active) {
          fill: $white;
        }

        &:hover{
          fill: $primary!important;
        }
      }
    }

    .favorite {
      svg g g {

        path {
          stroke: $black!important;

          @media screen and (-ms-high-contrast: active) {
            stroke: $white;
          }
        }
      }

      &.active {
        svg g {
          path {
          fill: $black!important;

            @media screen and (-ms-high-contrast: active) {
              fill: $white;
            }
          }
        }
      }
    }
  }

  .task-details {
    padding: 10px;
    display: block;
    width: 100%;

    &.disabled-link {
      pointer-events: none;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .task-details {
    padding: 0;
    display: block;
    width: 100%;
    position: relative;

    &.disabled-link {
      pointer-events: none;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .task-menu {
    margin-left: auto;
  }

  .actions-container {
    .kebab-dropdown {
      margin-bottom: 4px;
    }

    .group-icon {
      width: 40px;
      height: 24px;
      cursor: default;

      svg {
        fill: $gray-500;
      }
    }

    .favorite {
      cursor: pointer;

      svg {
        width: 32px;
        height: 24px;

        g{
          g{
            path {
              stroke: $gray-500;
            }
          }
        }

        .active {
          path g {
            fill: orange;
          }
        }
      }

      &.active {
        svg g {
          path {
            fill: $gray-500;
          }
        }
      }

      &:hover {
        svg g g path {
          stroke: $primary!important;
        }
        
        &.active {
          svg g {
            path {
              fill: $primary!important;
            }
          }
        }
      }
    }
  }

  .task-icon{
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      margin-right: 1.5rem;

      .active {
        svg g{
          path {
            fill: $primary;
          }
        }
      }
    }

    svg{
      width: 1.5rem;
    }
  }

  .bell-icon{
    svg{
      g {
        g{
          path {
            fill: $primary;
            @media screen and (-ms-high-contrast: active) {
              fill: $white;
            }
          }
        }
      }
    }
  }
}
