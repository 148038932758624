  .task-info-container {
    .task-details-section-title {
      margin-bottom: $spacer;
    }

    .task-details-section-text {
      margin-bottom: 0;
    }

    h2 {
      font-size: 24px;
      color: $black;
      font-weight: $font-weight-bold;
    }

    h5, p {
      color: $gray-600;
    }

    @include media-breakpoint-down(sm) {
      .task-details-section-title {
        margin-top: 0;
        margin-bottom: 0;
      }

      h2, h5, p {
        font-size: 15px;
      }

      h5 {
        margin: 0;
      }
    }
  }
