label {
  &.is-invalid {
    color: map-get($alert-colors, "danger");
  }

  &.form-check-label a, &.custom-control-label a {
    color: inherit;
    text-decoration: underline;
  }
}
