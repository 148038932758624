.icon-action{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $gray-100;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border:none;
  fill: black;
  cursor: pointer;

  svg{
    width: 16px;
    height: 16px;

    path{
      stroke: none;
      fill: $gray-450;
    }
  }

  &.icon-favorite:not(.favorite){
    svg{
      path{
        fill: none;
        stroke: $gray-450;
      }
    }

    &:hover {
      svg path{
        fill: none!important;
        stroke: $primary!important;
      }
    }
  }

  &:hover {
    svg path{
      stroke: none!important;
      fill: $primary;
    }
  }

  .clickable-metric, &.goto-link {
    @media screen and (-ms-high-contrast: active) {
      svg path {
        stroke: $white!important;
      }
      &:hover {
        svg path{
          stroke: $primary!important;
        }
      }
    }
  }

  &.icon-favorite, &.group-icon {
    &:hover {
      svg path{
        stroke: none;
      }
    }
  }

  &.icon-counter{
    cursor: default;
    border: solid 1px $gray-450;
    background-color: $white;
    color: $gray-450;
    font-size: 0.8rem;
    font-weight: normal;

    @media screen and (-ms-high-contrast: active) {
      border: 1px solid!important;
    }

  }
}
