.filter-tabs{
  margin: 45px 0;
  border-bottom: 1px solid $gray-300;

  .container{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .filter-count{
    font-size: 1rem;
    color: $gray-600;
    align-items: center;
    padding-bottom: 15px;
  }

  .filter-links{
    align-items: flex-end;
    display: flex;
    font-size: 1.1rem;

    .task{
      padding: 0!important;
      border-bottom: none;
    }

    a{
      color: $black;
      padding: 0 0 15px;
      margin-left: 30px;
      margin-bottom: -1px;
      
      &:hover{
        border-bottom: 2px solid $gray-500;
        padding-bottom: 13px;
      }
    }

    .active{
      a{
        border-bottom: 2px solid $primary!important;
        font-weight: bold;
        padding-bottom: 13px;
        margin-left: 28px;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, 'lg')) {
  .filter-tabs{
    .filter-count{
      padding-bottom: 9px;
    }

    .filter-links{
      a{
        padding-bottom: 9px;

        &:hover{
          padding-bottom: 7px;
        }
      }

      .active{
        a{
          padding-bottom: 7px;
        }
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
  .filter-tabs{
    margin: 24px 0 10px;

    .container{
      align-items: flex-end;
    }

    .filter-links{
      font-size: 0.8rem;
      justify-content: space-between;
      flex-grow: 1;

      a{
        margin-left: 5px;
      }

      li.active:not(:first-child){
        a{
          margin-left: 3px;
        }
      }

      .filter-tab:first-child{
        a{
          margin-left: 0;
        }
      }
    }
  }

  .filter-count{
    font-size: 0.75rem;
  }
}

@media (max-width: 310px) {
  .filter-tabs{
    height: auto;

    a{
      height: auto!important;
      padding-bottom: 5px!important;
    }
  }
}