* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

_:-ms-fullscreen, body {
  height: 100%!important;

  div#root{
    height: 100%!important;

    >main{
      height: 100%!important
    }
  }
}

body{
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;

  div#root{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;

    >main{
      flex-grow: 1;
      display: block;
      position: relative;

      &.open{
        .main.container{
          @media (max-width: map-get($container-max-widths, 'md')) {
            min-height: 409px;
          }
        }
      }
    }
  }
}

.body {
  color: $gray-800;
}

.bg-magenta {
  background: $primary;
}

.navbar {
  a {
   color: $gray-800;
  }
}
.no-side-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tooltip {
  z-index: 9999;
}

@media (max-width: map-get($container-max-widths, 'md')) {
  .main.container {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 15px;
  }
}

.main.container,
[role=main] {
  h3 {
    font-weight: 600;
  }
}

body.user-is-tabbing {
  *:focus, .nav-item:focus, li:focus, .btn:focus, button:focus {
    outline: none;
    box-shadow: 0 0 0px 1px $primary, 0 0 0px 2px white, 0 0 0px 7px $light_blue !important;
  }

  .focusRing___1airF.carousel__slide-focus-ring {
    outline-color: transparent;
    outline: none;
    box-shadow: none;
  }
}

*:focus, .form-control:focus, .btn:focus, button:focus {
  outline: none;
  box-shadow: none;
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
  h1{
    font-size: 3rem;
  }
}
