// OpenSans mixins

@mixin opensans-font-face($font-family, $filename, $weight: normal, $style: normal) {
  $filepath: "/fonts/#{$filename}";

  @font-face {
    font-family: $font-family;
    font-style: $style;
    font-weight: $weight;
    src: url("#{$filepath}.ttf");
    src: url("#{$filepath}.ttf") format("truetype");
  }
}
