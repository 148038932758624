.save-modal-body {
  background-color: white;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
  padding: 60px;
  border-radius: 10px;
  min-width: 580px;

  .header {
    margin-bottom: 30px;

    #title {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
      color: $primary;
    }

    #description {
      font-size: 16px;
      color: $gray-450;
      margin-bottom: 56px;
    }
  }

  .content{
    width: 100%;
    margin: 10px 0;

    .MuiFormControl-root{
      width: 100%;
    }

    input{
      font-size: 0.87rem!important;
    }
  }

  .actions {
    margin-top: 60px;
    padding-top: 10px;
    border-top: solid 0.5px $gray-450;

    .discard-button {
      background-color: unset;
      padding: 0;
      border: none;
      font-size: 14px;
      font-weight: 600;
      color: $blue;
      text-transform: uppercase;

      &:hover{
        color: $primary;
      }
    }

    .save-button{
      padding: 7px 20px;
      font-size: 0.87rem;
      text-transform: uppercase;
      color: $white;
      font-weight: 600;
    }
  }
}