.main-tabs-container {
  position: relative;
  display:flex;
  justify-content: space-between;
  align-items: center; 

  border-bottom: 1px solid $gray-300;
  margin-left: -15px;
  margin-right: -15px;

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    & {
        margin: unset;
    } 
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    .nav-link { 
      margin-top: 5px!important;
      margin-right: 12px!important;
    }
  }

  .nav-tabs{
    flex-grow: 1;
  }

  .change-view-icons, .filters {
    height: 100%;
    display: flex;
    align-items: center;
    overflow: visible;
    margin-left: 15px;
    margin-right: 15px;

    button {
      background-color: transparent;
      border: none;

      &.grid-icon {
        @include icons-with-active($gray-500, $black, $black);

        @media screen and (-ms-high-contrast: active) {
          @include icons-with-active($white, $primary, $primary);
        }
      }

      &.list-icon {
        @include icons-with-active($gray-500, $black, $black);

        @media screen and (-ms-high-contrast: active) {
          @include icons-with-active($white, $primary, $primary);
        }

        g{
          stroke-width: 0.6;
        }
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    & {
      margin-left: unset;
      margin-right: unset;
      box-shadow: none;

      .change-view-icons {
        margin-right: unset;
      }
    }
  }
}
