// Modal

.modal-title {
  flex: 1;
  text-align: center;
}

.modal-header .close {
  position: absolute;
  right: 1rem;
  float: none;
}
