#bpmn-container, #bpmn-container-viewer{
  border: solid 1px $gray-300;
  border-radius: 10px;
  height: 500px;
  width: 100%;
  overflow: hidden;

  .djs-minimap{
    border: none;
    box-shadow: none;
    border-radius: 6px;
    top: 14px;
    right: 19px;

    .toggle{
      text-indent: -9999px;
      width: 32px;
      height: 32px;
      box-shadow: none;
      border: none;
      background-position: cover;
    }

    &:not(.open){
      background: none;
      top: 15px;
      right: 20px;

      .toggle{
        background: url('../images/mini-map-default.png') no-repeat center;
        cursor: pointer;

        &:hover{
          background: url('../images/mini-map-default-hover.png') no-repeat center;
        }
      }
    }

    &.open{
      border: solid 1px $gray-300;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

      .toggle{
        background: url('../images/mini-map-default-hover.png') no-repeat center;
      }
    }

    .viewport-dom{
      border-radius: 6px;
      border: solid 1px $blue;
      background: rgba(36, 110, 227, 0.3);
    }
  }
}

.chart-editor-page{
  margin-bottom: -60px;
  border-bottom: solid 4px $white;

  .header{
    z-index: 999;
    width: 100%;
    position: fixed;
    padding: 18px 0;
    background-color: $gray-50;
    border-bottom: solid 4px $white;

    >.container{
      display: flex;
      justify-content: flex-end;
    }

    h2{
      font-size: 1rem;
      text-transform: uppercase;
      margin: 0;
      flex-grow: 1;
      color: $gray-450;
      font-weight: 600;
      margin-top: 7px;
    }

    .btn{
      padding: 7px 20px;
      font-size: 0.87rem;
      text-transform: uppercase;
      color: $white;
      font-weight: 600;
      margin-left: 10px;
    }
  }

  #bpmn-container, #bpmn-container-viewer{
    position: absolute;
    height: calc(100% - 113px);
    border: 0;
    border-radius: 0;
    background: $gray-50;
    border-bottom: solid 4px $white;

    #propview{
      width: 333px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      overflow: auto;
      width: 25%;
      float: right;
      height: 100%;
      background-color: $white;

      .main-underline-tabs{
        background-color: rgba($gray-50, 0.5);

        .nav-link{
          text-transform: uppercase;
          font-size: 1rem;
          font-weight: normal;
          padding-bottom: 15px;
          color: $gray-450;
          text-shadow: none;

          &:hover,&.active{
            border-bottom: 2px solid $blue;
          }
          
          &.active{
            color: $blue;
            font-weight: 600;
          }
        }
      }

      .nav-tabs{
        padding: 12px 0 0 30px;

        .nav-link.active{
          background-color: transparent;
        }
      }
    }

    #bpmnview{
      height: 100%;
      float:left;
      width: 75%;
    }

    .djs-container {
      background-color: white;
      border-right: solid 4px #f8f8f8;            
    
    }
  


    .djs-palette{
      top: 0;
      left: 0;
      border: none;
      border-right: solid 4px $white;
      width: 116px;
      padding-left: 5px;
      padding-bottom: 10px;
      overflow: auto;
      height: 100%;
      background: $gray-50;

      .separator{
        padding-top: 23px;
        margin: 15px -4px;
        border-bottom: solid 4px white;
      }

      .entry{
        color: $gray-500;
        cursor: pointer;
        height: 40px;

        &:before{
          font-size: 30px;
        }

        &:hover{
          color: $primary;
        }
      }
    }

    .bpp-properties-panel{
      background: $white;
      min-height: 100%;

      .bpp-properties-header{
        .label{
          display: none;
        }
      }

      .bpp-properties-tab-bar{
        padding: 0;

        .scroll-tabs-button{
          display: none;
        }

        .bpp-properties-tabs-links{
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 0 16px;

          li{
            a{
              text-transform: uppercase;
              background: none;
              margin: 0 5px;
              padding: 10px 0;
              border: none;
              cursor: pointer;
              color: $gray-450;
              font-size: 0.87rem;
              font-weight: 600;

              &:hover{
                color: $primary;
              }
            }

            &.bpp-active{
              a{
                border-bottom: solid 2px $blue;
              }
            }
          }
        }
      }

      .bpp-properties-tabs-container{
        color: $gray-450;

        input, label, .group-label, select{
          color: $gray-450;
        }

        .group-label{
          font-size: 1.12rem;
          font-weight: 600;
          margin-bottom: 12px;
        }

        label{
          font-size: 1rem;
          font-weight: normal;
        }

        .bpp-field-description, .bpp-error-message{
          font-size: 0.87rem;
        }

        .bpp-checkbox{
          input{
            margin-right: 10px;
          }
        }

        [contenteditable=true], select, input, textarea {
          padding: 16px 10px;
          margin: 12px 0;
          border-radius: 4px;
        }

        .bpp-field-wrapper{
          .clear{
            display: none;
          }
        }

        .bpp-properties-group{
          margin: 0 16px;
          padding: 30px 0;

          &:hover{
            .group-toggle{
              display: none;
            }
          }
        }
      }
    }
  }
}

/* activity */
g[data-element-id ^= Task] > g,
g[data-element-id ^= Activity] > g {
  & > text {  
    fill: #EDF0FF !important;    
  }
  & > path {  
    display: none;
  }
  & > rect {
    
    stroke:  white !important;
    stroke-width: 1px !important;
    border-radius: 3px;
    fill: #0945A5 !important;
  }
}


.icon-red{
  width: 10px;
  height: 10px;
  background: red !important;
}

/* start-event */
g[data-element-id ^= StartEvent] > g > circle {
  stroke: darken($color: #8893a4, $amount: 10) !important;  
  fill: darken(#f8f8f8, 10%) !important;
  stroke-width: 4px !important;
}


/* end-event */
g[data-element-id ^= Event] > g > circle {
  stroke: darken($color: #8893a4, $amount: 10) !important;
  fill: darken(#f8f8f8, 10%) !important;
  stroke-width: 4px !important;
}

g[data-element-id ^= EndEvent] > g > circle {
  stroke: darken($color: #8893a4, $amount: 10) !important;
  fill: darken(#f8f8f8, 10%) !important;
  stroke-width: 8px !important;
}

/* lane */
g[data-element-id ^= Lane] > g > rect {
  stroke: white !important;
  stroke-width: 8px !important;
  fill: #f8f8f8 !important;
}

g[data-element-id ^= Participant] > g > rect {
  stroke: white !important;
  stroke-width: 8px !important;
  fill: darken(#f8f8f8, 10%) !important;
}

g[data-element-id ^= Gateway] > g {
  & > path {
    fill:  darken($color: #8893a4, $amount: 10) !important;
    stroke:  darken($color: #8893a4, $amount: 10) !important;
  }
  & > polygon {
    fill: transparent !important;
    stroke: darken($color: #8893a4, $amount: 10)  !important;
    
  }

} 