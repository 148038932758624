$primary-color: #246ee3;
$secondary-color: #a4a4a4;

.MuiAppBar-colorPrimary {
  color: $primary-color !important;
  background-color: #f8f8f8 !important;
  min-height: 60px;
  line-height: 60px;
  margin-bottom: 30px;
  .appbar-list{
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    .appbar-item{
      float: left;
      border-bottom: 2px solid #0945a5;
      .appbar-link {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
  }
}
  

@media (max-width: map-get($grid-breakpoints, 'md')) {
  .MuiAppBar-colorPrimary {
    margin-bottom: 0;
  }
}