@mixin slide-dot {
  border-radius: 50%;
  padding: 0;
  width: 10px;
  height: 10px;
  background-color: $gray-400;
  border: none;
  margin: 0 7px;
}

@mixin section-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: solid 1px $gray-400;
  margin-bottom: 30px;

  h3{
    text-transform: uppercase;
    font-size: 1.31rem;
    font-weight: 500;
    margin: 0;
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    h3{
      font-size: 1.12rem;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
  .magazine_page {
    padding: 0;

    >.container{
      padding: 0!important;

      >span{display: none;}
    }
  }
}

.magazine_page {
  >.container{
    padding: 15px 0;
  }
}

.magazine-header {
  margin-top: 32px;
  width: 100%;
  position: relative;
  padding: 30px 0 38px 0;

  .magazine_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: url('/images/magazin-banner-wfilter.jpg') no-repeat;
    background-size: cover;
    backdrop-filter: blur(47px);
    -webkit-backdrop-filter: blur(47px);
  }

  h2 {
    z-index: 2;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    opacity: 0.99;
    font-size: 6.25rem;
    color: $primary;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin: 0;
    padding: 0;
  }

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    h2 {
      font-size: 1.93rem;
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    margin-top: 0;
    padding: 16px 0 24px 0;
    
    h2 {
      font-size: 0.18rem;
    }
  }
}

.carousel.articles{
  margin: 30px 0;
  background-color: $gray-800;
  min-height: 410px;
  padding-bottom: 90px;

  h2{
    font-size: 2.25rem;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .carousel__inner-slide{
    color: white;
    padding: 30px;
    font-size: 1.125rem;

    .slider-content{
      display: flex;
      justify-content: space-between;
      min-height: 168px;

      .rotation-wrapper {
        position: relative;
        width: 20px;
        min-height: 168px;
        margin-left: -5px;
      }

      .article-category{
        display: inline-block;
        transform: rotate(-90deg);
        position: absolute;
        top: 0;
        right: 100%;
        white-space: nowrap;
        transform-origin: right top;
        text-transform: uppercase;
        font-size: 1.31rem;
        font-weight: bold;
      }

      .article-content{
        flex-grow: 1;
        width: calc(100% - 55px);
        margin-left: 55px;
        margin-top: -5px;
      }

      .article-image-container{
        float: right;
        clip-path: inset(0 0 0 0);
        margin: -53px 0px 20px 20px;
        height: 273px;
        width: 289px;

        .smart_tile{
          margin: 0!important;
        }
      }
    }

    .show-more{
      background: none;
      color: $light_blue;
      font-size: 1rem;
      padding: 0;
      border: none;
      display: inline-block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      padding: 5px 0;
      background-color: $gray-800;
    }
  }

  .actions{
    display: flex;
    justify-content: space-between;
    padding: 30px;
    border-top: solid 1px $white;
    position: absolute;
    width: 100%;
    bottom: 0;

    .carousel__dot{
      @include slide-dot;

      &.carousel__dot--selected{
        background-color: white;
      }
    }

    .carousel__back-button, .carousel__next-button{
      font-size: 1rem;
      color: white;
      background: none;
      border: none;
      width: 68px;
    }

    .carousel__back-button{
      padding-left: 25px;
      background: url('../images/arrow-left.png') left center no-repeat;
      background-size: 15px 15px;
    }

    .carousel__next-button{
      padding-right: 25px;
      background: url('../images/arrow-right.png') right center no-repeat;
      background-size: 15px 15px;
    }
  }

  .expanded{
    .carousel__inner-slide{
      height: auto;
      position: relative;
      overflow: hidden
    }

    height: auto;
    padding-bottom: 0!important;
  }

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    min-height: 346px;
    padding-bottom: 90px;

    h2{
      font-size: 1.5rem;
    }

    .carousel__slider{
      min-height: 285px;

      .carousel__slide{
        min-height: 310px;

        .carousel__inner-slide{
          padding: 20px;
        }
      }

      .slider-content{
        .article-content{
          margin-left: 20px;
        }
        
        .article-image-container {
          height: 198px;
          width: 214px;

          .article-image, .smart_tile{
            height: 198px!important;
            width: 214px!important;
          }
        }

        .article-category{
          font-size: 1.12rem;
        }
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    margin-top: 0;
    padding-bottom: 73px;

    h2{
      margin-bottom: 20px;
      font-size: 1.31rem;
    }

    .carousel__slider{
      min-height: 250px;

      .carousel__slide{
        min-height: 275px;
      }
    }

    .rotation-wrapper{
      display: none;
    }

    .carousel__inner-slide{
      padding: 30px 16px;

      .slider-content{
        .article-content{
          margin-left: 0;
          font-size: 15px;
        }

        .article-image-container{
          display: none;
        }
      }
    }

    .actions{
      padding: 20px 16px 30px;
      .carousel__back-button, .carousel__next-button{
        display: none;
      }

      .carousel__dot{
        width: 5px;
        height: 5px;

        &:first-child{
          margin-left: 0;
        }

        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
}

.task-tools-container{
  margin: 0;

  .tasks-container{
    padding: 15px 15px 0 0;
    .header{
      @include section-header;
    }
  }

  .tools-container{
    padding: 15px 0 15px 15px;

    .carousel.tools{
      min-height: 300px;

      .header{
        @include section-header;

        .actions{
          button{
            @include slide-dot;

            &:disabled{
              background-color: $black;
            }
          }

          .carousel__dot{
            width: 6px;
            height: 6px;
          }
        }
      }

      .carousel__slider{
        margin: 0 -15px;
      }

      .carousel__inner-slide{
        padding: 0 15px;

        .smart_tile{
          width: 100%;
        }
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    flex-direction: column-reverse;
    margin-top: 60px;

    .tools-container{
      padding: 0;

      .smart_tile.layout-3{
        margin:0 0 15px 0!important
      }
    }

    .tasks-container{
      padding-right: 0;

      .magazine_tasks_list{
        li{
          &:first-child{
            padding-top: 0!important;
          }
        }

        .text-muted{
          padding: 0 15px;
        }
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    .tools-container{
      padding: 0 16px;

      .smart_tile.layout-2{
        margin:0!important
      }

      .carousel.tools{
        min-height: 180px;

        .carousel__slider{
          margin: 0 -6px;
        }

        .carousel__inner-slide{
          padding: 0 6px;
        }

        .header{
          border-bottom: none;
          margin-bottom: 5px;
        }

        .actions{
          .carousel__dot-group{
            button:nth-child(2n+2){
              display: none;
            }

            button:nth-child(5){
              margin-right: 0;
            }
          }
        }
      }
    }

    .tasks-container{
      .header{
        padding: 0 15px 15px;
      }

      .task{
        padding: 0 15px 15px!important;
      }
    }
  }
}

.folders-container{
  .header{
    @include section-header;
    margin: 0;
  }

  .folders-content{
    margin: 0;

    .folder{
      background-color: $gray-100;
      color: black;
      width: 23.5%;
      height: 255px;
      float:left;
      margin: 2% 2% 0 0;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      cursor: pointer;

      &:hover{
        color: black;
        text-decoration: none;
      }

      &:nth-child(4n+4){
        margin-right: 0
      }

      h4{
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.125rem;
        margin: 16px;
      }

      .image{
        height: 182px;
        width: 100%;
        border-bottom: solid 1px $gray-300;
        background: url('/images/folder.png') no-repeat center;
      }

      &.smart_tile{
        background-color: $white;

        .smart_tile_img{
          height: 182px;
        }
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    margin-top:60px;

    .folders-content{
      .folder{
        width: 31%;
        margin: 3.5% 3.5% 0 0!important;

        &:nth-child(4n+4){
          margin-right: 3.5%!important
        }

        &:nth-child(3n+3){
          margin-right: 0!important
        }
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    margin-top:40px;
    padding: 0 15px;

    .header{
      border-bottom: none;
      margin-bottom: 20px;
    }

    .folders-content{
      .folder{
        height: 131px;
        width: 48%;
        margin: 4% 4% 0 0!important;

        &:nth-child(3n+3){
          margin-right: 4%!important
        }

        &:nth-child(2n+2){
          margin-right: 0!important
        }

        h4{
          font-size: 0.75rem;
          margin: 0 7px;
          line-height: 38px;
        }

        .image{
          height: 93px;
          background-size: 55%;
        }

        &.smart_tile{
          .smart_tile_img{
            height: 93px;
          }

          .smart_tile_info{
            padding-top: 3px;
          }
        }
      }
    }
  }
}
