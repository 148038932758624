.static-page{
  h1{
    margin-bottom: 0;
  }

  p{
    margin-top: 0.5rem;

    &:last-child{
      margin-bottom: 0;
    }
  }
}