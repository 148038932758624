//
// animation
// ----------------------------------------

.fade {
  transition: opacity 300ms ease-out;

  &.in {
    opacity: 1;
  }
}

.slide {
  width: 0;
  transition: width 150ms cubic-bezier(.6, 0, .7, .2);

  .icon {
    opacity: 0;
    transition: opacity 150ms ease-out;
  }
  &.in {
    .icon {
      opacity: 1;
      transition: 150ms opacity 150ms ease-out;
    }
  }
}
