#tabs-container {
  .header{
    display: none;
  }

  .dashboard-row.list {
    padding: 10px 0;
    margin: 0px;
    border-bottom: solid 1px $gray-500;

    .col {
      padding: 0px;
    }

    .card {
      background: white ;
      border: none;
      &.disabled { pointer-events: none; }

      .card-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    &:hover {
      box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.16);

      .info-container {
        h4 {
          color: $primary;
        }
      }

      .right-content {
        .btn, .icon-action {
          border-color: $black;

          &.tasks-counter{
            background-color: $primary;
            border-color: $primary;
          }

          svg {
            path {
              fill: $black;
            }
          }

          &.external-link {
            svg path {
              fill: $black;
              stroke: none;
            }
          }
        }

        .icon-favorite, .icon-play {
          svg path {
            stroke: $black!important;
          }

          &.favorite{
            svg path {
              stroke: $black!important;
              fill: $black!important;
            }
          }
        }
      }
    }

    .left-content {
      text-transform: capitalize;

      .info-container {
        padding-left: 20px;

        h4 {
          font-size: 18px;
          font-weight: bold;
          color: black;
          margin-bottom: 0;
        }

        h5 {
          font-size: 18px;
          color: $gray-600;
          margin-bottom: 0;
        }
      }
    }

    .right-content {
      padding-right: 10px;
      z-index: 1;

      .icons{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .btn, .icon-action, .clickable-metric {
        width: 48px;
        height: 48px;
        padding: 0px;
        margin-right: 30px;
        background-color: white;

        &.clickable-metric{
          border: none;
        }

        &:last-child{
          margin-right: 0;
        }

        &.tasks-counter, &.icon-counter {
          background-color: transparent;
          border: none;

          .count{
            color: $primary;
            font-size: 1rem;
            font-weight: normal;
          }
        }

        svg {
          width: 16px;
          height: 16px;
          
          path {
            fill: $gray-500;

            @media screen and (-ms-high-contrast: active) {
              fill: $white!important;
            }
          }
        }

        &:hover {
          svg path {
            fill: $primary!important;
          }
        }

        &.external-link {
          svg path {
            fill: $gray-500;
            stroke: none;

            &.empty{
              stroke: none!important;
              fill: none!important;
            }
          }

          &:hover {
            border-color: $primary;

            svg path {
              fill: $primary !important;

              &.empty{
                stroke: none!important;
                fill: none!important;
              }
            }
          }
        }

        &.link, &.icon-info, &.icon-play {
          &:hover {
            border-color: $primary;

            svg path {
              fill: $primary !important;
            }
          }

          svg path {
            stroke: none;
          }
        }

        &.icon-favorite, &.icon-play {
          svg g path {
            stroke: $gray-500;
            fill: none;
          }

          &:hover {
            border-color: $primary;

            svg path {
              stroke: $primary!important;
              fill: none!important;
            }
          }

          &.favorite {
            svg g path {
              fill: $gray-500;
              stroke: $gray-500;
            }

            &:hover {
              svg path {
                stroke: $primary!important;
                fill: $primary!important;
              }
            }
          }
        }
      }
    }
  }
}

