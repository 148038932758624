.footer {
  @extend .brandbar;
  background-color: $gray-50;
  color: $gray-450;
  font-size: 14px;
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 19px 0;
  z-index: 0;
  min-height: auto;
  border-top: solid 2px $white;

  .container{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .brand-images {
    border-bottom: 1px solid $gray-250;
    min-height: 40px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .brand-logo, .brand-claim {
      margin-top: 0;
      float:none
    }
    
    .brand-logo{
      img{
        width: 85px;
      }
    }

    .brand-claim{
      img{
        width: 193px
      }
    }
  }

  .footer-links{
    padding: 8px 0;
    .imprint,
    .pages-links{
      a{
        
        color: $gray-450;

        &:hover{
          color: $gray-500;
        }
      }
    }

    .copyright{
      text-align: right;
      p{
        margin-bottom: 0;
        svg{
          margin-top: -5px;
        }
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    padding: 9px 0;

    .container{
      height: auto!important;
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {    
    min-height: auto !important;
    margin-top: 15px;
    .footer-links{
      & > * {
        text-align: center;
        margin: 0;
      }
      .copyright{
        margin: 0;
        text-align: center;
      }
    }
    
  }
 
}
