$attributes-border: 1px solid $gray-300;

.profile-container {
  padding-top: 1rem;

  .row {
    margin: 0
  }

  button {
    padding-left: 0px;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .profile-page-title {
    color: $black;
    margin-top: 32px;
    border-bottom: 1px solid $gray-300;

    .nav-tabs{
      border:none;

      .nav-link {
        border: none;
        cursor: pointer;
        padding: 0;
        padding-bottom: 0.8rem;
        display: inline-block;
        font-size: 1.31rem;
        color: $black;
        margin-right: 30px;
        background: none;

        &:hover{
          border-bottom: solid 2px $gray-300;
        }

        &.active{
          border-bottom: solid 2px $primary;
          font-weight: bold;
        }
      }
    }

    span {
      display: none;
    }
  }

  .profile-attributes-container {
    padding: 3rem 0 0;
    display: flex;
    flex-direction: column;
    flex-grow: 2;

    .user-name {
      font-size: 3em;
      margin-bottom: 30.5px
    }

    .profile-attributes {
      padding: 15px 15px 0;
      border-top: 1px solid $gray-300;
      color: $gray-600;

      .row {
        &:not(:last-child) {
          margin-bottom: 5px;

          .value {
            margin-bottom: 20px;
          }
        }

        .value {
          color: $black;
          font-weight: 500;
        }
      }
    }
  }

  .profile-attribute-container {
    padding: 3rem 0 0;
    display: flex;
    flex-direction: column;
    flex-grow: 2;

    .profile-attributes-title{
      font-weight: bold;
      font-size: 1.12rem;
      color: $black
    }

    .profile-attribute {
      padding: 15px 0;
      border-top: 1px solid $gray-300;
      border-bottom: 1px solid $gray-300;
      color: $gray-600;

      .col{
        padding: 0;
      }

      .title{
        padding-bottom: 15px;
        font-size: 1rem;
      }

      .attribute-value{
        font-size: 1.12rem;
      }

      form{
        width: 100%;

        .editable-row{
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .edit-button {
      padding: 0;
      color: $black;
      background: none;
      box-shadow: none;
      border: none;
      font-size: 1.12rem;
      font-weight: 500;
      text-align: right;
      float: right;
    }

    input {
      width: 428px;
      padding: 15px;
      border-radius: 4px;
      border: solid 1px $gray-500;
    }

    button:not(.edit-button) {
      padding: 16px 30px;
      border-radius: 4px;
      background-color: $primary;
      font-size: 18px;
      font-weight: 500;
      border: none;
    }
  }

  .integrations-attributes-container {
    padding: 3rem 0 0;
    display: flex;
    flex-direction: column;
    flex-grow: 2;

    .user-name {
      font-size: 3em;
      margin-bottom: 30.5px
    }

    .integrations-attributes {     
      color: $gray-600;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .row {
        &:not(:last-child) {
          margin-bottom: 5px;

          .value {
            margin-bottom: 20px;
          }
        }

        .value {
          color: $black;
          font-weight: 500;
        }
      }
    }
  }

  .policy-container {
    padding: 3rem 1.5rem 0;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    color: $gray-600;

    h3 {
      color: $black;
      font-size: 1.2rem;
    }

    div {
      margin-bottom: 0.8rem;
    }
  }

  .memberships-container{
    padding: 50px 0 0;

    h3{
      font-size: 1.12rem;
      font-weight: bold;
      padding-bottom: 10px;
      border-bottom: solid 1px $gray-300;
    }

    p{
      font-size: 1rem;
      color: $gray-600
    }

    a{
      font-weight: 500;
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    >.container{
      display: none;
    }

    .profile-page-title{
      margin-top: 0;
    }


    .tab-pane{
      >.container{
        padding: 0 92px;
      }
    }

    .profile-attributes-container{
      padding-bottom: 0;

      .profile-attributes{
        padding: 15px 0;
      }
    }

    .integrations-attributes-container{
      padding-bottom: 0;

      .integrations-attributes{
        padding: 15px 0;
      }
    }

    .integrations-attributes-container{
      padding-bottom: 0;

      .integrations-attributes{
        padding: 15px 0;
      }
    }


    .policy-container{
      padding: 3rem 0 0;

      .policy-body{
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 760px) {
    .profile-attribute {
      input{
        width: 100%;
      }
    }
    .profile-page-title {      
      .nav-tabs{        
        .nav-link {
          font-size: 0.97rem;
        }
      }
    }    
    .integrations-attributes-container{ 
      padding-top: 1rem; 
    }          
  }
  
  @media (max-width: map-get($grid-breakpoints, 'md')) {
    >.container{
      padding: 0
    }

    .tab-pane{
      >.container{
        padding: 0 16px;
      }
    }

    .profile-attributes-container, .policy-container {
      padding: 3rem 0 0;
    }

    .profile-attribute-container{
      padding: 30px 0;

      .profile-attribute {
        h2, .attribute-value{
          font-size: 0.93rem;
          color: $black
        }

        .row{
          margin-bottom: 0!important;
        }

        .title{
          padding-bottom: 5px;
        }

        .title, button{
          font-size: 0.75rem;

          &.edit-button{
            color: $gray-600
          }
        }

        input{
          width: 201px;
          padding: 8px;
          font-size: 0.75rem
        }
      }

      button:not(.edit-button) {
        padding: 9px 15px;
        min-width: inherit;
      }
    }
  }
}
