.search-filter-tabs {
  margin: 40px 0;
  background: $gray-200;
}

.search-filter-tabs-container {
  display: flex;
  flex: 1 1 auto;
}

.search-filter-tab {
  display: flex;
  min-height: 130px;
  align-items: stretch;
  flex-grow: 1;
  border-right: 1px solid $white;
  border-bottom: 1px solid $gray-200;

  &:hover, &.active {
    border-bottom: 1px solid $primary;

    .search-filter-tab-link {
      background: darken($gray-100, 2%);

      .search-filter-tab-title {
        color: $primary;
      }
    }
  }

  &:first-child {
    border-left: 1px solid $white;
  }
}

.search-filter-tab-link {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  padding: 30px 0;

  .search-filter-tab-title {
    align-self: center;
    text-transform: uppercase;
    flex-grow: 1;

  }

  .search-filter-tab-count {
    font-size: .9em;
    color: $gray-400;
    align-self: center;
  }

  &:hover {
    background: darken($gray-100, 2%);

    .search-filter-tab-title {
      color: $primary;
    }
  }
}
