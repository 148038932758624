// Pagination

.pagination {
  .page-link {
    &:active {
      z-index: 2;
      color: $pagination-hover-color;
      background-color: hsv-darken($pagination-hover-border-color, 7%, true);
      border-color: hsv-darken($pagination-hover-bg, 7%, true);
    }
  }
}
