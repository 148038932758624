@mixin mobile-view {
  .bm-overlay, .bm-menu-wrap{
    top: 0px;
    height: 100vh!important;

    .bm-cross-button{
      svg{
        stroke: white;
        width: 16px;
      }
    }
  }
}

.burger-menu{
  position: relative;

  .bm-burger-button{
    position: relative;
    margin: 5px 0 0 30px;

    svg path{
      fill: $gray-400;
    }
    
    button{
      width: 24px!important;
      height: 24px!important;
    }

    .bm-icon{
      &.close-menu{
        svg{
          stroke: $primary;
          width: 24px!important
        }
      }
    }
  }

  .bm-overlay{
    width: 100vw!important;
    height: 100vh!important;
    top: 160px;
    right: 0;
    background: rgba(75, 75, 75, 0.75)!important;
    z-index: 1100;
  }

  &.open{
    .bm-menu-wrap{
      transform: translate3d(0, 0px, 0px);
    }
  }
  
  .bm-menu-wrap{
    background: white;
    top: 160px;
    height: calc(100vh - 75px)!important;

    .bm-item-list{
      >.menu-item:first-child{
        background: $gray-700;
        color: $white;
      }

      .menu-item{
        position: relative;
        background: white;
        border: none;
        text-align: left;
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid $gray-300;
        color: black;
        font-size: 21px;

        &:hover{
          text-decoration: none;
        }

        &.name{
          cursor: default;
        }

        svg{
          width: 16px;
          height: 16px;
          margin: 0 20px 3px 0!important;
          fill: $gray-600;

          g, path{
            stroke: $gray-600;
          }
        }

        &.magazine{
          padding-left: 17px;

          svg{
            width: 22px;
            height: 16px;

            path{
              stroke: none;
            }
          }
        }

        &.admin{
          font-size: 18px;
        }

        &.profile, &.notifications{
          svg{
            width: 19px;
            height: 19px;
          }
        }
      }

      .logout-links{
        .menu-item{
          color: $gray-600;
          font-size: 18px;
        }
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    .bm-burger-button{
      svg, button{
        margin-top: 2px;
        width: 20px!important;
        height: 20px!important
      }
      
      .bm-icon{
        &.close-menu{
          svg{
            margin-top: 2px;
            width: 18px!important
          }
        }
      }
    }

    .bm-overlay, .bm-menu-wrap{
      top: 85px;
    }

    .bm-menu-wrap{
      width: 80%!important;
      margin-bottom: 30px;

      .bm-item-list{
        .menu-item{
          padding: 13px;
          font-size: 18px;

          &.magazine{
            padding-left: 8px;
          }

          &.admin{
            font-size: 16px;
          }
        }

        .logout-links{
          .menu-item{
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 350px) {
    .bm-menu-wrap{
      .bm-item-list{
        .menu-item{
          padding: 11px
        }
      }
    }
  }

  &.over-menu{
    @include mobile-view;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      .menu-item{
        &.name{
          height: 85px;
        }
      }
  
      .bm-menu-wrap{
        .bm-cross-button{
          top: 28px!important;
          right: 20px!important;

          svg{
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
