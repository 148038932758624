.kebab-dropdown {
  border: none;
  position: relative;
  line-height: 0px;
  
  &.btn-group {
    line-height: 0px;
    display: unset; // remove reactstrap default;
  }

  .kebab-menu {
    position: absolute;
    right: 34px;
    top: 0px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 2px;
    min-width: 126px;
    
    .dropdown-item, a {
      color: black;
      font-weight: 500;
      padding-top: 14px;
      padding-bottom: 14px;
      
      &:hover {
        color: $primary;
        background-color: unset;
        
        a {
          color: $primary;
        }
      }
    }

    .dropdown-divider:last-child{
      display: none;
    }
  }

  .toggle-button {
    padding: 0;
    border: 0;
    background-color: $white;
    
    svg {
      cursor: pointer;
      fill: $gray-600
    }

    &:hover {
      svg {
        fill: $primary;
      }
    }
  }

  button.kebab-menu {
    border: none;
    padding: 0px;

    &:hover {
      background-color: $white;
      
      .icon {
        fill: $primary;
      }
    }
  }
}

