.alerts {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.alert.flash {
  display: flex;
  color: $gray-600;
  padding: 0;
  border-radius: 0;
  border-color: fade-out($gray-350, .3);
  margin-bottom: $grid-gutter-width;

  .alert-icon {
    background-color: fade-out($gray-350, .3);
    background-image: url('/images/alert-info.svg');
    background-position: 50% 50%;
    background-size: 25px;
    background-repeat: no-repeat;
    width: 82px;
    height: 82px;
  }

  &.warning {
    border-color: fade-out($orange, .3);

    .alert-icon {
      background-image: url('/images/alert-warning.svg');
      background-color: fade-out($orange, .3);
    }
  }

  &.danger {
    border-color: fade-out($red, .3);

    .alert-icon {
      background-color: fade-out($red, .3);
      background-image: url('/images/alert-danger.svg');
    }
  }

  &.success {
    border-color: fade-out($green, .3);

    .alert-icon {
      background-color: fade-out($green, .3);
      background-image: url('/images/alert-success.svg');
    }
  }

  .alert-text {
    padding: 21px 32px;
    flex-grow: 1;
    background: $white;
  }
}

.description-alert{
  border: none;
  border-radius: 0;
  background-color: $gray-700;
  color: $white;
  font-size: 1.3rem;
  margin-bottom: 0;
  transition: all 300ms ease-out;
  height: 0;
  padding: 0;
  overflow: hidden;

  >.container{
    position: relative;
  }

  &.show{
    height: inherit;
    padding: 48px 0;

    .close{
      display: block;
    }
  }

  .label{
    color: $gray-300;
    margin-bottom: 10px;
  }

  .description{
    margin-bottom: 0;
  }

  .close{
    padding-top: 0;
    display: none;

    &::before{
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M15.81,14.99l-6.99-7l6.99-7c0.24-0.24,0.2-0.63-0.04-0.83c-0.24-0.2-0.59-0.2-0.79,0l-6.99,7l-6.99-7 C0.75-0.08,0.36-0.04,0.16,0.2c-0.2,0.24-0.2,0.59,0,0.79l6.99,7l-6.99,7c-0.24,0.24-0.2,0.63,0.04,0.83c0.24,0.2,0.59,0.2,0.79,0 l6.99-7l6.99,7c0.24,19.24,0.59,0.24,0.83,0.04C16.04,15.66,16.08,15.26,15.81,14.99C15.85,15.03,15.81,15.03,15.81,14.99z' fill='white'/%3E%3C/svg%3E");

      @media (max-width: map-get($grid-breakpoints, 'md')) {
        width: 16px;
        height: 16px;
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    font-size: 1.1rem;

    &.show{
      padding: 30px 0px;
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    font-size: 0.9rem;
    
    &.show{
      padding: 20px 0px;
    }

    .label{
      font-size: 0.75rem;
    }
  }
}
