.navbar {
  @include media-breakpoint-down(sm) {
    border: none;
    padding-bottom: 0.375rem;
    padding: 0rem 15px;
    
  }

  .navbar-toggler {
    .navbar-toggler-icon {
      height: .5em;
      width: .5em;
    }
  }

  &.loggedout {
    padding-top: 1.4125rem;
    padding-bottom: 1.4125rem;

    @include media-breakpoint-down(sm) {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
    }
  }

  &.navbar-expand-md {
    .navbar-collapse {
      padding-top: 8px;

      @include media-breakpoint-up(md) {
        display: none !important;
      }
    }
  }
}

.navbar-nav {
  flex-grow: 1;
  flex-direction: row;

  .navbar-nav-flex-right {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-grow: 1;

      .show & {
        display: none;
      }
    }
  }

  .navbar-icon {
    color: $black;
    cursor: pointer;
    padding: 0;
    margin: 9px 0 0 10px;
    background: none !important;
    z-index: 999;

    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      margin: 5px 0 0 20px;
    }

    &.search-icon {
      margin-left: 0px;
    }

    &.linkgroups{  
      svg{
        g{
          path{
            fill: $gray-400;
            stroke: none;
          }
        }        
      }
      
    
      &:hover, &.active{    
        g{
          path{
            fill: $primary;
          }
        }
      }
     
    }
    
    &.linkbell {
      g{
        g{
          path{
            fill: $gray-400;
            stroke: none;
          }
        }
      }

      &:hover, &.active{
        g{
          g{
            path{
              fill: $primary;
            }
          }
        }
      }
    }

    
    &.profile-menu, &.search-icon{
      svg{
        width: 26px;
        height: 26px;
      }

      path, circle{
        fill: $gray-400;
        stroke: none;
      }

      &:hover, .active{
        path, circle{
          fill: $primary;
        }
      }
    }

    &.close-icon {
      margin: 10px 0 0 0;

      path, circle{
        stroke: none;
      }

      svg {
        width: 28px;
        height: 28px;
        fill: $gray-500;
      }

      @media screen and (-ms-high-contrast: active) {
        svg path:nth-child(1) {
          fill: none;
        }
      }

      &:hover {
        svg {
          fill: $black;
        }
      }

      @media (max-width: map-get($grid-breakpoints, 'lg')) {
        margin: 5px 0 0 28px;

        svg {
          width: 30px;
          height: 30px;
        }
      }

      @media (max-width: map-get($grid-breakpoints, 'md')) {
        margin: 5px 10px 0 28px;
      }
    }

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      margin: 4px 0 0 28px;

      svg {
        width: 22px;
        height: 22px;
      }

      &.close-icon {
        svg {
          width: 26px;
          height: 26px;
        }
      }

      &.linkburger-menu {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    svg {
      stroke: $black;
    }

    &:hover, &.active {
      svg {
        stroke: $primary;

        @media screen and (-ms-high-contrast: active) {
          path {
            fill: $primary;
          }
          g {
            stroke: $primary;
          }
        }
      }
    }

    @media screen and (-ms-high-contrast: active) {
      svg {
        path {
          fill: $white;
        }
        g {
          stroke: $white;
        }
        .active {
          fill: none;
          stroke: $primary;
        }
      }
    }

    background: none;
    border: none;
  }

  .badge {
    position: absolute;
    top: -7px;
    right: 0;

    &.small {
      font-size: 0.75rem;
      padding: 0.4em
    }

    @include media-breakpoint-down(sm) {
      width: 24px;
      height: 24px;
      position: relative;
      display: inline-block;
      margin-left: 18px;

      &.small {
        font-size: 0.93rem;
        font-weight: bold;
        padding-top: 5px;
        top: 0
      }

      &.menu {
        position: absolute;
        top: 7px;
        right: 4px;
        width: 12px;
        height: 12px;
        z-index: 9999;
      }
    }
  }

  .nav-item {
    .profile-menu {
      padding-right: 0 !important;
      border-top-right-radius: 3px !important;
      border-bottom-right-radius: 3px !important;
    }

    .username {
      padding-left: 0.75rem;
    }
  }
}
