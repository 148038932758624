.profile-menu {
  button.nav-link {
    border: none;
    text-align: left;
  }

  .profile-link {
    color: $gray-350;
  }

  .show {
    .triangle {
      background-image: url('../images/dropdown-arrow.png');
      background-size: cover;
      position: absolute;
      top: 30px;
      left: calc(50% - 5px);
      width: 30px;
      height: 30px;
      z-index: 9999999;
    }
  }

  .dropdown-menu {
    background-color: $white;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px $gray-500;
    left: 10px!important;
    margin-top: 1.25rem;
    padding-top: 0;
    width: 209px;

    .dropdown-item {
      color: $black;
      padding: 7px 30px;
      cursor: pointer;
      font-size: 1rem;

      &.profile-name{
        color: $gray-600;
        border-bottom: solid 1px $gray-500;
        padding: 15px 30px;
        margin-bottom: 7px;
      }

      &:hover {
        background: none;
        font-weight: bold;
        color: $black;
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, "md")) {
    .dropdown-menu {
      left: 7px;
    }

    .username {
      line-height: 1;
      vertical-align: bottom;
    }

    .show {
      .username {
        color: $primary;
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, "md")) {
    .dropdown-menu {
      left: 7px!important;
    }

    .show {
      .triangle {
        top: 22px;
        left: calc(50% - 1px);
      }
    }
  }
}
