.folder-page{
  margin-top: 12px;

  .header{
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: solid 1px $gray-400;

    .container{
      h2{
        font-size: 1rem;
        width: 100%;
        color: $gray-600;
        font-weight: normal;
        margin: 12px 0 0;
      }
  
      h3{
        display: inline-block;
        margin-top: 33px;
      }
  
      >.container{
        display: none;
      }
    }

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      >.container{
        padding: 0;

        h3{
          margin-top: 20px;
        }

        .filter-tabs{
          width: 100%;
        }
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    >.container{
      padding: 0
    }
  }

  .folders-path{
    margin-top: 10px;
    text-transform: uppercase;
    color: $gray-500;
    font-size: 1rem;
    font-weight: 500;

    .current{
      color: black;
    }

    a{
      color: $gray-500;
      text-decoration: none;
    }

    .signal{
      color: $primary;
      display: inline-block;
      margin: 0 5px 0 10px;
    }
  }

  .filter-tabs{
    .container{
      padding: 0
    }

    float:right;
    border-bottom: none;
    margin: 33px 0 0;

    .filter-count{
      display: none;
    }
  }

  .folders-content{
    margin-top: 30px;
  }
}
