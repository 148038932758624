.filters-container {
  display: flex;
  justify-content:space-between;
  padding: 1.2rem 0;
  border-bottom: 1px solid $gray-300;

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    border-bottom: none;
    padding: 1.2rem 0 0.8rem;
  }

  .filter {
    display: flex;

    .filter-label,
    .filter-value {
      color: $gray-600;
      font-family: $font-family-opensans;
      font-size: 1rem;
      font-weight: $font-weight-normal;
      letter-spacing: normal;
      text-align: left;
      text-overflow: ellipsis;
      text-decoration: none;
      margin-right: 1.5rem;
      background: none;
      border:none;

      &.active{
        color: $black;
        font-weight: $font-weight-bold;
      }

      &:hover{
        color: $black;
      }

      &.selected{
        color: $black;
        font-weight: $font-weight-bold;
      }
    }
  }
}

.filters {
  svg {
    cursor: pointer;
  }

  &.is-open {
    svg path {
      fill: $black!important;
    }
  }

  &.active {
    svg path {
      fill: $primary;
    }
  }
}

.workflows-container{
  .filters-container {
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      justify-content: flex-end;
      .filter-value:last-child{
        margin-right: 0;
      }
    }

    .change-view-icons{
      button {
        border: none;
        background: none;
      }

      .grid-icon {
        @include icons-with-active($gray-500, $black, $black)
      }

      .list-icon {
        @include icons-with-active($gray-500, $black, $black)
      }
    }
  }
}

