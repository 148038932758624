// Buttons

@mixin btn-icon{
  font-size: $btn-secondary-icon-font-size!important;
  padding: $btn-secondary-icon-padding!important;
  padding-left: $btn-secondary-icon-padding-left!important;
  padding-right: $btn-secondary-icon-padding-right!important;
  height: $btn-secondary-icon-height;
  display: $btn-secondary-icon-display!important;
  align-items: $btn-secondary-icon-align-items;

  .icon {
    border-right: $btn-secondary-icon-border-right;
    display: $btn-secondary-icon-display;
    height: $btn-secondary-icon-height;
    align-items: $btn-secondary-icon-align-items;
  }

  .text{
    padding-left: $btn-secondary-icon-padding-left!important;
  }

  svg{
    width: $btn-secondary-icon-svg-width!important;
    padding-right: $btn-secondary-icon-padding-left!important;
    box-sizing: content-box;
    fill: white;
  }
}

@mixin btn-primary{
  background: $btn-primary-background;
  color: $btn-primary-color;
  border: $btn-primary-border;
  padding: $btn-primary-padding;
  border-radius: $btn-primary-border-radius;
  width: $btn-primary-width;

  &:hover{
    border-color: $btn-primary-hover-border;
    background-color: $btn-primary-hover-background;
    color: $btn-primary-hover-color;
  }

  &:disabled{
    background-color: $gray-450;
  }
}

.btn-primary{
  @include btn-primary;
}

@mixin btn-secondary{
  background: $btn-secondary-background;
  color: $btn-secondary-color;
  border: $btn-secondary-border;
  padding: $btn-secondary-padding;
  border-radius: $btn-secondary-border-radius;

  &:hover{
    border-color: $btn-secondary-hover-border;
    background-color: $btn-secondary-hover-background;
    color: $btn-secondary-hover-color;
  }
}

.btn-secondary{
  @include btn-secondary;
}

.btn-secondary-icon{
  @include btn-secondary;
  @include btn-icon;
}

.btn-primary-icon{
  @include btn-icon;
  @include btn-primary;

  .icon {
    border-right: none;
    
    svg{
      padding-right: 0!important;
    }
  }
}

.btn.array-item-remove, .btn-add{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 5px 6px;
  flex: none!important;
  border: none;
}

.btn.array-item-remove{
  background-color: $gray-250;
  color: $gray-450;

  &:hover{
    background-color: $red;
    color: $white;
  }
}

.btn.btn-add{
  background-color: $blue;
}
