  .save-header{
    width: 100%;
    z-index: 998;
    padding: 11px 0;
    position: fixed;
    top: 128px;
    background-color: $gray-50;
    border-bottom: solid 4px $white;
    height: 60px;

    >.container{
      display: flex;
      justify-content: flex-end;
    }

    h2{
      font-size: 1rem;
      text-transform: uppercase;
      margin: 0;
      flex-grow: 1;
      color: $gray-450;
      font-weight: 600;
      margin-top: 7px;
    }

    .btn{
      padding: 7px 20px;
      font-size: 0.87rem;
      text-transform: uppercase;
      color: $white;
      font-weight: 600;
      margin-left: 10px;
    }
  }