.empty-state {
  color: $gray-350;

  .title {
    margin-top: 10px;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: .3rem;
  }

  .subtitle {
    font-weight: 200;
    font-size: .95em;
  }
}
