//
// Brand Header
// ----------------------------------------
.brand-navbar {
  height: 70px;
  padding: 1.2rem .6rem;
  background: url('../images/navbar-bg.png') #fff 30% 0% no-repeat;
  border-top: 4px solid theme-color("primary");
  z-index: 9999;

  .browser-container {
    @media (min-width: map-get($grid-breakpoints, 'md')) {
      width: 500px !important;
    }

    .dashboard-link{
      padding-bottom: 3px;
    }

    .dashboard-item, .magazine-item {
      cursor: pointer;
      fill: $gray-400;
      stroke: none!important;

      &:hover, &.active{
        fill: $primary;
        stroke: none;

        g{
          stroke: none
        }
      }
    }

    .magazine-link{
      padding-bottom: 3px;
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    min-height: 70px!important;
  }

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    >.container{
      position: relative;
    }
  }

  > .brand-navbar-container {
    flex-wrap: wrap;
    justify-content: left;
    @include media-breakpoint-up(md) {
      padding-right: ($grid-gutter-width / 2);
      padding-left: ($grid-gutter-width / 2);
    }
  }

  @include media-breakpoint-up(lg) {
    @supports (position: sticky) or (position: -webkit-sticky){
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: $zindex-sticky;
    }
  }

  .navbar-brand {
    font-size: 1.3rem;
    padding: 0.6rem 0 0.7rem!important;

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      padding-top: 3px!important;
    }

    .app-title-first{
      @media (max-width: map-get($grid-breakpoints, 'sm')) {
        display: none;
      }  
    }

    h1{
      font-size: 1.3rem;
      margin-bottom: 0;
    }

    &,&:hover{
      color: $primary;
    }

    &:focus {
      color: $primary;
    }

    .app-title-first {
      margin-right: 0rem;
      font-weight: 600;
      font-size: 1.31rem;
      padding-left: 40px;
      background: url('../images/dashboard-logo.png') no-repeat;
    }

    .app-title-second {
      font-weight: bold;
    }

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      h1{
        font-size: 1.1rem;
      }
    }
  }

  .navbar-nav-svg {
    display: inline-block;
    width: 1.8rem;
    height: 1.6rem;
    vertical-align: middle;
  }

  .navbar-nav-svg-small {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
  }

  .nav-item {
    padding-right: 0;
    padding-left: 0;
  }

  .nav-link {
    position: relative;
    color: $gray-800;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    background: transparent;
    @include button-size(1.4375rem, 1.5rem, 1.25rem, $btn-line-height, 0);
    @include media-breakpoint-up(md) {
      @include button-size(
        $btn-padding-y * 2,
        ($grid-gutter-width / 2),
        1.125rem,
        $btn-line-height,
        3px
      );
    } // Share hover and focus styles

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      padding-right: 0!important;
      padding-left: 13px!important;
    }

    @include hover-focus {
      text-decoration: none;
    } // Disabled comes first so active can properly restyle

    &.disabled,
    &:disabled {
      box-shadow: none;
      opacity: $btn-disabled-opacity;
    }

    @include hover {
      color: color-yiq($gray-200);
      @include gradient-bg($gray-200);
    }

    &:active {
      background: $gray-250;
    }
  }

  .btn-brandbar,
  .btn-brandbar-small {
    position: relative;
    color: $gray-800;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    background: transparent;
    @include button-size(.3125rem, .6875rem, 1.25rem, $btn-line-height, 4px);
    @include media-breakpoint-up(md) {
      @include button-size(
        $btn-padding-y * 2,
        ($grid-gutter-width / 2),
        1.125rem,
        $btn-line-height,
        3px
      );
    } // Share hover and focus styles

    @include hover-focus {
      text-decoration: none;
      box-shadow: none;
    } // Disabled comes first so active can properly restyle

    &.disabled,
    &:disabled {
      box-shadow: none;
      opacity: $btn-disabled-opacity;
    }

    @include hover {
      color: color-yiq($gray-200);
      @include gradient-bg($gray-200);
    }

    &:active {
      background: $gray-250;
    }

    .badge {
      position: absolute;
      top: -.55rem;
      left: 1.2rem;
      font-size: .8rem;
    }
  }

  .btn-brandbar-small {
    padding: .125rem .3125rem;
  }

  .nav-item-lvl-2 .nav-link {
    border-radius: 0;
  }
}

.navbar-persistent {
  margin-left: auto;

  @include media-breakpoint-up(md) {
    margin-right: -.5rem;
  }

  .nav-item:not(:first-child) {
    margin-left: .8rem;
  }
}

.nav-link-dropdown {
  position: absolute;
  left: 0;
  width: 100vw;
  max-height: 100vh;
  transition: all .4s ease-out;

  > * {
    transition: all .4s ease-out;
  }

  &:not(.show) {
    > * {
      opacity: 0;
    }

    display: block;
    max-height: 0;
    visibility: hidden;
  }
}
