.snack-task-icon {
  width: 18px;
  height: 20px;
  object-fit: contain;
  margin-right: 11px;

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    display: none;
  }
}

.row-message {
  margin-left: 2px;

  .col {
    display: contents;
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    margin-left: 0px;

    .col {
      text-align: left;
      padding: 0px;
      display: block;
    }
  }
}

.workflow-name {
  margin-top: 2px;
  height: 19px;
  margin-left: 20.3px;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  margin-right: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    margin-left: 0;
    margin-top: 0;
    margin-right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 170px;
  }

}


