p.filterTitle{
  text-transform: uppercase;
  color: $secondary-color;
  font-weight: 800;
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 16px;
  margin-left:0px;
}
.group-show{
  .filterTitle{
    margin-left: 15px;
  }
}

.MuiTableContainer-root{
  border-radius: 10px !important;
  border: 1px solid #ccc;
}

.MuiTable-root{
  box-shadow: none;
  
  .MuiTableCell-root{
    height: 62px;
    padding: 0;
    &.MuiTableCell-head,
    &.MuiTableCell-body{
      padding: 0 15px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      color: #24344e;
      .MuiButton-root{
        min-width: 0;
      }
      &.waiting_for_approval{
        color: #9f9f9f;        
      }
    }
    &.avatarColumn{
      width: 40px;
      padding-left: 40px;
    }
    &.actionColumn{
      
      padding-right: 40px;
    }
  } 
}

.MuiTableRow-head{
  border-bottom: 2px solid #ccc;
  .MuiTableCell-head{
    font-weight: 700;
    font-size: 12px;
    color: $secondary-color;
  }
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
  p.filterTitle{
    &.first_one{
      margin-top: 0;
    }
  }
  .MuiTable-root{
    box-shadow: none;    
    .MuiTableCell-root{
      &.MuiTableCell-head,   
      &.MuiTableCell-body{
        padding: 0px 6px;
      }
    }
  }
}
