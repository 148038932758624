$secondary-color: #8b8f97;

.groups{
  .MuiDataGrid-root{
    border-radius: 20px;
    font-family: inherit;    
    
    .MuiDataGrid-cell{
      padding-left: 56px;
    }
    
    .MuiDataGrid-mainGridContainer { 
      .MuiDataGrid-columnsContainer{
        border-bottom-width: 2px;
        padding: 0 40px;  
      }     
      .MuiDataGrid-colCell{
        
        color: $secondary-color;   
        .MuiDataGrid-colCellTitle{
          font-weight: 700;
          font-size: 12px;
        }
            
      }
     
      .MuiDataGrid-columnSeparator {
        display: none;
      }
    }  
  }  
}
