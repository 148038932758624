.image {
  position: relative;
  background-position-y: center;

  .black-overlay-20 {
    background: black;
    opacity: 0.2;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}