.external-link-bar {
  .navbar-brand {
    color: $white;
  }

  .navbar-brand-title {
    margin-left: 30px;
  }

  .nav-item {
    justify-self: flex-end;
  }
}

.external-link-frame {
  border: 0;
  position: absolute;
  width: 100%;
  height: 100vh;
  height: 100%;
  height: -webkit-fill-available;
}
